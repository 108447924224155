import { useDispatch } from 'react-redux';
import { captureException } from '@sentry/browser';
import { push } from 'connected-react-router';

import { analyticsTrack } from '@pumpkincare/analytics';
import { usePlans } from '@pumpkincare/plans';
import {
  getQuoteCompletePets,
  getQuoteId,
  getSinglePetInsuranceTotal,
  useCost,
  useDeleteQuotePet,
  useMutatePetPlan,
  useQuote,
} from '@pumpkincare/quotes';
import { Body1, LegalBody, LoaderButton, Modal } from '@pumpkincare/shared/ui';

import Paths from '../../../app-shell/paths';
import { fetchQuoteById } from '../../../quotes';
import CartItem from './cart-item';

import styles from './cart.module.css';

function Cart({ onClose }) {
  const dispatch = useDispatch();

  const { data: quoteData, isPlaceholderData: isQuotePlaceholderData } = useQuote();
  const pets = getQuoteCompletePets(quoteData);
  const quoteId = getQuoteId(quoteData);
  const { data: costData } = useCost();

  const { mutateAsync: deleteQuotePet } = useDeleteQuotePet(quoteId);
  const { mutate: mutatePetPlan } = useMutatePetPlan();
  const planQueries = usePlans();

  const isMultiPet = pets.length > 1;

  function handleCheckoutClick() {
    analyticsTrack({
      category: 'Shopping Cart',
      event: 'Click Checkout',
      label: 'Shopping Cart Checkout',
    });

    // TODO - PUNKS-290: Replace this code with the useGoToCheckout hook
    dispatch(push(Paths.Checkout));
  }

  function handleRemovePet(pet) {
    deleteQuotePet({ quoteId, quotePetId: pet.id })
      .then(
        () => dispatch(fetchQuoteById(quoteId)) // updating redux quote, can be removed after quotes are converted to hooks)
      )
      .catch(captureException);
  }

  function onPepChange(pet) {
    const planQuery = planQueries.find(planQuery => planQuery.data.petId === pet.id);

    analyticsTrack({
      category: 'Shopping Cart',
      event: !pet.hasPrevent
        ? 'Click Add Pep to Plan'
        : 'Click Remove Pep from Plan',
      label: 'Shopping Cart Pep',
    });
    mutatePetPlan({
      pet: { ...pet, hasPrevent: !pet.hasPrevent },
      plan: planQuery.data,
    });
  }

  return (
    <Modal
      classes={{
        content: styles.contentContainer,
        modal: styles.modal,
        container: styles.modalContainer,
      }}
      onClose={onClose}
      stickToTheRight
    >
      <h4 className={styles.header}>Cart</h4>

      {pets.length > 0 ? (
        <>
          {pets.length > 1 ? (
            <LegalBody className={styles.multiPet}>
              10% multi-pet discount for all additional pets is applied at checkout!
            </LegalBody>
          ) : null}
          <div className={styles.cartItems}>
            {pets.map(pet => {
              const petCostData = costData.find(cost => cost.id === pet.id);
              const { fullPrice = 0 } = petCostData
                ? getSinglePetInsuranceTotal(petCostData)
                : {};

              return (
                <CartItem
                  key={pet.id}
                  isMultiPet={isMultiPet}
                  pet={pet}
                  onRemove={() => handleRemovePet(pet)}
                  fullPrice={fullPrice}
                  onAddPep={() => onPepChange(pet)}
                />
              );
            })}
          </div>

          <div className={styles.footer}>
            <LoaderButton
              color='secondary'
              classes={{ root: styles.button }}
              isLoading={isQuotePlaceholderData}
              onClick={handleCheckoutClick}
            >
              Looks pawesome! Let's check out.
            </LoaderButton>
          </div>
        </>
      ) : (
        <div className={styles.emptyCart}>
          <Body1>Your cart is empty!</Body1>

          <img
            className={styles.peekabooImage}
            src={`/assets/images/peekaboo.png`}
            alt='Empty Cart Image'
          />
        </div>
      )}
    </Modal>
  );
}

export default Cart;
