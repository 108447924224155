// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../../../../../libs/shared/src/ui/breakpoints.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".breakdown-section_breakdownSection_6d01e25d {\n  display: flex;\n  justify-content: space-between;\n\n  margin-top: 50px;\n  width: 100%;\n}\n\n.breakdown-section_breakdownItem_90756998 {\n  text-align: center;\n  max-width: 300px;\n  padding: 10px;\n}\n\n.breakdown-section_breakdownItem_90756998 .breakdown-section_title_4e2812aa {\n  text-align: center;\n  color: var(--cliffordRed);\n}\n\n.breakdown-section_breakdownItem_90756998 .breakdown-section_subtitle_00feb2a8 {\n  margin: 8px 0;\n}\n\n.breakdown-section_breakdownItem_90756998 .breakdown-section_body_1ce3f282 {\n  color: var(--gromitGray);\n}\n\n@media " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["downSm"] + " {\n  .breakdown-section_breakdownSection_6d01e25d {\n    flex-wrap: wrap;\n    justify-content: space-around;\n  }\n\n  .breakdown-section_breakdownItem_90756998 {\n    margin-bottom: 30px;\n  }\n}\n", "",{"version":3,"sources":["webpack://src/plans/view/ecomm-view-v2/coverage-details/breakdown-section/breakdown-section.module.css"],"names":[],"mappings":"AAEA;EACE,aAAa;EACb,8BAA8B;;EAE9B,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE;IACE,eAAe;IACf,6BAA6B;EAC/B;;EAEA;IACE,mBAAmB;EACrB;AACF","sourcesContent":["@value downSm from '~@pumpkincare/shared/ui/breakpoints';\n\n.breakdownSection {\n  display: flex;\n  justify-content: space-between;\n\n  margin-top: 50px;\n  width: 100%;\n}\n\n.breakdownItem {\n  text-align: center;\n  max-width: 300px;\n  padding: 10px;\n}\n\n.breakdownItem .title {\n  text-align: center;\n  color: var(--cliffordRed);\n}\n\n.breakdownItem .subtitle {\n  margin: 8px 0;\n}\n\n.breakdownItem .body {\n  color: var(--gromitGray);\n}\n\n@media downSm {\n  .breakdownSection {\n    flex-wrap: wrap;\n    justify-content: space-around;\n  }\n\n  .breakdownItem {\n    margin-bottom: 30px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"downSm": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["downSm"] + "",
	"breakdownSection": "breakdown-section_breakdownSection_6d01e25d",
	"breakdownItem": "breakdown-section_breakdownItem_90756998",
	"title": "breakdown-section_title_4e2812aa",
	"subtitle": "breakdown-section_subtitle_00feb2a8",
	"body": "breakdown-section_body_1ce3f282"
};
export default ___CSS_LOADER_EXPORT___;
