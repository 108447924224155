export const getQuotes = state => state.quotes;
export const getQuotesStatus = state => state.quotes.status;
export const getQuotesPets = state => state.quotes.pets;
export const getQuotesActiveId = state => state.quotes.activeID;
export const getQuotesMode = state => state.quotes.mode;
export const getQuotesPetsOrder = state => state.quotes.petsOrder;
export const getQuotesTrackingId = state => state.quotes.trackingId;
export const getQuotesPolicyState = state => state.quotes.policy_state;
export const getQuotesPolicyZipCode = state => state.quotes.policy_zipcode;
export const getQuotesId = state => state.quotes.id;
export const getQuotesEmail = state => state.quotes.email;
export const getQuotesFirstName = state => state.quotes.firstName;
export const getQuotesLastName = state => state.quotes.lastName;
export const getQuotesTransactionFee = state => state.quotes.transactionFee;

export const getQuotesVetAttributionStatus = state =>
  state.quotes.vetAttribution.status;
export const getQuotesVetAttributionData = state => state.quotes.vetAttribution.data;
export const getQuotesVetAttributionDataId = state =>
  state.quotes.vetAttribution.data.id;

export const getQuotesRegisterQuoteStatus = state =>
  state.quotes.registerQuoteStatus;

export const getShippingSameAsBilling = state =>
  state.quotes.isShippingSameAsBilling;

export const getQuotesShippingAddress = state => state.quotes.shippingAddress;
export const getQuotesShippingAddressFirstName = state =>
  state.quotes.shippingAddress.firstName;
export const getQuotesShippingAddressCity = state =>
  state.quotes.shippingAddress.city;
export const getQuotesShippingAddressState = state =>
  state.quotes.shippingAddress.state;
export const getQuotesShippingAddressZipcode = state =>
  state.quotes.shippingAddress.zipcode;

export const getQuotesBillingAddress = state => state.quotes.billingAddress;
export const getQuotesBillingAddressFirstName = state =>
  state.quotes.billingAddress.firstName;
export const getQuotesBillingAddressZipcode = state =>
  state.quotes.billingAddress.zipcode;
export const getQuotesBillingAddressState = state =>
  state.quotes.billingAddress.state;
export const getQuotesBillingAddressPhone = state =>
  state.quotes.billingAddress.phone;
export const getQuotesBillingNameShownOnCard = state =>
  state.quotes.billingAddress.nameShownOnCard;

export const getQuotesPaymentInfo = state => state.quotes.paymentInfo;
export const getQuotesPaymentInfoCardNumber = state =>
  state.quotes.paymentInfo.cardNumber;

export const getQuotesEnrollmentStatus = state => state.quotes.enrollmentStatus;
export const getQuotesPartner = state => state.quotes.partner;
