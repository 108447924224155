import { storeObserver } from '@pumpkincare/shared';

import getShouldFetchPersonalizationQuestion from '../selectors/get-should-fetch-personalization-question';
import fetchQuestionVariant from '../thunks/fetch-question-variant';

export default function personalizationStoreObservers(store) {
  const { dispatch } = store;

  storeObserver(store, getShouldFetchPersonalizationQuestion, shouldFetch => {
    if (shouldFetch) {
      dispatch(fetchQuestionVariant());
    }
  });
}
