import { CSSTransition, TransitionGroup } from 'react-transition-group';

import styles from './quote-flow-transition.module.css';

export default function QuoteFlowTransition({
  children,
  location,
  onTransitionExit = () => {},
}) {
  return (
    <TransitionGroup className={styles.transitionGroup}>
      <CSSTransition
        key={location.key}
        classNames={{
          enter: styles['transition-enter'],
          enterActive: styles['transition-enter-active'],
          enterDone: styles['transition-enter-done'],
          exit: styles['transition-exit'],
          exitActive: styles['transition-exit-active'],
        }}
        timeout={{ enter: 1000, exit: 1000 }}
        onExited={onTransitionExit}
      >
        <div className={styles.componentWrapper} aria-live='polite'>
          {children}
        </div>
      </CSSTransition>
    </TransitionGroup>
  );
}
