// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".coverage-item_coverageItem_6dda0f12 {\n  width: 107px;\n  text-align: center;\n  margin: 0;\n}\n\n.coverage-item_coverageItem_6dda0f12 .coverage-item_itemImageContainer_3a32967f {\n  align-items: flex-end;\n  display: flex;\n  justify-content: center;\n\n  height: 70px;\n  margin: 0 0 16px 0;\n}\n\n.coverage-item_coverageItem_6dda0f12 .coverage-item_itemImage_0940b82d {\n  max-width: 100%;\n}\n", "",{"version":3,"sources":["webpack://src/plans/view/ecomm-view-v2/coverage-details/coverage-item/coverage-item.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,kBAAkB;EAClB,SAAS;AACX;;AAEA;EACE,qBAAqB;EACrB,aAAa;EACb,uBAAuB;;EAEvB,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".coverageItem {\n  width: 107px;\n  text-align: center;\n  margin: 0;\n}\n\n.coverageItem .itemImageContainer {\n  align-items: flex-end;\n  display: flex;\n  justify-content: center;\n\n  height: 70px;\n  margin: 0 0 16px 0;\n}\n\n.coverageItem .itemImage {\n  max-width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"coverageItem": "coverage-item_coverageItem_6dda0f12",
	"itemImageContainer": "coverage-item_itemImageContainer_3a32967f",
	"itemImage": "coverage-item_itemImage_0940b82d"
};
export default ___CSS_LOADER_EXPORT___;
