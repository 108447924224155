import { generateIdentifyMeta } from '@pumpkincare/shared';

import { setAnalytics } from '../state/quotes-ducks';

// clearPetTraits clears the pet traits such that
// the order will be accurate after delete and add
export default function clearPetTraits(nameFieldMap, trackingId, email) {
  let traits = { email };
  const size = 3;
  const orders = new Array(size);

  orders.forEach(order => {
    for (const name in nameFieldMap) {
      traits[`PET${order + 1}${name}`] = '';
    }
  });

  return setAnalytics(generateIdentifyMeta(traits, trackingId));
}
