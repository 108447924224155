import { createSelector } from 'reselect';

import { getQuotesActiveId, getQuotesPets } from './quotes-selectors';

const getQuotesActivePet = createSelector(
  [getQuotesPets, getQuotesActiveId],
  (pets, activeId) => pets.find(pet => pet.id === activeId) || pets[0]
);

export default getQuotesActivePet;
