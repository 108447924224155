import { parsePlanDetails } from '@pumpkincare/plans';
import { CollapsiblePanel, LegalBody } from '@pumpkincare/shared/ui';

import styles from './prevent.module.css';

function Prevent({ planPrice, pet }) {
  const preventLineItems = parsePlanDetails(pet.plan, pet.petBreedSpecies);

  return (
    <div style={{ marginTop: '16px' }}>
      <div className={styles.insurancePreventTitleDiv}>
        <LegalBody isBold className={styles.titleClassname}>
          Preventive Essentials Pack
        </LegalBody>

        <LegalBody isBold className={styles.insurancePreventTitlePrice}>
          ${planPrice}
        </LegalBody>
      </div>

      <div className={styles.verticalLine}>
        <CollapsiblePanel
          defaultOpen={false}
          classes={{
            title: styles.titleClassname,
          }}
          title={
            <LegalBody isBold className={styles.detailsColor}>
              View Details
            </LegalBody>
          }
        >
          <div>
            {preventLineItems.map(({ item }, key) => (
              <LegalBody key={key} className={styles.LegalBodyMargin}>
                {item}
              </LegalBody>
            ))}
          </div>
        </CollapsiblePanel>
      </div>
    </div>
  );
}

export default Prevent;
