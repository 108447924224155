import classNames from 'classnames';

import { KeyboardArrowDown } from '@pumpkincare/shared/ui';

import styles from './wave-delimiter.css';

function WaveDelimiter({
  bluesCluesToHuckleberry = false,
  benjiBlue = false,
  line = false,
  onArrowClick = () => {},
  style = {},
  className = '',
  id,
}) {
  const waveHuckleberry = classNames(styles.wave, styles.huckleberryBlue, {
    [styles.hidden]: bluesCluesToHuckleberry === false,
  });
  const waveBluesClues = classNames(styles.wave, styles.bluesCluesBlue, {
    [styles.hidden]: bluesCluesToHuckleberry === false,
  });
  const waveBenjiBlue = classNames(styles.wave, styles.benjiBlue, {
    [styles.hidden]: benjiBlue === false,
  });
  const waveLine = classNames(styles.waveLineContainer, {
    [styles.hidden]: line === false,
  });
  const backgroundFixerColor = bluesCluesToHuckleberry
    ? 'var(--bluesCluesBlue)'
    : benjiBlue
    ? 'var(--benjiBlue)'
    : '';

  return (
    <div
      className={className}
      style={{ position: 'relative', zIndex: 10, ...style }}
      id={id}
    >
      <div
        className={styles.backgroundFixer}
        style={{ backgroundColor: backgroundFixerColor }}
      />

      <svg
        className={waveHuckleberry}
        width='1280'
        height='20'
        viewBox='0 0 1280 20'
        preserveAspectRatio='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path d='M0 10.29C640 43.9264 640 -23.3464 1280 10.2901V0L0 0V10.29Z' />
      </svg>

      <svg
        className={waveBluesClues}
        width='1280'
        height='20'
        viewBox='0 0 1280 20'
        preserveAspectRatio='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path d='M867.822 19.5C959.351 19.1 1180.74 10 1280 7V0H0V0.5C51.9594 -1.5 343.398 4.33333 482.623 7.5C526.589 9 753.411 20 867.822 19.5Z' />
      </svg>

      <svg
        className={waveBenjiBlue}
        width='1280'
        height='20'
        viewBox='0 0 1280 20'
        preserveAspectRatio='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path d='M0 10.29C640 43.9264 640 -23.3464 1280 10.2901V0L0 0V10.29Z' />
      </svg>

      <div className={waveLine}>
        <svg
          className={classNames(styles.wave, styles.line)}
          width='1280'
          height='42'
          viewBox='0 0 1280 42'
          fill='none'
          preserveAspectRatio='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M1281 20.9056C641 -48.0493 641 89.8601 1 20.9053'
            stroke='#CBD2FC'
            strokeLinecap='round'
          />
        </svg>

        <div className={styles.icon} onClick={onArrowClick}>
          <KeyboardArrowDown />
        </div>
      </div>
    </div>
  );
}

export default WaveDelimiter;
