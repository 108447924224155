import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import {
  analyticsTrack,
  GAQuoteFlowClickToEditPetInfo,
} from '@pumpkincare/analytics';
import { CHECKOUT } from '@pumpkincare/analytics';
import {
  getProductHasPrevent,
  usePlan,
  useProductsByState,
} from '@pumpkincare/plans';
import {
  getQuotePolicyState,
  getQuoteVetId,
  getSinglePetInsuranceTotal,
  useDeleteQuotePet,
  useMutatePetPlan,
  useQuote,
} from '@pumpkincare/quotes';
import { formatCurrency, formatPetAge, getIsLoggedIn } from '@pumpkincare/shared';
import { Body2, LegalBody, RouteLink, Typography } from '@pumpkincare/shared/ui';
import { getUserId, useUssr } from '@pumpkincare/user';

import { Paths } from '../../../../../app-shell';
import { fetchQuoteById, setCheckoutPetAsActive } from '../../../../../quotes';
import { getQuotesId } from '../../../../../quotes/selectors';
import Prevent from './prevent';
import Protect from './protect';

import styles from './pet-summary-panel.module.css';

function PetSummaryPanel({ pet, petCost, isMultiPet, isUserChargedAnnually }) {
  const dispatch = useDispatch();
  const quoteId = useSelector(getQuotesId);

  const { data: userData } = useUssr();
  const { mutateAsync: deleteQuotePet } = useDeleteQuotePet();

  const { mutateAsync: mutatePetPlan } = useMutatePetPlan();
  const { data: quoteData } = useQuote();
  const vetId = getQuoteVetId(quoteData);
  const userId = getUserId(userData);
  const { data: planData } = usePlan(
    pet?.petAge,
    pet?.petBreedSpecies,
    userId,
    vetId
  );

  const policyState = getQuotePolicyState(quoteData);
  const { data: productsByStateData } = useProductsByState(policyState);
  const productHasPrevent = getProductHasPrevent(productsByStateData);

  const { petBreedName, petAge, plan } = pet;
  const priceMultiplier = isUserChargedAnnually ? 12 : 1;
  const billingFrequencySuffix = isUserChargedAnnually ? '/ yr' : '/ mo';
  const isLoggedIn = getIsLoggedIn();

  let {
    price_before_discount: fullPrice,
    price_after_discount: priceAfterDiscount,
  } = petCost;

  const isThereDiscount = petCost.discounts.length > 0;

  let preventPrice = parseFloat(plan?.cost ?? 0) * priceMultiplier;

  const total = preventPrice + fullPrice;
  const totalWithDiscount = isThereDiscount ? preventPrice + priceAfterDiscount : 0;

  const protectFinalPrice = isThereDiscount
    ? formatCurrency(priceAfterDiscount)
    : formatCurrency(fullPrice);

  const fullPriceClassname = totalWithDiscount ? styles.beforeDiscount : null;

  function handleEditClick() {
    dispatch(setCheckoutPetAsActive(pet.id));

    GAQuoteFlowClickToEditPetInfo('Checkout');
  }

  function handleDeleteConfirmClick() {
    deleteQuotePet({ quotePetId: pet.id }).then(
      () => dispatch(fetchQuoteById(quoteId)) // updating redux quote, can be removed after quotes are converted to hooks)
    );
  }

  function handlePreventChange() {
    analyticsTrack({
      event: pet.hasPrevent ? 'Remove prevent from plan' : 'Add prevent to plan',
      category: CHECKOUT,
    });

    mutatePetPlan({
      pet: { ...pet, hasPrevent: !pet.hasPrevent },
      plan: planData,
    });
  }

  return (
    <div style={{ margin: '25px 0px' }}>
      <div className={styles.divFlexRow}>
        <div className={styles.nameSection}>
          <Body2 isBold>{pet.petName}'s Plan</Body2>

          {isMultiPet ? (
            <div
              onClick={handleDeleteConfirmClick}
              className={classNames(Typography.link, styles.linkContainer)}
            >
              <img alt='' src='/assets/images/iconImages/remove-pet-x-circle.svg' />
              Remove pet
            </div>
          ) : null}
        </div>

        <div>
          {totalWithDiscount ? (
            <Body2 isBold className={styles.withDiscount}>
              {`${formatCurrency(totalWithDiscount)} ${billingFrequencySuffix}`}
            </Body2>
          ) : null}

          <div
            className={classNames(
              Typography.body2,
              Typography.bold,
              fullPriceClassname
            )}
          >
            {`${formatCurrency(total)} ${billingFrequencySuffix}`}
          </div>
        </div>
      </div>

      <div className={styles.containerLegacy}>
        <div className={styles.innerContainerLegacy}>
          <img
            src={
              pet.petBreedSpecies === 'Dog'
                ? '/assets/images/iconImages/dog_icon.svg'
                : '/assets/images/iconImages/cat_icon.svg'
            }
            alt=''
            style={{ marginLeft: '-10px' }}
            height={55}
            width={55}
          />

          <div className={styles.divFlexColumn}>
            <div style={{ marginBottom: '8px' }}>
              <LegalBody className={styles.petInfo}>Breed</LegalBody>

              <LegalBody className={styles.petInfo}>{petBreedName} </LegalBody>
            </div>

            <div>
              <LegalBody className={styles.petInfo}>Age</LegalBody>

              <LegalBody className={styles.petInfo}>
                {formatPetAge(petAge)}
              </LegalBody>
            </div>
          </div>
        </div>

        <Protect
          isLoggedIn={isLoggedIn}
          discount={petCost}
          isThereDiscount={isThereDiscount}
          fullPrice={`${formatCurrency(fullPrice)} ${billingFrequencySuffix}`}
          finalPrice={`${protectFinalPrice} ${billingFrequencySuffix}`}
          pet={pet}
        />

        <RouteLink
          to={Paths.PlanSelection}
          className={classNames(
            styles.linkContainer,
            styles.linkEdit,
            Typography.link
          )}
          onClick={handleEditClick}
        >
          <img alt='' src='/assets/images/iconImages/edit.png' />
          Edit Plan
        </RouteLink>

        {plan ? (
          <>
            <Prevent
              petId={pet.id}
              species={pet.petBreedSpecies}
              planPrice={`${preventPrice.toFixed(2)} ${billingFrequencySuffix}`}
              pet={pet}
            />

            <div
              onClick={() => handlePreventChange()}
              className={classNames(styles.removeSection, Typography.link)}
              role='button'
            >
              <img alt='' src='/assets/images/iconImages/remove-pet-x-circle.svg' />
              Remove from plan
            </div>
          </>
        ) : null}

        {!plan && productHasPrevent ? (
          <div className={styles.addPepContainer}>
            <img
              alt=''
              src='/assets/images/icon_box.png'
              className={styles.boxIcon}
            />

            <span className={classNames(Typography.legalBody, styles.pepBoxText)}>
              Add Preventive Essentials to help prevent health issues before they
              start
            </span>

            <div
              className={styles.addSection}
              onClick={() => handlePreventChange()}
              role='button'
            >
              <span className={classNames(Typography.h6, styles.adSignal)}>
                &#43;
              </span>

              <span>Add</span>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default PetSummaryPanel;
