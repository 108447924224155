import classNames from 'classnames';

import { Body2, LegalBody, Typography } from '@pumpkincare/shared/ui';

import styles from './breakdown-section.module.css';

function BreakdownSection({ coverageDetails }) {
  const { recommendedPlan, recommendedPlanOrder } = coverageDetails;

  function renderItem({ title, subtitle, description }) {
    return (
      <div className={styles.breakdownItem} key={title}>
        <span className={classNames(Typography.h1, styles.title)}>{title}</span>

        <LegalBody className={styles.subtitle}>{subtitle}</LegalBody>

        <LegalBody
          className={styles.body}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
    );
  }

  return (
    <div className={styles.breakdownSection}>
      {recommendedPlanOrder.map(item => renderItem(recommendedPlan[item]))}
    </div>
  );
}

export default BreakdownSection;
