// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".teams_mainContainer_e440bee5 {\n  background-color: var(--cujoCream);\n\n  display: flex;\n  flex-wrap: wrap;\n  flex-direction: row;\n  padding: 0 var(--w1);\n}\n\n.teams_contentBlock_d166e8c3 {\n  z-index: 5;\n  width: 50%;\n  margin: 24px 0 96px 0;\n}\n\n.teams_headerH1_02c8cd7a {\n  margin-bottom: 16px;\n}\n\n.teams_headerBody1_7297e27f {\n  margin-bottom: 16px;\n}\n\n.teams_headerMessage_913d0af4 {\n  margin-bottom: 16px;\n}\n\n.teams_headerButton_d3d81d2b {\n  color: var(--scoutNavy);\n  border-color: var(--zeroWhite);\n  text-decoration: none;\n  margin-bottom: 24px;\n  width: 100%;\n}\n\n.teams_textField_c6567de9 {\n  -mui-mixins: theme.typography.body1;\n  width: 100%;\n  padding-bottom: 16px;\n}", "",{"version":3,"sources":["webpack://src/teams/teams.module.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;;EAElC,aAAa;EACb,eAAe;EACf,mBAAmB;EACnB,oBAAoB;AACtB;;AAEA;EACE,UAAU;EACV,UAAU;EACV,qBAAqB;AACvB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,uBAAuB;EACvB,8BAA8B;EAC9B,qBAAqB;EACrB,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,mCAAmC;EACnC,WAAW;EACX,oBAAoB;AACtB","sourcesContent":[".mainContainer {\n  background-color: var(--cujoCream);\n\n  display: flex;\n  flex-wrap: wrap;\n  flex-direction: row;\n  padding: 0 var(--w1);\n}\n\n.contentBlock {\n  z-index: 5;\n  width: 50%;\n  margin: 24px 0 96px 0;\n}\n\n.headerH1 {\n  margin-bottom: 16px;\n}\n\n.headerBody1 {\n  margin-bottom: 16px;\n}\n\n.headerMessage {\n  margin-bottom: 16px;\n}\n\n.headerButton {\n  color: var(--scoutNavy);\n  border-color: var(--zeroWhite);\n  text-decoration: none;\n  margin-bottom: 24px;\n  width: 100%;\n}\n\n.textField {\n  -mui-mixins: theme.typography.body1;\n  width: 100%;\n  padding-bottom: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainContainer": "teams_mainContainer_e440bee5",
	"contentBlock": "teams_contentBlock_d166e8c3",
	"headerH1": "teams_headerH1_02c8cd7a",
	"headerBody1": "teams_headerBody1_7297e27f",
	"headerMessage": "teams_headerMessage_913d0af4",
	"headerButton": "teams_headerButton_d3d81d2b",
	"textField": "teams_textField_c6567de9"
};
export default ___CSS_LOADER_EXPORT___;
