import classNames from 'classnames';
import PropTypes from 'prop-types';

import { analyticsTrack, INITIATE_EXPERIMENT } from '@pumpkincare/analytics';
import {
  ARF_MEOW_PHONE_NUMBER,
  CAT,
  CONTACT_EMAIL,
  CONTACT_EMAIL_LINK,
  DOG,
  PHONE_NUMBER_LINK,
  useBooleanInput,
} from '@pumpkincare/shared';
import {
  Body1,
  Body2,
  ButtonStyles,
  LegalBody,
  Modal,
  Superscript2,
  Typography,
} from '@pumpkincare/shared/ui';

import styles from './plan-statistics.module.css';

function PlanStatistics({
  whyPetInsurance,
  preExConditions,
  petBreedSpecies,
  state,
}) {
  const { handle, handleLocation, imageUrl, items } = whyPetInsurance;

  const [isModalOpen, toggleModalOpen] = useBooleanInput(false);

  const modalImageClassName = classNames(styles.img, styles.preExisting, {
    [styles.cat]: petBreedSpecies === CAT,
    [styles.dog]: petBreedSpecies === DOG,
  });

  function handleSupScriptClick() {
    setTimeout(() => {
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
    }, 100);
  }

  function handlePreExistClick() {
    analyticsTrack({
      event: INITIATE_EXPERIMENT,
      category: 'Stats Module',
      label: 'pre_existing',
    });

    toggleModalOpen();
  }

  return (
    <div className={styles.container}>
      <h3 className={Typography.h3}>{handleLocation ?? handle}</h3>

      <div className={styles.content}>
        <div className={classNames(styles.emergencyItem, styles.item, styles.left)}>
          <img src='/assets/images/iconImages/siren-pluto-gold.png' alt='' />

          <div className={styles.itemText}>
            <Body1 isBold className={styles.itemTitle}>
              {items[0]?.title}{' '}
              <Superscript2
                className={styles.supScript}
                onClick={handleSupScriptClick}
              >
                2
              </Superscript2>
            </Body1>

            <Body2>{items[0]?.copy}</Body2>
          </div>
        </div>

        <div className={classNames(styles.stethoItem, styles.item, styles.right)}>
          <img src='/assets/images/iconImages/stethoscope-clifford-red.png' alt='' />

          <div className={styles.itemText}>
            <Body1 isBold className={styles.itemTitle}>
              {items[1]?.title}{' '}
              <Superscript2
                className={styles.supScript}
                onClick={handleSupScriptClick}
              >
                3
              </Superscript2>
            </Body1>

            <Body2> {items[1]?.copy}</Body2>
          </div>
        </div>

        <div className={styles.imageItem}>
          <div className={styles.petImage}>
            <img src={imageUrl} alt='' />
          </div>
        </div>

        <div className={classNames(styles.savingItem, styles.item, styles.left)}>
          <img src='/assets/images/iconImages/savings-benji-blue.png' alt='' />

          <div className={styles.itemText}>
            <Body1 isBold className={styles.itemTitle}>
              {items[2]?.titleLocation?.[state] ?? items[2]?.title}
              <Superscript2
                className={styles.supScript}
                onClick={handleSupScriptClick}
              >
                4
              </Superscript2>
            </Body1>

            <Body2>
              {items[2]?.titleLocation?.[state]
                ? items[2]?.copyLocation
                : items[2]?.copy}
            </Body2>
          </div>
        </div>

        <div className={classNames(styles.dnaItem, styles.item, styles.right)}>
          <img src='/assets/images/iconImages/dna-clifford-red.png' alt='' />

          <div className={styles.itemText}>
            <Body1 isBold className={styles.itemTitle}>
              {items[3]?.title}
            </Body1>

            <Body2>{items[3]?.copy}</Body2>
          </div>
        </div>

        <div className={classNames(styles.preExistingItem, styles.left)}>
          <img src='/assets/images/iconImages/pre-existing-pluto-gold.png' alt='' />

          <div className={styles.itemText}>
            <Body1 isBold className={styles.itemTitle}>
              It’s best to sign up before pre-existing conditions pile up!
            </Body1>

            <Body2>{preExConditions}</Body2>

            <span
              className={classNames(styles.itemLink, ButtonStyles.cta)}
              onClick={handlePreExistClick}
              role='button'
            >
              What the fluff is this?!
              <img src='/assets/images/iconImages/arrow-right-blue-clues.svg' />
            </span>
          </div>
        </div>
      </div>
      {isModalOpen ? (
        <Modal
          onClose={toggleModalOpen}
          classes={{ container: styles.modalContainer }}
        >
          <span className={classNames(Typography.h2, styles.modalTitle)}>
            Pre-existing Conditions
          </span>

          <Body1 className={styles.modalCopy} isBold>
            What the fluff is a pre-existing condition?
          </Body1>

          <Body1 className={styles.modalCopyDesc}>
            A condition that occurred, showed signs, showed symptoms, was noted by
            your vet, was diagnosed, or treated before your Pumpkin plan’s coverage
            started or during the 14-day waiting period. This includes conditions
            that are related to, secondary, or resulting from a pre-existing
            condition.
          </Body1>

          <Body1 className={styles.modalCopy} isBold>
            Why aren’t they covered?
          </Body1>

          <Body1 className={styles.modalCopyDesc}>
            Pet insurance is designed to help cover accidents & illnesses that occur
            after you enroll and your pet’s waiting period ends, not before. Let’s
            look at a hypothetical example to see why this is SUPER important to
            understand!
          </Body1>

          <div className={styles.modalSection}>
            <div className={modalImageClassName} />

            <div className={styles.modalSubSection}>
              <div className={styles.subSectionIconContainer}>
                <img src='/assets/images/light-bulb.png' alt='' />
              </div>

              <div>
                <Body1 className={styles.modalSubText}>
                  <b>Furbulous news!</b> If your pet’s pre-existing condition is
                  curable, like a flea bite (vs an incurable condition like
                  allergies), your Pumpkin insurance plan could cover it if it’s
                  cured, your pet has been free of symptoms and treatment for 180
                  days, and it occurs again in the future. This excludes knee & hind
                  leg ligament conditions. If one of these conditions occur before
                  enrolling or during the waiting period, future ones won’t be
                  covered.
                </Body1>
              </div>
            </div>
          </div>

          <div className={styles.modalFooter}>
            <div className={styles.footerContactSection}>
              <div className={styles.footerIconContainer}>
                <img
                  className={styles.footerIcon}
                  src='/assets/images/iconImages/call-dog.png'
                  alt=''
                />
              </div>

              <div className={styles.imageContainer} />

              <div className={styles.contactContent}>
                <Body2 isBold>
                  Still have questions about pre-existing conditions?
                </Body2>

                <Body2>Our pet-loving care team is just a paw away.</Body2>

                <div className={styles.contactArea}>
                  <div className={styles.contactItem}>
                    <div className={styles.contactIconWrapper}>
                      <img
                        className={styles.contactIcon}
                        src='/assets/images/contact_phone.svg'
                        alt=''
                      />
                    </div>

                    <div>
                      <LegalBody className={styles.contactInfo}>
                        <a href={PHONE_NUMBER_LINK} className={styles.linkText}>
                          Call {`${ARF_MEOW_PHONE_NUMBER}`}
                        </a>
                        Mon-Fri 8am-8pm EST
                      </LegalBody>
                    </div>
                  </div>

                  <div className={styles.contactItem}>
                    <div className={styles.contactIconWrapper}>
                      <img
                        className={styles.contactIcon}
                        src='/assets/images/contact_mail.svg'
                        alt=''
                      />
                    </div>

                    <div>
                      <LegalBody className={styles.contactInfo}>
                        <a href={CONTACT_EMAIL_LINK} className={styles.linkText}>
                          Email {CONTACT_EMAIL}
                        </a>
                        We’ll respond in a day or less
                      </LegalBody>
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.imageContainerBottom} />

              <div className={styles.buttonContainer}>
                <button
                  onClick={toggleModalOpen}
                  className={classNames(ButtonStyles.cta)}
                >
                  Back to Plan Details
                </button>
              </div>
            </div>
          </div>
        </Modal>
      ) : null}
    </div>
  );
}

PlanStatistics.defaultProps = {
  whyPetInsurance: {},
  preExConditions: '',
  petBreedSpecies: '',
};

PlanStatistics.propTypes = {
  whyPetInsurance: PropTypes.shape({
    handle: PropTypes.string,
    handleLocation: PropTypes.string,
    imageUrl: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        copy: PropTypes.string,
        imageUrl: PropTypes.string,
        titleLocation: PropTypes.object,
        copyLocation: PropTypes.string,
      })
    ),
  }),
  preExConditions: PropTypes.string,
  petBreedSpecies: PropTypes.string,
  state: PropTypes.string,
};

export default PlanStatistics;
