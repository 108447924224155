import {
  captureException,
  ERROR,
  FETCHING,
  generateIdentifyMeta,
  SUCCESS,
} from '@pumpkincare/shared';

import { getQuotesId, getQuotesTrackingId } from '../selectors/quotes-selectors';
import { setQuoteVetAttribution } from '../state/quotes-ducks';

export default function updateQuoteVetAttribution(vet) {
  return (dispatch, getState, { quotesService }) => {
    dispatch(setQuoteVetAttribution(FETCHING));
    const state = getState();

    const traits = vet.id
      ? {
          VETCLINICNAME: vet.vet_name,
          VETCLINICID: vet.id,
        }
      : {
          VETCLINICNAME: vet.vet_name ? 'Other Vet' : 'No Vet',
        };

    return vet.id
      ? quotesService
          .patchQuoteVetAttribution(getQuotesId(state), { vet_id: vet.id })
          .then(() => {
            dispatch(
              setQuoteVetAttribution(
                SUCCESS,
                vet,
                generateIdentifyMeta(traits, getQuotesTrackingId(state))
              )
            );
          })
          .catch(error => {
            captureException(error);
            dispatch(setQuoteVetAttribution(ERROR));
          })
      : dispatch(
          setQuoteVetAttribution(
            SUCCESS,
            {},
            generateIdentifyMeta(traits, getQuotesTrackingId(state))
          )
        );
  };
}
