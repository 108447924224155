import classNames from 'classnames';

import { isUnlimitedLimit, parsePlanDetails, zeroesToK } from '@pumpkincare/plans';
import {
  Body2,
  ButtonStyles,
  LegalBody,
  Spinner,
  Typography,
} from '@pumpkincare/shared/ui';

import styles from './cart-item.module.css';

function CartItem({ isMultiPet, pet, onRemove, fullPrice, onAddPep }) {
  const coverageLimit = isUnlimitedLimit(pet.annual_limit)
    ? pet.annual_limit
    : `$${zeroesToK(pet.annual_limit)}`;
  const planDetails = pet.plan
    ? parsePlanDetails(pet.plan, pet.petBreedSpecies)
    : null;
  const preventPrice = parseFloat(pet.plan?.cost ?? 0);

  return (
    <div className={styles.itemContainer}>
      <div className={styles.innerContainer}>
        <div className={styles.topContainer}>
          <img
            src={
              pet.petBreedSpecies === 'Dog'
                ? '/assets/images/iconImages/dog_icon.svg'
                : '/assets/images/iconImages/cat_icon.svg'
            }
            className={styles.petImage}
            height={55}
            width={55}
          />

          <div className={styles.petPlanTitle}>
            <Body2 isBold className={styles.petName}>
              {pet.petName}'s Plan
            </Body2>
            {isMultiPet ? (
              <button
                className={classNames(ButtonStyles.unset, styles.removePet)}
                onClick={onRemove}
              >
                <img src={'/assets/images/iconImages/remove-pet-x-circle.svg'} />
                <span
                  className={classNames(Typography.legalBody, styles.removePetColor)}
                >
                  &nbsp;Remove pet
                </span>
              </button>
            ) : null}
          </div>
        </div>

        <div className={styles.planDetails}>
          <div className={styles.titleContainer}>
            <LegalBody className={styles.title}>Pumpkin Pet Insurance</LegalBody>
            <div className={styles.planCost}>
              <LegalBody className={styles.title}>
                ${fullPrice ? fullPrice : <Spinner size={14} />}
              </LegalBody>
              <LegalBody>&nbsp;/ mo</LegalBody>
            </div>
          </div>

          <div className={styles.detailsTab}>
            <LegalBody className={styles.details}>90% Reimbursement Rate</LegalBody>
            <LegalBody className={styles.details}>
              ${pet.deductible} Annual Deductible
            </LegalBody>
            <LegalBody className={styles.details}>
              {coverageLimit} Annual Coverage Limit
            </LegalBody>
          </div>

          {pet.hasPrevent ? (
            <>
              <div className={styles.titleContainer}>
                <LegalBody className={styles.title}>Preventive Essentials</LegalBody>
                <div className={styles.planCost}>
                  <LegalBody className={styles.title}>
                    ${preventPrice ? preventPrice : <Spinner size={14} />}
                  </LegalBody>
                  <LegalBody>&nbsp;/ mo</LegalBody>
                </div>
              </div>

              <div className={styles.detailsTab}>
                {planDetails.map(({ item }, index) => {
                  return (
                    <LegalBody key={index} className={styles.details}>
                      {item}
                    </LegalBody>
                  );
                })}
              </div>
            </>
          ) : (
            <div className={styles.pepContainer} onClick={onAddPep}>
              <img
                src={'/assets/images/icon_pumpkin_box.png'}
                height={38}
                width={50}
              />
              <LegalBody className={styles.pepBlurb}>
                Add Preventive Essentials to help prevent health issues before they
                start
              </LegalBody>
              <button className={classNames(ButtonStyles.unset, styles.addPep)}>
                <span className={Typography.body1}>+</span>{' '}
                <span className={Typography.legalBody}>&nbsp;Add</span>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CartItem;
