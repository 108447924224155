// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".next-button_nextButton_d3335397 {\n  width: 100% !important;\n  margin: 40px 0 0 !important;\n}\n\n.next-button_nextButton_d3335397:focus {\n  outline: auto 5px -webkit-focus-ring-color;\n}\n", "",{"version":3,"sources":["webpack://src/quotes/view/shared/next-button/next-button.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,2BAA2B;AAC7B;;AAEA;EACE,0CAA0C;AAC5C","sourcesContent":[".nextButton {\n  width: 100% !important;\n  margin: 40px 0 0 !important;\n}\n\n.nextButton:focus {\n  outline: auto 5px -webkit-focus-ring-color;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nextButton": "next-button_nextButton_d3335397"
};
export default ___CSS_LOADER_EXPORT___;
