// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".coverage-section_conditionsContainer_0c031fd2 {\n  display: flex;\n  flex-basis: 100%;\n  flex-grow: 0;\n  flex-wrap: wrap;\n  justify-content: center;\n\n  box-sizing: border-box;\n  margin: 0;\n  max-width: 100%;\n  width: 100%;\n}\n\n.coverage-section_redTitle_42ba10bd {\n  color: var(--cliffordRed);\n  margin-bottom: 24px;\n  margin-top: 32px;\n  text-align: center;\n}\n\n.coverage-section_link_927bb66b {\n  color: var(--bluesCluesBlue);\n  cursor: pointer;\n  text-decoration: none;\n}\n\n.coverage-section_eligibleCondition_5481245a {\n  width: 176px;\n}\n\n@media '~@pumpkincare/shared/ui/breakpoints' {\n  .coverage-section_eligibleCondition_5481245a {\n    width: 150px;\n  }\n}\n", "",{"version":3,"sources":["webpack://src/plans/view/ecomm-view-v2/coverage-details/coverage-section/coverage-section.module.css"],"names":[],"mappings":"AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,YAAY;EACZ,eAAe;EACf,uBAAuB;;EAEvB,sBAAsB;EACtB,SAAS;EACT,eAAe;EACf,WAAW;AACb;;AAEA;EACE,yBAAyB;EACzB,mBAAmB;EACnB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,4BAA4B;EAC5B,eAAe;EACf,qBAAqB;AACvB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE;IACE,YAAY;EACd;AACF","sourcesContent":["@value tablet '~@pumpkincare/shared/ui/breakpoints';\n\n.conditionsContainer {\n  display: flex;\n  flex-basis: 100%;\n  flex-grow: 0;\n  flex-wrap: wrap;\n  justify-content: center;\n\n  box-sizing: border-box;\n  margin: 0;\n  max-width: 100%;\n  width: 100%;\n}\n\n.redTitle {\n  color: var(--cliffordRed);\n  margin-bottom: 24px;\n  margin-top: 32px;\n  text-align: center;\n}\n\n.link {\n  color: var(--bluesCluesBlue);\n  cursor: pointer;\n  text-decoration: none;\n}\n\n.eligibleCondition {\n  width: 176px;\n}\n\n@media tablet {\n  .eligibleCondition {\n    width: 150px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tablet": "'~@pumpkincare/shared/ui/breakpoints'",
	"conditionsContainer": "coverage-section_conditionsContainer_0c031fd2",
	"redTitle": "coverage-section_redTitle_42ba10bd",
	"link": "coverage-section_link_927bb66b",
	"eligibleCondition": "coverage-section_eligibleCondition_5481245a"
};
export default ___CSS_LOADER_EXPORT___;
