import { transformPet } from '@pumpkincare/quotes';

import { getAppNoopPayload } from '../../app-shell';

export default function updatePetInQuote(quoteId, pet) {
  return (dispatch, getState, { quotesService }) => {
    return quotesService
      .patchQuotePet({ quoteId, pet, noopPayload: getAppNoopPayload(getState()) })
      .then(responsePet => transformPet(responsePet));
  };
}
