import { capitalize } from '@pumpkincare/shared';
import { LegalBody } from '@pumpkincare/shared/ui';

import SectionContainer from '../../section-container';
import BreakdownSection from './breakdown-section';
import CoverageSection from './coverage-section';

import styles from './coverage-details.module.css';

function CoverageDetails({ activePet, data }) {
  const { petGender, petAge, petBreedName } = activePet;
  const { header, detailsTitle } = data;

  return (
    <SectionContainer>
      <h2 className={styles.coverageTitle}>{header}</h2>

      <LegalBody style={{ margin: '8px 0 16px' }}>
        {capitalize(petGender)}
        &nbsp; &#8226; &nbsp;
        {petBreedName}
        &nbsp; &#8226; &nbsp;
        {`Age ${petAge}`}
      </LegalBody>

      <BreakdownSection coverageDetails={data} />

      <h4 className={styles.detailsTitle}>{detailsTitle}</h4>

      <CoverageSection activePet={activePet} />
    </SectionContainer>
  );
}

export default CoverageDetails;
