import BottomContentLinks from './bottom-content-links';
import LearnMore from './learn-more';
import Legal from './legal';
import NeedSomeHelp from './need-some-help';

function Footer() {
  return (
    <>
      <NeedSomeHelp />
      <LearnMore />
      <Legal />
      <BottomContentLinks />
    </>
  );
}

export default Footer;
