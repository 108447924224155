import classnames from 'classnames';

import styles from './section-container.module.css';

function SectionContainer({ className, children }) {
  return (
    <div className={classnames(styles.sectionContainer, className)}>{children}</div>
  );
}

export default SectionContainer;
