/* eslint-disable react/jsx-no-target-blank */
import { useState } from 'react';
import classNames from 'classnames';

import { analyticsTrack, INITIATE_EXPERIMENT } from '@pumpkincare/analytics';
import { getQuotePolicyState, useQuote } from '@pumpkincare/quotes';
import {
  ARF_MEOW_PHONE_NUMBER,
  CAT,
  CONTACT_EMAIL,
  CONTACT_EMAIL_LINK,
  DOG,
  PHONE_NUMBER_LINK,
  useBooleanInput,
  useProgramResolver,
} from '@pumpkincare/shared';
import {
  Body1,
  Body2,
  ButtonStyles,
  LegalBody,
  Modal,
  Typography,
} from '@pumpkincare/shared/ui';

import styles from './plan-not-covered.module.css';

function PlanNotCovered({ petBreedSpecies, petAge }) {
  const { data: policyState } = useQuote({ select: getQuotePolicyState });

  if (typeof petAge == 'string') {
    petAge = 0;
  }
  const {
    data: { sample_plan_url: samplePolicyUrl },
  } = useProgramResolver(policyState, petBreedSpecies, petAge);

  const [selectedItem, setSelectedItem] = useState('cosmetic_elective');
  const [isModalOpen, toggleModalOpen] = useBooleanInput(false);

  const footerClassName = classNames(styles.modalFooter, {
    [styles.modalFooterDental]: selectedItem === 'dental',
  });

  const isDog = petBreedSpecies === DOG;

  const petCallIcon = isDog
    ? '/assets/images/iconImages/icon-dog-help.png'
    : '/assets/images/iconImages/icon-cat-help.png';

  const NOT_COVERED_ITEMS = [
    {
      copy: 'Pre-existing conditions',
      link: 'What the fluff is this?!',
      analytic: 'pre_existing',
    },
    {
      copy: 'Spaying & neutering',
      link: 'Why isn’t this covered?',
      analytic: 'spay_neuter',
    },
    {
      copy: 'Some dental cleanings & services',
      link: 'Get the dirt on dental!',
      analytic: 'dental',
    },
    {
      copy: 'Cosmetic & elective procedures',
      link: 'Let’s dig into the details!',
      analytic: 'cosmetic_elective',
    },
    {
      copy: 'Wellness exams, vaccines & other preventive care',
      link: 'What does this include?',
      analytic: 'wellness',
    },
  ];

  const MODAL_INFO = {
    pre_existing: {
      title: 'Pre-existing conditions',
      copy: (
        <>
          <b>What the fluff is a pre-existing condition? </b>
          <br />
          A condition that occurred, showed signs, showed symptoms, was noted by your
          vet, was diagnosed, or treated before your Pumpkin plan’s coverage started
          or during the 14-day waiting period. This includes conditions that are
          related to, secondary, or resulting from a pre-existing condition.
          <br />
          <br />
          <b>Why aren’t they covered?</b>
          <br />
          Pet insurance is designed to help cover accidents & illnesses that occur
          after you enroll and your pet’s waiting period ends, not before. Let’s look
          at a hypothetical example to see why this is SUPER important to understand!
        </>
      ),
      subText: (
        <Body2 className={styles.subtext}>
          {isDog ? (
            <>
              <b>Furbulous news!</b> If your pet’s pre-existing condition is curable,
              like intestinal worms (vs incurable like food allergies), your Pumpkin
              insurance plan could cover it if it was cured, your pet has been free
              of symptoms and treatment for 180 days, and it occurs again in the
              future. This excludes knee & hind leg ligament conditions. If one of
              these conditions occur before enrolling or during the waiting period,
              future ones won’t be covered.
            </>
          ) : (
            <>
              <b>Furbulous news!</b> If your pet’s pre-existing condition is curable,
              like a respiratory infection (vs incurable like asthma), your Pumpkin
              insurance plan could cover it if it was cured, your pet has been free
              of symptoms and treatment for 180 days, and it occurs again in the
              future. This excludes knee & hind leg ligament conditions. If one of
              these conditions occur before enrolling or during the waiting period,
              future ones won’t be covered.
            </>
          )}
        </Body2>
      ),
      mobileImg: isDog
        ? '/assets/images/cartoons/pre_existing-mobile-dog.png'
        : '/assets/images/cartoons/pre_existing-mobile-cat.png',
      largeImg: isDog
        ? '/assets/images/cartoons/pre_existing-dog.png'
        : '/assets/images/cartoons/pre_existing-cat.png',
      altText: isDog
        ? `A dog says “Mom! My paws are super itchy!” When they are at the vet, the pet owner says “We started Butch on a new food and ever since he’s been itching his paws a lot! Maybe it’s a food allergy?” The vet writes down Food Allergy Symptoms in Butch’s chart. The vet says, “Let’s do an elimination trial with hypoallergenic food for 3 weeks and find out.” Later on the phone with Pumpkin, the pet owner says, “Hi! My dog Butch has food allergy symptoms and is being tested. I’d like to buy insurance now, so treatment would be covered if gets that diagnosis.” A Pumpkin representative says, “Unfortunately Pumpkin plans don’t cover pre-existing conditions. This would include his allergy symptoms, even without a diagnosis. That said, you can still enroll and get covered for new accidents & illnesses that haven’t occurred yet!” The pet owner says “I see, let’s enroll anyway! You never know when another accident or illness could pop up, and I want to make sure he’s covered next time.” The Pumpkin rep says, “100%. Let me help you create Butch’s plan!”`
        : `A cat is seen coughing a lot. When they are at the vet, the pet owner says “Fluffy has been coughing a lot and is having trouble breathing. Any idea what’s wrong?” The vet writes down Possible Asthma Symptoms in Fluffy’s chart. The vet says. “I have a hunch but these are symptoms of several conditions, so let’s run some test to confirm.” Later on the phone with Pumpkin, the pet owner says, “Hi! My cat has been coughing. Our vet is running tests to find out the cause. I’d like to buy insurance now, so treatment can be covered when she gets a diagnosis.” A Pumpkin representative says, “Unfortunately Pumpkin plans don’t cover pre-existing conditions. This includes whichever condition she ends up being diagnosed with for her current coughing symptoms. That said, you can still enroll and get covered for new accidents & illnesses that haven’t occurred yet!” The pet owner says “I see, let’s enroll anyway! You never know when another accident or illness could pop up, and I want to make sure she’s covered next time.“ The Pumpkin rep says, “100%. Let me help you create Fluffy’s plan!”`,
    },

    spay_neuter: {
      title: 'Spaying & neutering',
      copy: (
        <span>
          Just like auto insurance covers repairs if your car is damaged in an
          unexpected accident,{' '}
          <b>
            pet insurance is designed to cover treatment if your{' '}
            {petBreedSpecies.toLowerCase()} gets hurt or sick from an unexpected
            accident or illness.
          </b>
          <br />
          <br />
          Similarly, auto insurance doesn’t cover routine oil changes for the same
          reason{' '}
          <b>
            Pumpkin plans don’t cover surgical procedures like spaying or neutering.
          </b>{' '}
          They are things you do preventatively so your car won’t overheat down the
          road or your pet won’t develop future illnesses, but not things you do to
          help them recover from accidents or illnesses.
        </span>
      ),
      subText: (
        <Body2 className={styles.subtext}>
          Many assume pet insurance is like human health insurance, but it actually
          works more like auto insurance since under the law pets are considered
          “property” (even though to us they’re family)!
        </Body2>
      ),
      mobileImg: isDog
        ? '/assets/images/cartoons/spay_neuter-mobile-dog.png'
        : '/assets/images/cartoons/spay_neuter-mobile-cat.png',
      largeImg: isDog
        ? '/assets/images/cartoons/spay_neuter-dog.png'
        : '/assets/images/cartoons/spay_neuter-cat.png',
      altText:
        'Pet insurance is like auto insurance. Like an oil change for preventive care is not covered for your car but body damage repair for an accident is. A pay or neuter procedure is not covered for your pet as surgical repair on a fracture for an accident is covered.',
    },

    wellness: {
      title: 'Wellness exams, vaccines & other preventive care',
      copy: (
        <span>
          Just like auto insurance covers repairs if your car is damaged in an
          unexpected accident,
          <b>
            {' '}
            pet insurance is designed to cover treatment if your{' '}
            {petBreedSpecies.toLowerCase()} gets hurt or sick from an unexpected
            accident or illness.
          </b>
          <br />
          <br />
          Similarly, auto insurance doesn’t cover routine oil changes like
          <b>
            {' '}
            Pumpkin insurance plans don’t cover routine vaccines, wellness exams, or
            parasite tests.
          </b>{' '}
          They are things you do preventatively so your car won’t overheat down the
          road or your pet won’t develop future illnesses, but not things to help
          them recover from accidents or illnesses.
        </span>
      ),
      subText: (
        <Body2 className={styles.subtext}>
          Pumpkin insurance plans don’t cover preventive care,{' '}
          <b>but Preventive Essentials does!</b> While not insurance, it’s an
          optional wellness package you can add to your Pumpkin plan. Get more info
          on the next page after you finalize your insurance!
        </Body2>
      ),
      mobileImg: isDog
        ? '/assets/images/cartoons/wellness-mobile-dog.png'
        : '/assets/images/cartoons/wellness-mobile-cat.png',
      largeImg: isDog
        ? '/assets/images/cartoons/wellness-dog.png'
        : '/assets/images/cartoons/wellness-cat.png',
      altText: isDog
        ? 'Pet insurance is like auto insurance. Like an oil change for preventive care is not covered for your car but body damage repair for an accident is. A vaccine for preventive care for your dog is not covered but kennel cough treatment for illness is covered.'
        : 'Pet insurance is like auto insurance. Like an oil change for preventive care is not covered for your car but body damage repair for an accident is. A vaccine for preventive care for your cat is not covered but Feline Distemper Treatment for illness is covered.',
    },

    dental: {
      title: 'Some dental cleanings & services',
      copy: (
        <>
          <Body1>
            Pumpkin Pet Insurance plans are designed to cover treatments for{' '}
            {petBreedSpecies.toLowerCase()}s suffering from dental illnesses – not
            routine cleanings to help prevent dental illnesses in{' '}
            {petBreedSpecies.toLowerCase()}s with healthy teeth and gums.{' '}
            <Body1 className={styles.dentalMargin}>
              <b>
                Pumpkin plans will cover certain cleanings prescribed for periodontal
                disease
              </b>{' '}
              when a {petBreedSpecies.toLowerCase()}’s symptoms, diagnosis, and
              treatment occur after the 14 day waiting period and coverage start
              date.
            </Body1>
            Check out this handy chart to see if your {petBreedSpecies.toLowerCase()}
            ’s cleaning would be covered!
          </Body1>
        </>
      ),
      subText: (
        <Body2 className={styles.subtext}>
          Not sure where your {petBreedSpecies.toLowerCase()} lands? Ask your vet if
          symptoms have been noted in recent medical records.
        </Body2>
      ),
      mobileImg:
        petBreedSpecies == DOG
          ? '/assets/images/cartoons/dental-mobile-dog.png'
          : '/assets/images/cartoons/dental-mobile-cat.png',
      largeImg:
        petBreedSpecies == DOG
          ? '/assets/images/cartoons/dental-dog.png'
          : '/assets/images/cartoons/dental-cat.png',
      altText:
        'Pets covered by Pumpkin Plans before symptoms, diagnosis and treatment occur have cleaning covered for dental disease stages 1-4. Preventative cleaning is not covered if there is no dental disease present. For pets covered by Pumpkin Plans after symptoms, diagnosis, and treatment occur, the cleaning is not covered when there is dental disease present for stage 1-4 or no dental disease.',
    },

    cosmetic_elective: {
      title: 'Cosmetic & elective procedures',
      copy: (
        <span>
          Pumpkin Pet Insurance plans cover diagnostics and treatments for dogs and
          cats suffering from accidents & illnesses.{' '}
          <b>Pumpkin plans do not cover cosmetic or elective procedures</b> pet
          owners may choose to get for their pets for aesthetic or lifestyle reasons,
          such as ear cropping, tail docking, or declawing – even if performed by a
          licensed vet.
        </span>
      ),
      subText: (
        <Body2 className={styles.subtext}>
          General rule of thumb: If a procedure is not recommended by a veterinarian
          to diagnose or treat a covered condition, then it’s most likely not covered
          by Pumpkin Pet Insurance plans.
        </Body2>
      ),
      mobileImg: '/assets/images/cartoons/cosmetic_elective-mobile.png',
      largeImg: '/assets/images/cartoons/cosmetic_elective.png',
      altText:
        'Accident and illness care does not include cosmetic and elective procedures. Rx Meds for Ear Infections is covered but ear cropping is not. Surgery for tail fractures is covered but tail docking is not. Treatment for torn claw is covered but declawing is not.',
    },
  };

  function handleLinkClick(e, analytic) {
    e.stopPropagation();

    if (MODAL_INFO[analytic]) {
      analyticsTrack({
        event: INITIATE_EXPERIMENT,
        category: 'Not Covered and PEP Upsell Modules',
        label: analytic,
      });

      setSelectedItem(analytic);
      toggleModalOpen();
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <span className={classNames(Typography.h4, styles.title)}>
          It’s important to know some of the common things that aren’t covered too.
        </span>

        <div className={styles.itemsContainer}>
          {NOT_COVERED_ITEMS.map((item, card) => (
            <div
              key={card}
              className={styles.item}
              onClick={e => handleLinkClick(e, item.analytic)}
            >
              <div className={styles.itemContainer}>
                <div className={styles.iconContainer}>
                  <img
                    src='/assets/images/paw-huck-blue.svg'
                    alt=''
                    className={styles.icon}
                  />
                </div>

                <div className={styles.linkWrapper}>
                  <Body1 className={styles.itemCopy}>{item.copy}</Body1>

                  <button
                    className={classNames(styles.itemLink, ButtonStyles.cta)}
                    onClick={e => handleLinkClick(e, item.analytic)}
                  >
                    {item.link}

                    <img
                      src='/assets/images/iconImages/arrow-right-blue-clues.svg'
                      alt=''
                    />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>

        <a
          href={samplePolicyUrl}
          className={classNames(styles.link, ButtonStyles.cta)}
          target={'_blank'}
        >
          See the full list of benefits & exclusions in a sample policy
          <img src='/assets/images/iconImages/arrow-right-blue-clues.svg' alt='' />
        </a>
      </div>

      {isModalOpen ? (
        <Modal
          onClose={toggleModalOpen}
          classes={{ container: styles.modalContainer }}
        >
          <span className={classNames(Typography.h2, styles.modalTitle)}>
            {MODAL_INFO[selectedItem].title}
          </span>

          <Body1 className={styles.modalCopy}>{MODAL_INFO[selectedItem].copy}</Body1>

          <div className={styles.modalSection}>
            <div className={styles.imageSection}>
              <img
                src={MODAL_INFO[selectedItem].largeImg}
                alt={MODAL_INFO[selectedItem].altText}
                className={styles.imgLarge}
              />

              <img
                src={MODAL_INFO[selectedItem].mobileImg}
                alt={MODAL_INFO[selectedItem].altText}
                className={styles.imgMobile}
              />

              {selectedItem === 'dental' ? (
                <div className={styles.notes}>
                  <span>
                    *Symptoms may include but aren’t limited to tartar, plaque,
                    gingivitis, halitosis, loose teeth.
                  </span>

                  <span>
                    Provided for illustrative purposes only. Exclusions and
                    limitations apply. Refer to a{' '}
                    <a href={samplePolicyUrl} rel='noopener' target='_blank'>
                      <span className={styles.linkFont}>sample policy</span>
                    </a>{' '}
                    for more details.
                  </span>
                </div>
              ) : null}
            </div>

            <div className={styles.subTextSection}>
              <div>{MODAL_INFO[selectedItem].subText}</div>
            </div>

            <div className={styles.modalSubSection}>
              <div className={styles.subSection}>
                <div className={styles.subSectionIconContainer}>
                  <img src='/assets/images/light-bulb.png' alt='' />
                </div>

                {MODAL_INFO[selectedItem].subText}
              </div>
            </div>
          </div>

          {selectedItem === 'dental' ? (
            <div className={styles.dentalCoverage}>
              <Body1>
                Beyond cleanings, here are some common examples of dental services
                Pumpkin plans typically do & don’t cover:
              </Body1>
              <div className={styles.dentalCoverageDetails}>
                <div>
                  <Body1 isBold>COVERED</Body1>
                  <Body1>
                    <ul className={styles.infoList}>
                      <li>
                        <Body1>
                          Cleanings and other treatment related to stage 1-4
                          periodontal disease (symptoms include tartar, plaque,
                          gingivitis, halitosis, and loose teeth)
                        </Body1>
                      </li>
                      <li>
                        <Body1>
                          Treatment related to other dental illnesses, such as{' '}
                          {petBreedSpecies === CAT ? 'FORLs,' : null} gingival
                          hyperplasia, and stomatitis
                        </Body1>
                      </li>
                      <li>
                        <Body1>
                          Tooth extractions for fractured teeth and other covered
                          conditions
                        </Body1>
                      </li>
                    </ul>
                  </Body1>
                </div>
                <div>
                  <Body1 isBold>NOT COVERED</Body1>
                  <Body1>
                    <ul className={styles.infoList}>
                      <li>
                        <Body1>
                          Routine dental cleanings when no periodontal disease is
                          present
                        </Body1>
                      </li>
                      <li>
                        <Body1>Caps, crowns, and crown amputation</Body1>
                      </li>
                      <li>
                        <Body1>Root canals</Body1>
                      </li>
                      <li>
                        <Body1>Fillings and implants</Body1>
                      </li>
                      <li>
                        <Body1>
                          Planing (even when its related to periodontal disease)
                        </Body1>
                      </li>
                      <li>
                        <Body1>
                          For a full list of exclusions, see a{' '}
                          <a
                            href={samplePolicyUrl}
                            rel='noopener'
                            className={classNames(
                              styles.linkFont,
                              styles.linkCoverSection
                            )}
                            target={'_blank'}
                          >
                            sample policy
                          </a>
                        </Body1>
                      </li>
                    </ul>
                  </Body1>
                </div>
              </div>
            </div>
          ) : null}
          <div className={footerClassName}>
            <div className={styles.footerContactSection}>
              <div className={styles.footerIconContainer}>
                <img className={styles.footerIcon} src={petCallIcon} alt='' />
              </div>

              <div className={styles.imageContainer} />

              <div className={styles.content}>
                <Body2 isBold>
                  Still have questions about{' '}
                  {MODAL_INFO[selectedItem].title.toLowerCase()}?
                </Body2>

                <Body2>Our pet-loving care team is just a paw away.</Body2>

                <div className={styles.contactArea}>
                  <div className={styles.contactItem}>
                    <div className={styles.contactPhoneIcon}>
                      <img
                        className={styles.contactIcon}
                        src='/assets/images/iconImages/phone-image.png'
                        alt=''
                      />
                    </div>

                    <div>
                      <LegalBody className={styles.contactInfo}>
                        <a href={PHONE_NUMBER_LINK} className={styles.linkText}>
                          Call {`${ARF_MEOW_PHONE_NUMBER}`}
                        </a>
                        <span>Mon-Fri 8am-8pm EST</span>
                        <span>Sat 9am - 5pm EST</span>
                      </LegalBody>
                    </div>
                  </div>

                  <div className={styles.contactItem}>
                    <div className={styles.contactIconWrapper}>
                      <img
                        className={styles.contactIcon}
                        src='/assets/images/contact_mail.svg'
                        alt=''
                      />
                    </div>

                    <div>
                      <LegalBody className={styles.contactInfo}>
                        <a href={CONTACT_EMAIL_LINK} className={styles.linkText}>
                          Email {CONTACT_EMAIL}
                        </a>
                        We’ll respond in a day or less
                      </LegalBody>
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.imageContainerBottom} />

              <div className={styles.buttonContainer}>
                <button
                  onClick={toggleModalOpen}
                  className={classNames(ButtonStyles.cta)}
                >
                  Back to Plan Details
                </button>
              </div>
            </div>
          </div>
        </Modal>
      ) : null}
    </div>
  );
}

PlanNotCovered.defaultProps = {
  petBreedSpecies: 'Dog',
};

export default PlanNotCovered;
