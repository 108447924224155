import { useState } from 'react';
import PropTypes from 'prop-types';

import { Body1, Body2, Modal, Superscript2 } from '@pumpkincare/shared/ui';

import styles from './insurance-modal.module.css';

function InsuranceModal({ modalData }) {
  const [isModalOpen, setModalOpen] = useState(false);

  function handleSupscriptClick() {
    setModalOpen(false);
    setTimeout(() => {
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
    }, 100);
  }

  return (
    <>
      <div
        onClick={e => setModalOpen(true)}
        tabIndex='0'
        className={styles.infoIcon}
        role='dialog'
      >
        <img src='/assets/images/iconImages/icon_info.svg' alt='' />
      </div>

      {isModalOpen ? (
        <Modal
          onClose={() => setModalOpen(false)}
          classes={{
            modalDialog: styles.modalDialog,
            container: styles.modalContainer,
          }}
        >
          <div className={styles.modalBody}>
            <h4 className={styles.modalTitle}>{modalData.title}</h4>

            {modalData.sectionItems?.map((item, index) => (
              <div className={styles.modalSection} key={item.title}>
                <Body1 isBold> {item.title}</Body1>

                <Body2>
                  {item.copy}
                  {index === 1 && modalData.hasSup ? (
                    <Superscript2
                      className={styles.supScript}
                      onClick={handleSupscriptClick}
                    >
                      1
                    </Superscript2>
                  ) : null}
                </Body2>
              </div>
            ))}

            <div className={styles.modalFooter}>
              <div className={styles.modalImageWrapper}>
                <img
                  src={modalData.footer?.img}
                  className={styles.modalImage}
                  alt=''
                />
              </div>

              <div>
                <div className={styles.modalSection}>
                  <Body1 isBold> {modalData.footer?.title}</Body1>

                  <Body2>{modalData.footer?.copy}</Body2>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      ) : null}
    </>
  );
}
InsuranceModal.propTypes = {
  modalData: PropTypes.shape({
    title: PropTypes.string,
    sectionItems: PropTypes.array,
    hasSup: PropTypes.bool,
    footer: PropTypes.shape({
      title: PropTypes.string,
      copy: PropTypes.string,
    }),
  }),
};
InsuranceModal.defaultProps = {
  modalData: {},
};

export default InsuranceModal;
