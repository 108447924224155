import { Fragment } from 'react';

import { getPlanWebDescription } from '@pumpkincare/plans';
import { sortLegalData } from '@pumpkincare/quotes';
import { getIsKitten, getIsPuppy, removeDuplicates } from '@pumpkincare/shared';

export function compileLegalData(pets) {
  let allLegal = pets.map(pet => {
    return pet.plan ? getPlanWebDescription(pet.plan) : { legal: [] };
  });

  allLegal = allLegal.reduce((a, b) => {
    return { legal: [...a.legal, ...b.legal] };
  });
  allLegal = removeDuplicates(allLegal.legal, 'content');

  return sortLegalData(
    allLegal.filter(legal => !legal.title.includes('PRESCRIPTION'))
  );
}

export function buildLegalFootNotes(activePet) {
  const { petBreedSpecies } = activePet;

  const breedLabel = getIsPuppy(activePet)
    ? 'puppy'
    : getIsKitten(activePet)
    ? 'kitten'
    : `${petBreedSpecies.toLowerCase()}`;

  const FOOTNOTES = {
    dog: [
      '1. Based on United States Fire Insurance Company claim data, 10/20. The data shows less than 2% of recent ' +
        'dog customers typically submit claims totaling over $10k in a policy year.',
      '2. Horch, AJ, "Most Americans own a pet, but not the insurance 1 in 3 faithful companions will need." ' +
        'cnbc.com, last update 6/19.',
      '3. Based on United States Fire Insurance Company dog claims data for pet insurance plans provided by Pumpkin ' +
        'with policy effective dates between 8/20-10/21. The data shows 56% of dog customers filed an insurance claim ' +
        'in their first policy year.',
      '4. American Pets Product Association (APPA), “2021-2022 APPA National Pet Owners Survey.” Estimated lifetime ' +
        'cost for a dog living 12 years based on annual spend data from survey (12 years at $1,344 per year).',
    ],

    puppy: [
      '1. Based on United States Fire Insurance Company claim data, 10/20. The data shows less than 2% of recent ' +
        'dog customers typically submit claims totaling over $10k in a policy year.',
      '2. Horch, AJ, "Most Americans own a pet, but not the insurance 1 in 3 faithful companions will need." ' +
        'cnbc.com, last update 6/19.',
      '3. Based on United States Fire Insurance Company puppy claims data for pet insurance plans provided by ' +
        'Pumpkin with policy effective dates between 8/20-10/21. The data shows 48% of puppy customers filed an ' +
        'insurance claim in their first policy year.',
      '4. Rover, “The Cost of Dog Parenthood in 2022.” rover.com, accessed 10/22.',
    ],

    kitten: [
      '1. Based on United States Fire Insurance Company claim data, 10/20. The data shows less than 3% of recent' +
        ' cat customers typically submit claims totaling over $7k in a policy year.',
      '2. Horch, AJ, "Most Americans own a pet, but not the insurance 1 in 3 faithful companions will need." ' +
        'cnbc.com, last update 6/19.',
      '3. Based on United States Fire Insurance Company kitten claims data for pet insurance plans provided ' +
        'by Pumpkin with policy effective dates between 8/20-11/21. The data shows 35% of kitten customers filed an ' +
        'insurance claim in their first policy year.',
      '4. Rover, “The Cost of Cat Parenthood in 2022.” rover.com, accessed 2/23.',
    ],

    cat: [
      '1. Based on United States Fire Insurance Company claim data, 10/20. The data shows less than 3% of recent' +
        ' cat customers typically submit claims totaling over $7k in a policy year.',
      '2. Horch, AJ, "Most Americans own a pet, but not the insurance 1 in 3 faithful companions will need." ' +
        'cnbc.com, last update 6/19.',
      '3. Based on United States Fire Insurance Company cat claims data for pet insurance plans provided by ' +
        'Pumpkin with policy effective dates between 8/20-11/21. The data shows 38% of cat customers filed an ' +
        'insurance claim in their first policy year.',
      '4. American Pets Product Association (APPA), “2021-2022 APPA National Pet Owners Survey.” Estimated ' +
        'lifetime cost for a cat living 15 years based on annual spend data from survey (15 years at $801 per year).',
    ],
  };

  return (
    <>
      {FOOTNOTES[breedLabel].map((item, i) => (
        <Fragment key={i}>
          {item} <br />
        </Fragment>
      ))}
    </>
  );
}
