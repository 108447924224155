import { createSelector } from 'reselect';

import { getIdentityId } from '../../../identity';
import { getAppAgentId } from './app-selectors';

const getLaunchDarklyUser = createSelector(
  [getIdentityId, getAppAgentId],
  (identityId, agentId) => {
    const user = identityId ? { key: identityId } : { anonymous: true };

    if (window.Cypress) {
      user.country = 'e2e';
    } else if (agentId) {
      user.country = 'agent';
    }

    return user;
  }
);

export default getLaunchDarklyUser;
