import Store from 'store';

import { ENVIRONMENT } from '@pumpkincare/config';
import { useMarketingAttribution } from '@pumpkincare/marketing';
import { BrandXPixel, CjPixel, TunePixel } from '@pumpkincare/marketing/ui';
import { getQuoteFirstName, useQuote } from '@pumpkincare/quotes';
import {
  ARF_MEOW_PHONE_NUMBER,
  COMPLETE_QUOTE_ID_LS_KEY,
  FORMATTED_PHONE_NUMBER,
  GRAND_TOTAL_LOCAL_STORAGE_KEY,
} from '@pumpkincare/shared';
import { Body1, SocialMedia } from '@pumpkincare/shared/ui';

import styles from './check-your-email.module.css';

function CheckYourEmail() {
  const grandTotal = Store.get(GRAND_TOTAL_LOCAL_STORAGE_KEY);
  const quoteId = Store.get(COMPLETE_QUOTE_ID_LS_KEY);

  const { data: firstName } = useQuote({ quoteId, select: getQuoteFirstName });

  // this will account for leaving the page and returning and for refreshing the page
  const isFirstVisitWithAttribution = useMarketingAttribution(
    quoteId,
    'Land on Checkout Success',
    'isGoingToCheckoutSuccess'
  );

  return (
    <div className={styles.container}>
      <div className={styles.desktop}>
        <div className={`${styles.circle} ${styles.circle1}`} />
        <div className={`${styles.circle} ${styles.circle2}`} />
        <div className={`${styles.circle} ${styles.circle3}`} />
        <div className={`${styles.circle} ${styles.circle4}`} />
        <div className={`${styles.circle} ${styles.circle5}`} />
        <div className={`${styles.circle} ${styles.circle6}`} />
      </div>
      <img src='/assets/images/success-pug.png' alt='' className={styles.pug} />

      <div className={styles.contentWrapper}>
        <h1 className={styles.heading}>Welcome to the family!</h1>
        <div className={styles.paragraphWrapper}>
          <h4>{firstName || 'Bella'}, Check your email...</h4>
          <Body1 className={styles.confirmationText}>
            We’ve sent you a confirmation email with a link to set up your account
            and password.
          </Body1>
          <div className={styles.divider} />
          <div className={styles.socialWrapper}>
            <Body1>Have questions? Call our Pumpkin Care Team at</Body1>
            <Body1>{`${ARF_MEOW_PHONE_NUMBER} (${FORMATTED_PHONE_NUMBER})`}</Body1>

            <SocialMedia
              containerProps={{ className: styles.socialIcons }}
              imageProps={{ className: styles.socialIcon }}
            />
          </div>
        </div>
      </div>

      <BrandXPixel environment={ENVIRONMENT} quoteId={quoteId} />

      {isFirstVisitWithAttribution ? (
        <>
          <CjPixel page='Purchase' quoteId={quoteId} checkoutTotal={grandTotal} />
          <TunePixel page='Sale' quoteId={quoteId} checkoutTotal={grandTotal} />
        </>
      ) : null}
    </div>
  );
}

export default CheckYourEmail;
