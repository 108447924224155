// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".single-select_group_a7473252 {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    flex-wrap: nowrap;\n    justify-content: center;\n}\n\n.single-select_group4x4_d4c391a4 {\n    flex-direction: row;\n    flex-wrap: wrap;\n}\n\n.single-select_group_a7473252 .single-select_groupItem4x4_75e130ac {\n    width: 128px;\n    height: 128px;\n    margin: 4px;\n\n    box-sizing: border-box;\n}\n\n.single-select_group_a7473252 .single-select_groupItem_3cd76b9a {\n    width: auto;\n    margin: 8px 0;\n    align-items: flex-start;\n}", "",{"version":3,"sources":["webpack://src/personalization/view/single-select/single-select.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,iBAAiB;IACjB,uBAAuB;AAC3B;;AAEA;IACI,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,WAAW;;IAEX,sBAAsB;AAC1B;;AAEA;IACI,WAAW;IACX,aAAa;IACb,uBAAuB;AAC3B","sourcesContent":[".group {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    flex-wrap: nowrap;\n    justify-content: center;\n}\n\n.group4x4 {\n    flex-direction: row;\n    flex-wrap: wrap;\n}\n\n.group .groupItem4x4 {\n    width: 128px;\n    height: 128px;\n    margin: 4px;\n\n    box-sizing: border-box;\n}\n\n.group .groupItem {\n    width: auto;\n    margin: 8px 0;\n    align-items: flex-start;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"group": "single-select_group_a7473252",
	"group4x4": "single-select_group4x4_d4c391a4",
	"groupItem4x4": "single-select_groupItem4x4_75e130ac",
	"groupItem": "single-select_groupItem_3cd76b9a"
};
export default ___CSS_LOADER_EXPORT___;
