// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".component_componentWrapper_2a1e4e23 {\n  width: 100%;\n  position: relative;;\n}\n\n.component_transitionGroup_5a6ff993 {\n  position: relative;\n  max-width: 100%;\n}\n\n.component_transition-enter_66ed65a3 {\n  opacity: 0;\n  position: absolute;\n}\n.component_transition-enter-done_dd3ad787 {\n  opacity: 1;\n  position: relative;\n}\n\n.component_transition-enter-active_425c2e8c {\n  opacity: 1;\n  transition: opacity 500ms;\n  transition-delay: 500ms;\n  position: absolute;\n}\n.component_transition-exit_c5f8244d {\n  opacity: 1;\n  position: absolute;\n}\n.component_transition-exit-active_d5aeaad7 {\n  opacity: 0;\n  transition: opacity 500ms;\n  position: absolute;\n}", "",{"version":3,"sources":["webpack://src/quotes/view/shared/wrapper/component/quote-flow-transition.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,UAAU;EACV,kBAAkB;AACpB;AACA;EACE,UAAU;EACV,kBAAkB;AACpB;;AAEA;EACE,UAAU;EACV,yBAAyB;EACzB,uBAAuB;EACvB,kBAAkB;AACpB;AACA;EACE,UAAU;EACV,kBAAkB;AACpB;AACA;EACE,UAAU;EACV,yBAAyB;EACzB,kBAAkB;AACpB","sourcesContent":[".componentWrapper {\n  width: 100%;\n  position: relative;;\n}\n\n.transitionGroup {\n  position: relative;\n  max-width: 100%;\n}\n\n.transition-enter {\n  opacity: 0;\n  position: absolute;\n}\n.transition-enter-done {\n  opacity: 1;\n  position: relative;\n}\n\n.transition-enter-active {\n  opacity: 1;\n  transition: opacity 500ms;\n  transition-delay: 500ms;\n  position: absolute;\n}\n.transition-exit {\n  opacity: 1;\n  position: absolute;\n}\n.transition-exit-active {\n  opacity: 0;\n  transition: opacity 500ms;\n  position: absolute;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"componentWrapper": "component_componentWrapper_2a1e4e23",
	"transitionGroup": "component_transitionGroup_5a6ff993",
	"transition-enter": "component_transition-enter_66ed65a3",
	"transition-enter-done": "component_transition-enter-done_dd3ad787",
	"transition-enter-active": "component_transition-enter-active_425c2e8c",
	"transition-exit": "component_transition-exit_c5f8244d",
	"transition-exit-active": "component_transition-exit-active_d5aeaad7"
};
export default ___CSS_LOADER_EXPORT___;
