import { transformQuoteRedux } from '@pumpkincare/quotes';
import { captureException } from '@pumpkincare/shared';

import { getQuotes } from '../selectors/quotes-selectors';
import { setQuoteResponseV2 } from '../state/quotes-ducks';

export default function fetchQuoteById(quoteId) {
  return (dispatch, getState, { quotesService }) => {
    return quotesService
      .fetchQuoteById(quoteId)
      .then(quoteResponse => {
        const quote = transformQuoteRedux(quoteResponse, getQuotes(getState()));

        if (quote.pets.length > 0) {
          // it might be the case quote doesn't have any pets
          dispatch(setQuoteResponseV2(quote));
        }

        return quote;
      })
      .catch(captureException);
  };
}
