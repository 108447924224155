// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".edit-pet_editPet_17d93482 {\n  width: 100%;\n}\n\n.edit-pet_speciesChoice_c56d7a4c {\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n   margin: 32px 0 0;\n  justify-content: space-between;\n  gap: 16px;\n}\n\n.edit-pet_speciesChoiceError_99b13de8 {\n  margin: 40px 0 12px;\n}\n\n.edit-pet_speciesChoice_c56d7a4c .edit-pet_speciesChoiceItem_f7fe561a {\n  width: 152px; /* 16px H paddings + 2px borders */\n  height: 48px;\n}\n\n.edit-pet_editPet_17d93482 .edit-pet_textfield_383a0f41 {\n  width: 100%;\n  margin: 0;\n  padding-bottom: 0;\n}\n\n.edit-pet_editPet_17d93482 .edit-pet_errorText_9fad2393 {\n  color: var(--cliffordRed);\n  text-align: left;\n  margin: 8px 0 0 ;\n}\n", "",{"version":3,"sources":["webpack://src/quotes/view/introduction/edit-pet/edit-pet.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;GAClB,gBAAgB;EACjB,8BAA8B;EAC9B,SAAS;AACX;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,YAAY,EAAE,kCAAkC;EAChD,YAAY;AACd;;AAEA;EACE,WAAW;EACX,SAAS;EACT,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,gBAAgB;AAClB","sourcesContent":[".editPet {\n  width: 100%;\n}\n\n.speciesChoice {\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n   margin: 32px 0 0;\n  justify-content: space-between;\n  gap: 16px;\n}\n\n.speciesChoiceError {\n  margin: 40px 0 12px;\n}\n\n.speciesChoice .speciesChoiceItem {\n  width: 152px; /* 16px H paddings + 2px borders */\n  height: 48px;\n}\n\n.editPet .textfield {\n  width: 100%;\n  margin: 0;\n  padding-bottom: 0;\n}\n\n.editPet .errorText {\n  color: var(--cliffordRed);\n  text-align: left;\n  margin: 8px 0 0 ;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"editPet": "edit-pet_editPet_17d93482",
	"speciesChoice": "edit-pet_speciesChoice_c56d7a4c",
	"speciesChoiceError": "edit-pet_speciesChoiceError_99b13de8",
	"speciesChoiceItem": "edit-pet_speciesChoiceItem_f7fe561a",
	"textfield": "edit-pet_textfield_383a0f41",
	"errorText": "edit-pet_errorText_9fad2393"
};
export default ___CSS_LOADER_EXPORT___;
