import { captureException } from '@pumpkincare/shared';

import { getAppNoopPayload } from '../../app-shell';

export default function deletePetFromQuote(quoteId, quotePetId) {
  return (dispatch, getState, { quotesService }) => {
    return quotesService
      .deletePetFromQuote({
        quoteId,
        quotePetId,
        noopPayload: getAppNoopPayload(getState()),
      })
      .catch(captureException);
  };
}
