import { matchPath, useLocation } from 'react-router';
import classNames from 'classnames';

import { WWW_URL } from '@pumpkincare/config';
import { Link, Typography } from '@pumpkincare/shared/ui';

import Paths from '../../../paths';

import styles from './learn-more.module.css';

function LearnMore() {
  const { pathname } = useLocation();

  const isQFPages = !!matchPath(pathname, [Paths.PlanSelection, Paths.Checkout]);

  const pawIconClassName = classNames(styles.pawIcon, {
    [styles.left]: !isQFPages,
    [styles.right]: !!isQFPages,
  });
  return (
    <>
      <div className={styles.lightBlueWave}></div>
      <img className={pawIconClassName} src='/assets/images/paw-blue-clues.svg' />

      <div className={styles.container}>
        <div>
          <h4 className={styles.title}>Learn More</h4>
        </div>

        <div className={styles.bodyText}>
          <Link
            href={`${WWW_URL}/sample-plan`}
            rel='noopener noreferrer'
            target='_blank'
          >
            <span className={classNames(Typography.body2, styles.body1)}>
              Read a Sample Policy
            </span>
          </Link>

          <Link href={`${WWW_URL}/faqs`} rel='noopener noreferrer' target='_blank'>
            <span className={classNames(Typography.body2, styles.body1)}>
              Explore more with FAQs
            </span>
          </Link>
        </div>
      </div>
    </>
  );
}

export default LearnMore;
