import { useSelector } from 'react-redux';

import { usePetPlanData } from '@pumpkincare/plans';
import { getQuoteActivePet, getQuoteVetId, useQuote } from '@pumpkincare/quotes';

import { getIdentityId } from '../../../identity';
import { PlanSelection } from '../../../plans';
import { getQuotesActiveId } from '../../../quotes/selectors';
import Page from '../page';

function PlanPage() {
  const activeId = useSelector(getQuotesActiveId);
  const identityId = useSelector(getIdentityId);

  const { data: quoteData } = useQuote();
  const activePet = getQuoteActivePet(quoteData)(activeId);
  const vetId = getQuoteVetId(quoteData);
  const { isReady } = usePetPlanData({ petId: activePet?.id, identityId, vetId });

  return (
    <Page isNavBarVisible={isReady}>
      <PlanSelection />
    </Page>
  );
}

export default PlanPage;
