import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useBooleanInput } from '@pumpkincare/shared';
import { LegalBody } from '@pumpkincare/shared/ui';

import { Interstitial } from '../../../quotes';
import QuoteFlowHeader from '../../../quotes/view/quote-flow-header';
import { QuoteFlowTransition } from '../../../quotes/view/shared';
import {
  answerOptionsComponentMap,
  INTERSTITIAL,
} from '../../model/answer-options-component-map';
import { getPersonalizationQuestionVariant } from '../../selectors/personalization-selectors';
import postAnswers from '../../thunks/post-answers';

import styles from './pet-questions.module.css';

function PetQuestions() {
  const dispatch = useDispatch();
  const questionVariant = useSelector(getPersonalizationQuestionVariant);
  const answer = useRef([]);
  const [isProcessing, toggleProcessing] = useBooleanInput(true);

  const {
    answer_options = [],
    id = '',
    img_src = '',
    variant_type = '',
    variant_text = '',
    subtitle = '',
    module = null,
  } = questionVariant || {};
  const AnswerOptionsComponent = answerOptionsComponentMap[variant_type];

  useEffect(() => {
    /*
      if user returns to page with id already loaded, QuoteFlowComponent
      transition, (and handleTransitionExit) isn't triggered. toggleProcessing to
      re-enable buttons
     */
    if (isProcessing && id) {
      toggleProcessing();
    }
  }, []);

  const subtitleView = subtitle ? (
    <LegalBody className={styles.subtitle}>{subtitle}</LegalBody>
  ) : null;
  const questionView = (
    <>
      <QuoteFlowHeader className={styles.header}>{variant_text}</QuoteFlowHeader>

      {subtitleView}

      {AnswerOptionsComponent ? (
        <AnswerOptionsComponent
          data={answer_options}
          currentAnswer={answer.current}
          onChange={handleAnswersChange}
          autoFocus
        />
      ) : null}
    </>
  );

  function handleAnswersChange(data) {
    if (!isProcessing) {
      toggleProcessing();
      answer.current = Array.isArray(data) ? data : [data];

      dispatch(postAnswers(answer.current));
    }
  }

  function handleNextClick() {
    if (!isProcessing) {
      toggleProcessing();

      dispatch(postAnswers(answer.current));
    }
  }

  function handleTransitionExit() {
    if (isProcessing) {
      toggleProcessing();
      answer.current = [];
    }
  }

  return (
    // use 'id' to trigger transition
    <QuoteFlowTransition
      location={{ key: id }}
      onTransitionExit={handleTransitionExit}
    >
      {variant_type === INTERSTITIAL ? (
        <Interstitial
          petImage={img_src}
          text1={variant_text}
          text2={subtitle}
          disabled={isProcessing}
          onClick={handleNextClick}
          module={module}
        />
      ) : (
        questionView
      )}
    </QuoteFlowTransition>
  );
}

export default PetQuestions;
