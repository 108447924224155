import { push } from 'connected-react-router';

import { postIdentitiesNextQuestionVariant } from '@pumpkincare/identity';
import { captureException } from '@pumpkincare/shared';

import { getIdentityId } from '../../identity/selectors';
import { getQuoteFlowNextStep, getQuotesActivePet } from '../../quotes/selectors';
import { setQuestionVariant } from '../state/personalization-ducks';

export default function fetchQuestionVariantThunk() {
  return (dispatch, getState) => {
    const state = getState();

    postIdentitiesNextQuestionVariant(
      getIdentityId(state),
      getQuotesActivePet(state).petBreedSpecies
    )
      .then(response => {
        if (response.status === 200) {
          dispatch(setQuestionVariant(response.data));
        } else if (response.status === 204) {
          dispatch(push(getQuoteFlowNextStep(getState())));
          dispatch(setQuestionVariant(null));
        }
      })
      .catch(captureException);
  };
}
