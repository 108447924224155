import { INITIAL } from '@pumpkincare/shared';

const SET_PLAN_PAGE_LOAD_STATUS = 'plans/SET_PLAN_PAGE_LOAD_STATUS';
const SET_PLAN_PAGE_SUBMIT_STATUS = 'plans/SET_PLAN_PAGE_SUBMIT_STATUS';
const SET_PLAN_PRODUCTS = 'plans/SET_PLAN_PRODUCTS';
const SET_PLAN_RECOMMENDATION_OPTIONS = 'plans/SET_PLAN_RECOMMENDATION_OPTIONS';
const SET_PLAN_POLICY_OPTIONS = 'plans/SET_PLAN_POLICY_OPTIONS';
const SET_CART_VISIBILITY = 'plans/SET_CART_VISIBILITY';

const initialState = {
  pageSubmitStatus: INITIAL,
  pageLoadStatus: INITIAL,
  products: {
    insurance: false,
    prevent: false,
  },
  recommendationOptions: {},
  policyOptions: {},
  cartVisibility: false,
};

export default function plansReducer(state = initialState, { payload, type }) {
  switch (type) {
    case SET_PLAN_PAGE_LOAD_STATUS: {
      return {
        ...state,
        pageLoadStatus: payload,
      };
    }

    case SET_PLAN_PAGE_SUBMIT_STATUS: {
      return {
        ...state,
        pageSubmitStatus: payload,
      };
    }

    case SET_PLAN_PRODUCTS: {
      return {
        ...state,
        products: payload,
      };
    }

    case SET_PLAN_RECOMMENDATION_OPTIONS: {
      return {
        ...state,
        recommendationOptions: {
          ...state.recommendationOptions,
          [payload.petId]: payload.options,
        },
      };
    }

    case SET_PLAN_POLICY_OPTIONS: {
      return {
        ...state,
        policyOptions: {
          ...state.policyOptions,
          [payload.petId]: payload.options,
        },
      };
    }

    case SET_CART_VISIBILITY: {
      return {
        ...state,
        cartVisibility: payload,
      };
    }

    default: {
      return state;
    }
  }
}

export function setPlanPageLoadStatus(status) {
  return {
    type: SET_PLAN_PAGE_LOAD_STATUS,
    payload: status,
  };
}

export function setPlanPageSubmitStatus(payload) {
  return {
    type: SET_PLAN_PAGE_SUBMIT_STATUS,
    payload,
  };
}

export function setPlanProducts(payload) {
  return { type: SET_PLAN_PRODUCTS, payload };
}

export function setPlanRecommendationOptions(petId, options) {
  return { type: SET_PLAN_RECOMMENDATION_OPTIONS, payload: { petId, options } };
}

export function setPlanPolicyOptions(petId, options) {
  return { type: SET_PLAN_POLICY_OPTIONS, payload: { petId, options } };
}

export function setPlansCartVisibility(payload) {
  return { type: SET_CART_VISIBILITY, payload };
}
