import { useEffect } from 'react';

import { checkMarketingAttributionForExpiry } from '@pumpkincare/marketing';

import Routes from '../routes';

function App() {
  useEffect(() => {
    checkMarketingAttributionForExpiry();
  }, []);

  return (
    <div
      style={{
        margin: 'auto',
        backgroundColor: 'var(--zeroWhite)',
        fontFamily: 'var(--main-font)',
      }}
    >
      <Routes />
    </div>
  );
}

export default App;
