import {
  ANNUAL_LIMIT,
  DEDUCTIBLE,
  isUnlimitedLimit,
  zeroesToK,
} from '@pumpkincare/plans';
import { CAT, DOG } from '@pumpkincare/shared';
import { Body1, Body2, Typography } from '@pumpkincare/shared/ui';

import InsuranceModal from '../insurance-modal';

import styles from './levers.module.css';

const LEVERS_TO_TITLES_MAP = {
  limits: 'Annual coverage limit',
  deductibles: 'Annual Deductible',
};

const LEVERS_TO_RECOMMENDATIONS_MAP = {
  limits: ANNUAL_LIMIT,
  deductibles: DEDUCTIBLE,
};

const leversObj = {
  [DOG]: {
    limits: {
      title: 'Annual Coverage Limit',
      sectionItems: [
        {
          title: 'What is it?',
          copy: 'The max amount of eligible vet bills your pet insurance plan can reimburse you for each policy year.',
        },
        {
          title: 'Which do I choose?',
          copy:
            '$10k is a high enough annual limit for 98% of recent pupstomers, but we also offer $20k & Unlimited if you’re ' +
            'worried about high vet bills or want extra peace of mind.',
        },
      ],
      hasSup: true,
      footer: {
        title: 'Ruff! Ruff!',
        copy: 'You can’t increase your annual limit after you set it, so choose carefully!',
        img: '/assets/images/unlimited-cash-dog.png',
      },
    },

    deductibles: {
      title: 'Annual Deductible',
      sectionItems: [
        {
          title: 'What is it?',
          copy: (
            <span>
              The amount you pay upfront towards eligible vet bills each policy year{' '}
              <span className={Typography.bold}>before</span> your 90% cash back
              kicks in.
            </span>
          ),
        },
        {
          title: 'Which do I choose?',
          copy:
            'Choose $500 or $1k if you’re OK paying that sum towards eligible vet bills before your cash back kicks ' +
            'in. Want cash back sooner when your dog gets sick or hurt? Try $100 or $250.',
        },
      ],
      footer: {
        title: 'Awooooo!',
        copy: 'The higher the annual deductible, the lower your monthly cost!',
        img: '/assets/images/doctor-dog.png',
      },
    },
  },
  [CAT]: {
    limits: {
      title: 'Annual Coverage Limit',
      sectionItems: [
        {
          title: 'What is it?',
          copy: 'The max amount of eligible vet bills your pet insurance plan can reimburse you for each policy year.',
        },
        {
          title: 'Which do I choose?',
          copy:
            '$7k is a high enough annual limit for 97% of recent catstomers, but we also offer $15k & Unlimited ' +
            'if you’re worried about high vet bills or want extra peace of mind.',
        },
      ],
      hasSup: true,
      footer: {
        title: 'Prrr! Prrr!',
        copy: 'You can’t increase your annual limit after you set it, so choose carefully!',
        img: '/assets/images/unlimited-cash-cat.png',
      },
    },

    deductibles: {
      title: 'Annual Deductible',
      sectionItems: [
        {
          title: 'What is it?',
          copy: (
            <span>
              The amount you pay upfront towards eligible vet bills each policy year{' '}
              <span className={Typography.bold}>before</span> your 90% cash back
              kicks in.
            </span>
          ),
        },
        {
          title: 'Which do I choose?',
          copy:
            'Choose $500 or $1k if you’re OK paying that sum towards eligible vet bills before your cash back kicks ' +
            'in. Want cash back sooner when your cat gets sick or hurt? Try $100 or $250.',
        },
      ],
      footer: {
        title: 'Mrrrrrrrrrrawr!',
        copy: 'The higher the annual deductible, the lower your monthly cost!',
        img: '/assets/images/doctor-cat.png',
      },
    },
  },
};

function Levers({
  activeValues = {},
  levers = {},
  recommendations = {},
  onChange,
  pet,
}) {
  const { petBreedSpecies } = pet;

  return (
    <>
      {Object.keys(levers).map(leverKey => {
        return (
          <div key={leverKey} className={styles.container}>
            <div
              className={styles.leverRecommendation}
              data-testid='lever-recommendation'
            >
              <div className={styles.titleWrapper}>
                <Body1 className={styles.leverTitle}>
                  {LEVERS_TO_TITLES_MAP[leverKey]}
                </Body1>

                <InsuranceModal
                  modalData={leversObj[petBreedSpecies]?.[leverKey] ?? {}}
                />
              </div>

              {Object.keys(recommendations).length > 0 ? (
                <div>
                  <img
                    className={styles.inlineImg}
                    alt=''
                    src='/assets/images/iconImages/star.png'
                  />
                  &nbsp;
                  <span className={styles.recommendedValue}>
                    {`$${zeroesToK(
                      recommendations[LEVERS_TO_RECOMMENDATIONS_MAP[leverKey]].value
                    )} Recommended`}
                  </span>
                </div>
              ) : null}
            </div>

            <div className={styles.levers}>
              {levers[leverKey].map(item => {
                const isSelected =
                  activeValues[item.option.key] === item.option.value;

                return (
                  <button
                    key={item.title}
                    className={styles.lever}
                    aria-pressed={isSelected}
                    onClick={() => onChange?.(item.option)}
                  >
                    <Body2 isBold className={styles.leverTitle}>
                      {isUnlimitedLimit(item.option.value)
                        ? item.option.value
                        : `$${Number(item.option.value).toLocaleString('en')}`}
                    </Body2>
                  </button>
                );
              })}
            </div>
          </div>
        );
      })}
    </>
  );
}

export default Levers;
