/* eslint-disable react/jsx-no-target-blank */
import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { GaLink } from '@pumpkincare/analytics/ui';
import { BLOG_URL, MC_URL, WWW_URL } from '@pumpkincare/config';
import {
  ARF_MEOW_PHONE_NUMBER,
  PHONE_ARIA_LABEL,
  PHONE_NUMBER_LINK,
} from '@pumpkincare/shared';
import { ButtonStyles, Link, Typography } from '@pumpkincare/shared/ui';

import styles from './mobile-menu.module.css';

function MobileMenu({ onItemClick }) {
  const firstElem = useRef();
  const logoElem = useRef();
  const [menuState, setMenuState] = useState({
    unselectAll: false,

    petInsurance: false,
    preventiveCare: false,
    company: false,
    compare: false,
    claims: false,
    advice: false,
  });

  function toggleMenuItem(item) {
    setMenuState(state => ({
      petInsurance: false,
      preventiveCare: false,
      company: false,
      compare: false,
      claims: false,
      advice: false,
      unselectAll: state[item],
      [item]: !state[item],
    }));
  }

  useEffect(() => {
    logoElem.current.focus();
  }, [logoElem]);

  return (
    <>
      <div className={styles.backdrop} onClick={onItemClick}>
        &nbsp;
      </div>

      <div className={styles.root}>
        <div className={styles.logo} ref={logoElem} tabIndex='0'>
          <button
            autoFocus={true}
            className={ButtonStyles.unset}
            onClick={onItemClick}
            ref={firstElem}
          >
            <img alt='hide menu' src='/assets/images/iconImages/chevron.svg' />
          </button>

          <Link
            href={WWW_URL}
            target='_blank'
            rel='noopener'
            onClick={onItemClick}
            title='Home'
            tabIndex='-1'
            className={styles.logoContainer}
          >
            <img
              alt=''
              role='presentation'
              src='/assets/images/Pumpkin_Logo_White.svg'
              className={styles.logoImg}
            />
          </Link>
        </div>

        <div className={styles.menuContainer}>
          <ul className={classNames(Typography.body2, Typography.bold, styles.menu)}>
            <li className={styles.menuItem}>
              <input
                type='radio'
                name='mobileMenu'
                id='unselectAllRadio'
                checked={menuState.unselectAll}
                readOnly
              />
              <input
                type='radio'
                name='mobileMenu'
                id='petInsuranceRadio'
                checked={menuState.petInsurance}
                readOnly
              />

              <label
                htmlFor='petInsuranceRadio'
                onClick={() => toggleMenuItem('petInsurance')}
              >
                <button className={ButtonStyles.unset}>Pet Insurance</button>

                <span className={styles.expandIcon}>&nbsp;</span>
              </label>

              <ul className={styles.dropdown}>
                <li
                  className={classNames(Typography.legalBody, styles.dropdownItem)}
                  onClick={onItemClick}
                >
                  <GaLink
                    data-event='Click For Dogs'
                    data-label='Navigation Bar'
                    data-category='About Pumpkin'
                    href={`${WWW_URL}/dog-health-insurance`}
                    rel='noopener'
                    target='_blank'
                  >
                    Dog Pet Insurance
                  </GaLink>
                </li>

                <li
                  className={classNames(Typography.legalBody, styles.dropdownItem)}
                  onClick={onItemClick}
                >
                  <GaLink
                    data-event='Click For Cats'
                    data-label='Navigation Bar'
                    data-category='About Pumpkin'
                    href={`${WWW_URL}/puppy-insurance`}
                    rel='noopener'
                    target='_blank'
                  >
                    Puppy Pet Insurance
                  </GaLink>
                </li>

                <li
                  className={classNames(Typography.legalBody, styles.dropdownItem)}
                  onClick={onItemClick}
                >
                  <GaLink
                    data-event='Click For Cats'
                    data-label='Navigation Bar'
                    data-category='About Pumpkin'
                    href={`${WWW_URL}/cat-health-insurance`}
                    rel='noopener'
                    target='_blank'
                  >
                    Cat Pet Insurance
                  </GaLink>
                </li>

                <li
                  className={classNames(Typography.legalBody, styles.dropdownItem)}
                  onClick={onItemClick}
                >
                  <GaLink
                    data-event='Click For Cats'
                    data-label='Navigation Bar'
                    data-category='About Pumpkin'
                    href={`${WWW_URL}/kitten-insurance`}
                    rel='noopener'
                    target='_blank'
                  >
                    Kitten Pet Insurance
                  </GaLink>
                </li>
              </ul>
            </li>

            <li className={styles.menuItem}>
              <input
                type='radio'
                name='mobileMenu'
                id='preventiveCareRadio'
                checked={menuState.preventiveCare}
                readOnly
              />
              <label
                htmlFor='preventiveCareRadio'
                onClick={() => toggleMenuItem('preventiveCare')}
              >
                <button className={ButtonStyles.unset}>Preventive Care</button>

                <span className={styles.expandIcon}>&nbsp;</span>
              </label>

              <ul className={styles.dropdown}>
                <li
                  className={classNames(Typography.legalBody, styles.dropdownItem)}
                  onClick={onItemClick}
                >
                  <Link
                    href={`${WWW_URL}/dog-preventativecare`}
                    rel='noopener'
                    target='_blank'
                  >
                    Preventive Essentials for Dogs & Puppies
                  </Link>
                </li>

                <li
                  className={classNames(Typography.legalBody, styles.dropdownItem)}
                  onClick={onItemClick}
                >
                  <Link
                    href={`${WWW_URL}/cat-preventativecare`}
                    rel='noopener'
                    target='_blank'
                  >
                    Preventive Essentials for Cats & Kittens
                  </Link>
                </li>
              </ul>
            </li>

            <li className={styles.menuItem}>
              <input
                type='radio'
                name='mobileMenu'
                id='compareRadio'
                checked={menuState.compare}
                readOnly
              />
              <label
                htmlFor='compareRadio'
                onClick={() => toggleMenuItem('compare')}
              >
                <button className={ButtonStyles.unset}>Compare</button>

                <span className={styles.expandIcon}>&nbsp;</span>
              </label>

              <ul className={styles.dropdown}>
                <li
                  className={classNames(Typography.legalBody, styles.dropdownItem)}
                  onClick={onItemClick}
                >
                  <Link href={`${WWW_URL}/compare`} rel='noopener' target='_blank'>
                    Compare Pumpkin
                  </Link>
                </li>

                <li
                  className={classNames(Typography.legalBody, styles.dropdownItem)}
                  onClick={onItemClick}
                >
                  <Link
                    href={`${WWW_URL}/compare/trupanion`}
                    rel='noopener'
                    target='_blank'
                  >
                    Trupanion
                  </Link>
                </li>

                <li
                  className={classNames(Typography.legalBody, styles.dropdownItem)}
                  onClick={onItemClick}
                >
                  <Link
                    href={`${WWW_URL}/compare/nationwide`}
                    rel='noopener'
                    target='_blank'
                  >
                    Nationwide
                  </Link>
                </li>

                <li
                  className={classNames(Typography.legalBody, styles.dropdownItem)}
                  onClick={onItemClick}
                >
                  <Link
                    href={`${WWW_URL}/compare/healthy-paws`}
                    rel='noopener'
                    target='_blank'
                  >
                    Healthy Paws
                  </Link>
                </li>

                <li
                  className={classNames(Typography.legalBody, styles.dropdownItem)}
                  onClick={onItemClick}
                >
                  <Link
                    href={`${WWW_URL}/compare/figo`}
                    rel='noopener'
                    target='_blank'
                  >
                    Figo
                  </Link>
                </li>

                <li
                  className={classNames(Typography.legalBody, styles.dropdownItem)}
                  onClick={onItemClick}
                >
                  <Link
                    href={`${WWW_URL}/compare/petplan`}
                    rel='noopener'
                    target='_blank'
                  >
                    Petplan
                  </Link>
                </li>

                <li
                  className={classNames(Typography.legalBody, styles.dropdownItem)}
                  onClick={onItemClick}
                >
                  <Link
                    href={`${WWW_URL}/compare/embrace`}
                    rel='noopener'
                    target='_blank'
                  >
                    Embrace
                  </Link>
                </li>

                <li
                  className={classNames(Typography.legalBody, styles.dropdownItem)}
                  onClick={onItemClick}
                >
                  <Link
                    href={`${WWW_URL}/compare/lemonade`}
                    rel='noopener'
                    target='_blank'
                  >
                    Lemonade
                  </Link>
                </li>

                <li
                  className={classNames(Typography.legalBody, styles.dropdownItem)}
                  onClick={onItemClick}
                >
                  <Link
                    href={`${WWW_URL}/compare/pets-best`}
                    rel='noopener'
                    target='_blank'
                  >
                    Pets Best
                  </Link>
                </li>

                <li
                  className={classNames(Typography.legalBody, styles.dropdownItem)}
                  onClick={onItemClick}
                >
                  <Link
                    href={`${WWW_URL}/compare/geico`}
                    rel='noopener'
                    target='_blank'
                  >
                    Geico
                  </Link>
                </li>

                <li
                  className={classNames(Typography.legalBody, styles.dropdownItem)}
                  onClick={onItemClick}
                >
                  <Link
                    href={`${WWW_URL}/compare/progressive`}
                    rel='noopener'
                    target='_blank'
                  >
                    Progressive
                  </Link>
                </li>

                <li
                  className={classNames(Typography.legalBody, styles.dropdownItem)}
                  onClick={onItemClick}
                >
                  <Link
                    href={`${WWW_URL}/compare/akc`}
                    rel='noopener'
                    target='_blank'
                  >
                    AKC
                  </Link>
                </li>

                <li
                  className={classNames(Typography.legalBody, styles.dropdownItem)}
                  onClick={onItemClick}
                >
                  <Link
                    href={`${WWW_URL}/compare/metlife`}
                    rel='noopener'
                    target='_blank'
                  >
                    Metlife
                  </Link>
                </li>

                <li
                  className={classNames(Typography.legalBody, styles.dropdownItem)}
                  onClick={onItemClick}
                >
                  <Link
                    href={`${WWW_URL}/compare/wagmo`}
                    rel='noopener'
                    target='_blank'
                  >
                    Wagmo
                  </Link>
                </li>

                <li
                  className={classNames(Typography.legalBody, styles.dropdownItem)}
                  onClick={onItemClick}
                >
                  <Link
                    href={`${WWW_URL}/compare/pet-assure`}
                    rel='noopener'
                    target='_blank'
                  >
                    Pet Assure
                  </Link>
                </li>

                <li
                  className={classNames(Typography.legalBody, styles.dropdownItem)}
                  onClick={onItemClick}
                >
                  <Link
                    href={`${WWW_URL}/compare/pawp`}
                    rel='noopener'
                    target='_blank'
                  >
                    Pawp
                  </Link>
                </li>

                <li
                  className={classNames(Typography.legalBody, styles.dropdownItem)}
                  onClick={onItemClick}
                >
                  <Link
                    href={`${WWW_URL}/compare/odie`}
                    rel='noopener'
                    target='_blank'
                  >
                    Odie
                  </Link>
                </li>

                <li
                  className={classNames(Typography.legalBody, styles.dropdownItem)}
                  onClick={onItemClick}
                >
                  <Link
                    href={`${WWW_URL}/compare/trusted-pals`}
                    rel='noopener'
                    target='_blank'
                  >
                    Trusted Pals
                  </Link>
                </li>

                <li
                  className={classNames(Typography.legalBody, styles.dropdownItem)}
                  onClick={onItemClick}
                >
                  <Link
                    href={`${WWW_URL}/compare/prudent-pet`}
                    rel='noopener noreferrer'
                    target='_blank'
                  >
                    Prudent Pet
                  </Link>
                </li>

                <li
                  className={classNames(Typography.legalBody, styles.dropdownItem)}
                  onClick={onItemClick}
                >
                  <Link
                    href={`${WWW_URL}/compare/manypets`}
                    rel='noopener noreferrer'
                    target='_blank'
                  >
                    ManyPets
                  </Link>
                </li>
              </ul>
            </li>

            <li className={styles.menuItem}>
              <input
                type='radio'
                name='mobileMenu'
                id='claimsRadio'
                checked={menuState.claims}
                readOnly
              />
              <label htmlFor='claimsRadio' onClick={() => toggleMenuItem('claims')}>
                <button className={ButtonStyles.unset}>Claims</button>

                <span className={styles.expandIcon}>&nbsp;</span>
              </label>

              <ul className={styles.dropdown}>
                <li
                  className={classNames(Typography.legalBody, styles.dropdownItem)}
                  onClick={onItemClick}
                >
                  <Link href={`${WWW_URL}/claims`} rel='noopener' target='_blank'>
                    Claims Overview
                  </Link>
                </li>

                <li
                  className={classNames(Typography.legalBody, styles.dropdownItem)}
                  onClick={onItemClick}
                >
                  <Link
                    href={`${MC_URL}/claim-submission`}
                    rel='noopener noreferrer'
                    target='_blank'
                  >
                    Submit a Claim
                    <img src='/assets/images/iconImages/chevrons-right-white.svg' />
                  </Link>
                </li>
              </ul>
            </li>

            <li className={styles.menuItem}>
              <input
                type='radio'
                name='mobileMenu'
                id='adviceRadio'
                checked={menuState.advice}
                readOnly
              />
              <label htmlFor='adviceRadio' onClick={() => toggleMenuItem('advice')}>
                <button className={ButtonStyles.unset}>Advice</button>

                <span className={styles.expandIcon}>&nbsp;</span>
              </label>

              <ul className={styles.dropdown}>
                <li
                  className={classNames(Typography.legalBody, styles.dropdownItem)}
                  onClick={onItemClick}
                >
                  <Link href={BLOG_URL} target='_blank' rel='noopener noreferrer'>
                    All Advice
                  </Link>
                </li>

                <li
                  className={classNames(Typography.legalBody, styles.dropdownItem)}
                  onClick={onItemClick}
                >
                  <Link
                    href={`${BLOG_URL}/category/pet-parenting-101/`}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Pet Parenting
                  </Link>
                </li>

                <li
                  className={classNames(Typography.legalBody, styles.dropdownItem)}
                  onClick={onItemClick}
                >
                  <Link
                    href={`${BLOG_URL}/category/behavior-and-training/`}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Behavior & Training
                  </Link>
                </li>

                <li
                  className={classNames(Typography.legalBody, styles.dropdownItem)}
                  onClick={onItemClick}
                >
                  <Link
                    href={`${BLOG_URL}/category/health-and-wellness/`}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Health & Wellness
                  </Link>
                </li>

                <li
                  className={classNames(Typography.legalBody, styles.dropdownItem)}
                  onClick={onItemClick}
                >
                  <Link
                    href={`${BLOG_URL}/category/pet-insurance/`}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Pet Insurance 101
                  </Link>
                </li>

                <li
                  className={classNames(Typography.legalBody, styles.dropdownItem)}
                  onClick={onItemClick}
                >
                  <Link
                    href={`${BLOG_URL}/category/trending-now/`}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Trending Now
                  </Link>
                </li>

                <li
                  className={classNames(Typography.legalBody, styles.dropdownItem)}
                  onClick={onItemClick}
                >
                  <Link
                    href={`${WWW_URL}/dog-breeds`}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Dog Breeds
                  </Link>
                </li>

                <li
                  className={classNames(Typography.legalBody, styles.dropdownItem)}
                  onClick={onItemClick}
                >
                  <Link
                    href={`${WWW_URL}/cat-breeds`}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Cat Breeds
                  </Link>
                </li>
              </ul>
            </li>
          </ul>

          <div className={styles.menuDivider} />

          <ul className={styles.links}>
            <li className={styles.menuItem} onClick={onItemClick}>
              <Link
                href={`${WWW_URL}/underwriting-information`}
                rel='noopener'
                target='_blank'
              >
                Underwriting
              </Link>
            </li>
          </ul>

          <GaLink
            href={PHONE_NUMBER_LINK}
            aria-label={PHONE_ARIA_LABEL}
            data-event='Click Blog'
            data-label='Navigation Bar'
            data-category='About Pumpkin'
            style={{ display: 'block', margin: '0 16px 16px' }}
            tabIndex='-1'
          >
            <button className={classNames(ButtonStyles.blue, styles.callButton)}>
              {ARF_MEOW_PHONE_NUMBER}
            </button>
          </GaLink>

          <span tabIndex='0' onFocus={() => firstElem.current.focus()}>
            &nbsp;
          </span>
        </div>
      </div>
    </>
  );
}

export default MobileMenu;
