import { matchPath } from 'react-router';
import { createSelector } from 'reselect';

import { getRouterLocationPathName, Paths } from '../../app-shell';
import { getIdentityId } from '../../identity/selectors';
import getQuotesActivePet from '../../quotes/selectors/get-quotes-active-pet';
import { getPersonalizationQuestionVariant } from './personalization-selectors';

const getShouldFetchPersonalizationQuestion = createSelector(
  [
    getRouterLocationPathName,
    getPersonalizationQuestionVariant,
    getIdentityId,
    getQuotesActivePet,
  ],
  (pathname, questionVariant, identityId, activePet) => {
    return !!(
      matchPath(pathname, {
        path: Paths.PetQuestions,
      }) !== null &&
      questionVariant === null &&
      identityId &&
      activePet &&
      activePet.petBreedSpecies
    );
  }
);

export default getShouldFetchPersonalizationQuestion;
