import { analyticsTrack } from '@pumpkincare/analytics';
import { getQuoteCompletePets, useQuote } from '@pumpkincare/quotes';
import { useBooleanInput } from '@pumpkincare/shared';
import { LegalBody } from '@pumpkincare/shared/ui';

import Cart from './cart';

import styles from './cart-icon.module.css';

function CartIcon() {
  const [isCartModalOpen, toggleIsCartModalOpen] = useBooleanInput(false);

  const { data: quoteData } = useQuote();
  const pets = getQuoteCompletePets(quoteData);

  function handleCartClick() {
    analyticsTrack({
      category: 'Shopping Cart',
      event: 'Open Cart',
      label: 'Open Shopping Cart',
    });

    toggleIsCartModalOpen();
  }

  return (
    <>
      {pets.length > 0 ? (
        <LegalBody isBold className={styles.popoverNum}>
          {pets.length}
        </LegalBody>
      ) : null}
      <img
        className={styles.cartIcon}
        src={`/assets/images/iconImages/icon_cart.svg`}
        alt='Cart Image'
        onClick={handleCartClick}
      />

      {isCartModalOpen ? <Cart onClose={toggleIsCartModalOpen} /> : null}
    </>
  );
}

export default CartIcon;
