// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../../../libs/shared/src/ui/breakpoints.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".quote-flow_quoteFlow_50d005e4 {\n  box-sizing: border-box;\n  min-height: 750px;\n  margin: 0 32px;\n  padding: 40px 0 24px;\n  width: auto;\n}\n\n.quote-flow_wide_ed13532d {\n  margin: 0 32px;\n  width: unset;\n}\n\n@media " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["tablet"] + " {\n  .quote-flow_quoteFlow_50d005e4 {\n    margin: 0 auto;\n    padding: 96px 0 48px;\n    width: 375px;\n  }\n}", "",{"version":3,"sources":["webpack://src/quotes/view/quote-flow/quote-flow.module.css"],"names":[],"mappings":"AAEA;EACE,sBAAsB;EACtB,iBAAiB;EACjB,cAAc;EACd,oBAAoB;EACpB,WAAW;AACb;;AAEA;EACE,cAAc;EACd,YAAY;AACd;;AAEA;EACE;IACE,cAAc;IACd,oBAAoB;IACpB,YAAY;EACd;AACF","sourcesContent":["@value tablet from '~@pumpkincare/shared/ui/breakpoints';\n\n.quoteFlow {\n  box-sizing: border-box;\n  min-height: 750px;\n  margin: 0 32px;\n  padding: 40px 0 24px;\n  width: auto;\n}\n\n.wide {\n  margin: 0 32px;\n  width: unset;\n}\n\n@media tablet {\n  .quoteFlow {\n    margin: 0 auto;\n    padding: 96px 0 48px;\n    width: 375px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tablet": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["tablet"] + "",
	"quoteFlow": "quote-flow_quoteFlow_50d005e4",
	"wide": "quote-flow_wide_ed13532d"
};
export default ___CSS_LOADER_EXPORT___;
