// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../../../../libs/shared/src/ui/breakpoints.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".coverage-details_coverageTitle_270255a3 {\n  text-align: center;\n  margin: 60px auto 8px;\n  width: 100%;\n  max-width: 1440px;\n}\n\n.coverage-details_detailsTitle_003f2107 {\n  text-align: center;\n  max-width: 500px;\n  margin-top: 80px;\n  margin-bottom: 40px;\n}\n\n@media " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["mobile"] + " {\n  .coverage-details_coverageTitle_270255a3 {\n    margin-top: 32px;\n    margin-bottom: 40px;\n  }\n\n  .coverage-details_detailsTitle_003f2107 {\n    margin-top: 20px;\n  }\n}\n", "",{"version":3,"sources":["webpack://src/plans/view/ecomm-view-v2/coverage-details/coverage-details.module.css"],"names":[],"mappings":"AAEA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE;IACE,gBAAgB;IAChB,mBAAmB;EACrB;;EAEA;IACE,gBAAgB;EAClB;AACF","sourcesContent":["@value mobile from '~@pumpkincare/shared/ui/breakpoints';\n\n.coverageTitle {\n  text-align: center;\n  margin: 60px auto 8px;\n  width: 100%;\n  max-width: 1440px;\n}\n\n.detailsTitle {\n  text-align: center;\n  max-width: 500px;\n  margin-top: 80px;\n  margin-bottom: 40px;\n}\n\n@media mobile {\n  .coverageTitle {\n    margin-top: 32px;\n    margin-bottom: 40px;\n  }\n\n  .detailsTitle {\n    margin-top: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mobile": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["mobile"] + "",
	"coverageTitle": "coverage-details_coverageTitle_270255a3",
	"detailsTitle": "coverage-details_detailsTitle_003f2107"
};
export default ___CSS_LOADER_EXPORT___;
