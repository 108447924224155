import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useFlags } from 'launchdarkly-react-client-sdk';
import queryString from 'query-string';
import Store from 'store';

import { useMarketingAttribution } from '@pumpkincare/marketing';
import { CjPixel, TunePixel } from '@pumpkincare/marketing/ui';
import { getQuotePets, useQuote } from '@pumpkincare/quotes';
import {
  COMPLETE_QUOTE_ID_LS_KEY,
  FETCHING,
  GENERIC_TEMP_ERROR,
  GRAND_TOTAL_LOCAL_STORAGE_KEY,
  useBanners,
} from '@pumpkincare/shared';
import { Body1, LegalBody, LoaderButton, SocialMedia } from '@pumpkincare/shared/ui';
import { getUserId, useUssr } from '@pumpkincare/user';
import {
  createVetFormPayload,
  WhatVetClinic,
  WhatVetClinicLegacy,
} from '@pumpkincare/vets';

import { getAppFeatureFlags } from '../../../app-shell';
import { Hdyhau } from '../../../hdyhau';
import {
  CHECKOUT_CTA,
  CHECKOUT_HEADLINE,
  CHECKOUT_NOTE,
  formatVetFormHeader,
  HELP_TITLE,
  HelpText,
  PETS_IMAGE,
  PETS_IMAGE_ALT,
  PREVENTIVE_TEXT,
  PREVENTIVE_TITLE,
} from '../../constants/checkout-vet-form';
import { getVetForm } from '../../selectors/vet-selectors';
import submitCheckoutVetForm from '../../thunks/submit-checkout-vet-form';
import Dialog from '../dialog';
import HasBeenToTheVet from '../has-been-to-the-vet';

import styles from './checkout-vet-form.module.css';

// TODO: Wrapper for CheckoutVetForm and HDYHAU - too much wasted logic here just to render HDYHAU
function CheckoutVetForm() {
  const { allowMultiVetsPerPet } = useFlags();

  const { addBanner, removeAllBanners } = useBanners();

  const { search } = useLocation();
  const { token } = queryString.parse(search);
  const isNewCustomer = !!token;

  const dispatch = useDispatch();
  const vetFormState = useSelector(getVetForm);
  const featureFlags = useSelector(getAppFeatureFlags);

  const [petsAnswers, setPetsAnswers] = useState([]);

  const grandTotal = Store.get(GRAND_TOTAL_LOCAL_STORAGE_KEY);
  const quoteId = Store.get(COMPLETE_QUOTE_ID_LS_KEY);

  const { data: userId } = useUssr(getUserId);
  const { data: quotePetsData, isFetched: isQuoteFetched } = useQuote({
    quoteId,
    select: getQuotePets,
  });

  const petNames = quotePetsData.map(pet => pet.petName);

  // this will account for leaving the page and returning and for refreshing the page
  const isFirstVisitWithAttribution = useMarketingAttribution(
    quoteId,
    'Land on Checkout Success',
    'isGoingToCheckoutSuccess'
  );

  useEffect(() => {
    setPetsAnswers(
      quotePetsData.map((pet, index) => {
        return {
          index,
          id: pet.id,
          name: pet.petName,
          vet: null,
          hasBeenToTheVet: null,
        };
      })
    );
  }, [quotePetsData]);

  function isPetValid(pet) {
    return pet.vet && pet.hasBeenToTheVet !== null;
  }

  function createFirstClinicQuestion(pet) {
    return allowMultiVetsPerPet ? (
      <WhatVetClinic
        key={`vetClinic-${pet.id}`}
        petName={pet.petName}
        onChange={data => handleOnVetClinicChange(pet.id, data)}
        className={styles.vetQuestion}
      />
    ) : (
      <WhatVetClinicLegacy
        key={`vetClinic-${pet.id}`}
        pet={pet}
        onChange={data => handleOnVetClinicChange(pet.id, data)}
        className={styles.vetQuestion}
      />
    );
  }

  function createHasBeenToTheVetQuestion(pet) {
    return (
      <>
        <HasBeenToTheVet
          pet={pet}
          value={pet.hasBeenToTheVet}
          onOptionChange={newOption => handleHasBeenToTheVet(pet.id, newOption)}
          className={styles.vetQuestion}
        />
        {pet.hasBeenToTheVet ? (
          <div className={styles.bubbleContainer}>
            <Dialog
              header={PREVENTIVE_TITLE}
              copy={PREVENTIVE_TEXT}
              logoSide={'right'}
            />
          </div>
        ) : null}
      </>
    );
  }

  function handleOnVetClinicChange(petId, data) {
    const newVet = data.valid
      ? { id: data.existing?.id, custom: data.custom }
      : null;

    setPetsAnswers(state => [
      {
        ...state[0],
        vet: newVet,
      },
    ]);
  }

  function handleHasBeenToTheVet(petId, answer) {
    setPetsAnswers(state => [
      {
        ...state[0],
        hasBeenToTheVet: answer,
      },
    ]);
  }

  function handleSubmit() {
    const payload = createVetFormPayload(petsAnswers);

    removeAllBanners();

    dispatch(submitCheckoutVetForm({ ...payload, userId })).catch(() => {
      addBanner(GENERIC_TEMP_ERROR);
    });
  }

  if (isNewCustomer) {
    return <Hdyhau />;
  }

  return isQuoteFetched ? (
    <div className={styles.root}>
      <div className={styles.content}>
        <h1 className={styles.headline}>{CHECKOUT_HEADLINE}</h1>

        <div className={styles.form}>
          <h4>{formatVetFormHeader(petNames)}</h4>

          {petsAnswers.length > 0 ? createFirstClinicQuestion(petsAnswers[0]) : null}

          {petsAnswers.length > 0
            ? createHasBeenToTheVetQuestion(petsAnswers[0])
            : null}

          {petsAnswers.length > 0 ? (
            <LoaderButton
              color='primary'
              onClick={handleSubmit}
              classes={{ root: styles.ctaButton }}
              disabled={!isPetValid(petsAnswers[0])}
              isLoading={vetFormState.status === FETCHING}
              data-testid='submit-button'
            >
              {CHECKOUT_CTA}
            </LoaderButton>
          ) : null}

          {allowMultiVetsPerPet === false ? (
            <LegalBody>{CHECKOUT_NOTE}</LegalBody>
          ) : null}
        </div>
      </div>

      <div className={styles.info}>
        <img src={PETS_IMAGE} alt={PETS_IMAGE_ALT} className={styles.petsImage} />
        <h4 className={styles.helpTitle}>{HELP_TITLE}</h4>
        <Body1 className={styles.helpText}>
          <HelpText />
        </Body1>
        <SocialMedia
          anchorProps={{
            target: '_blank',
            rel: 'noopener noreferrer',
            className: styles.socialLinksItem,
          }}
          imageProps={{ className: styles.socialLinksImage }}
        />
      </div>

      {isFirstVisitWithAttribution ? (
        <>
          <CjPixel page='Purchase' quoteId={quoteId} checkoutTotal={grandTotal} />
          <TunePixel page='Sale' quoteId={quoteId} checkoutTotal={grandTotal} />
        </>
      ) : null}
    </div>
  ) : null;
}

export default CheckoutVetForm;
