import { DOG } from '@pumpkincare/shared';
import { Body2 } from '@pumpkincare/shared/ui';

import CoverageItem from '../coverage-item';

import styles from './coverage-section.module.css';

function CoverageSection({ activePet }) {
  const { petBreedSpecies } = activePet;
  const isDog = petBreedSpecies === DOG;

  const skinConditionCopy = isDog
    ? 'Eye, Ear & Skin Infections'
    : 'Skin & Eye Conditions';
  const digestiveConditionCopy = isDog ? (
    <>
      Digestive
      <br />
      Illnesses
    </>
  ) : (
    'Diabetes'
  );
  const cancerConditionCopy = isDog ? 'Cancer & Growths' : 'Cancer';
  const ingestionConditionCopy = isDog ? 'Swallowed Objects' : 'Toxic Ingestion';
  const utiConditionCopy = isDog ? 'Urinary Infections' : 'UTIs & Kidney Disease';
  const utiConditionImg = isDog
    ? '/assets/images/iconImages/uti.svg'
    : '/assets/images/iconImages/kidneys.svg';
  const differentConditionOneCopy = (
    <>
      Broken
      <br />
      Bones
    </>
  );
  const differentConditionOneImg = '/assets/images/iconImages/broken-bone.svg';
  const differentConditionTwoCopy = isDog ? (
    <>
      Hip
      <br />
      Dysplasia
    </>
  ) : (
    'Respiratory Infections'
  );
  const differentConditionTwoImg = isDog
    ? '/assets/images/iconImages/hip-dysplasia.svg'
    : '/assets/images/iconImages/lungs.svg';
  const behavioralConditionImg = isDog
    ? '/assets/images/iconImages/sneakers.png'
    : '/assets/images/iconImages/scratching-paw.png';

  return (
    <>
      <div className={styles.conditionsContainer}>
        <CoverageItem imgSrc='/assets/images/iconImages/ees-conditions.svg'>
          {skinConditionCopy}
        </CoverageItem>

        <CoverageItem imgSrc='/assets/images/iconImages/digestive-issues.svg'>
          {digestiveConditionCopy}
        </CoverageItem>

        <CoverageItem imgSrc={utiConditionImg}>{utiConditionCopy}</CoverageItem>

        <CoverageItem imgSrc={differentConditionTwoImg}>
          {differentConditionTwoCopy}
        </CoverageItem>

        <CoverageItem imgSrc='/assets/images/iconImages/growths.svg'>
          {cancerConditionCopy}
        </CoverageItem>

        <CoverageItem imgSrc={differentConditionOneImg}>
          {differentConditionOneCopy}
        </CoverageItem>

        <CoverageItem imgSrc='/assets/images/iconImages/swallowed-objects.svg'>
          {ingestionConditionCopy}
        </CoverageItem>

        <CoverageItem imgSrc='/assets/images/iconImages/bite-wounds.svg'>
          Bite
          <br />
          Wounds
        </CoverageItem>
      </div>

      <Body2 className={styles.redTitle}>
        They cover advanced veterinary care for costly eligible conditions.
      </Body2>

      <div className={styles.conditionsContainer}>
        <CoverageItem imgSrc='/assets/images/iconImages/beaker.svg'>
          Radiology & Diagnostic Tests
        </CoverageItem>

        <CoverageItem imgSrc='/assets/images/iconImages/meds.svg'>
          Prescription Meds
        </CoverageItem>

        <CoverageItem imgSrc='/assets/images/iconImages/ambulance.svg'>
          Emergencies &amp; Hospitalization
        </CoverageItem>

        <CoverageItem imgSrc='/assets/images/iconImages/apothecary.svg'>
          Alternative Therapies
        </CoverageItem>

        <CoverageItem imgSrc='/assets/images/iconImages/surgical-equipment.svg'>
          Surgery & Specialized Care
        </CoverageItem>

        <CoverageItem imgSrc='/assets/images/iconImages/iv.svg'>
          Cancer Treatments
        </CoverageItem>
      </div>

      <Body2 className={styles.redTitle}>
        They even cover key things some other insurance plans don’t.
      </Body2>

      <div className={styles.conditionsContainer}>
        <CoverageItem imgSrc='/assets/images/iconImages/paw-plan.svg'>
          Hereditary Conditions
        </CoverageItem>

        <CoverageItem imgSrc='/assets/images/iconImages/tooth.svg'>
          Dental &amp; Gum Disease
        </CoverageItem>

        <CoverageItem imgSrc={behavioralConditionImg}>
          Behavioral Issues
        </CoverageItem>

        <CoverageItem imgSrc='/assets/images/iconImages/stethoscope.svg'>
          Sick Visit Exam Fees
        </CoverageItem>

        <CoverageItem imgSrc='/assets/images/iconImages/microchip.svg'>
          Microchip Implantation
        </CoverageItem>

        <CoverageItem
          imgSrc='/assets/images/iconImages/food-bowl.svg'
          className={styles.eligibleCondition}
          role=''
        >
          Prescription Food for Eligible Conditions
          <a
            className={styles.link}
            href={'#food-coverage'}
            aria-label='link to legal copy'
          >
            *
          </a>
        </CoverageItem>
      </div>
    </>
  );
}

export default CoverageSection;
