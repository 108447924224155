import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { Body1, InputStyles, Typography } from '@pumpkincare/shared/ui';

import styles from './checkout-form.module.css';

function AddressInput({ address, isShipping = false, onChange, email }) {
  const { allowUpdateNameOnCheckoutPage } = useFlags();

  const textInputClassName = classNames(
    styles.textInput,
    Typography.body2,
    styles.border,
    InputStyles.text
  );

  const textInputLockedClassName = classNames(
    styles.textInput,
    Typography.body2,
    InputStyles.text,
    styles.borderless
  );

  function handleFieldChangeFactory(field) {
    return e => {
      return onChange({ field, value: e.target.value.replace(/\s+/g, ' ').trim() });
    };
  }

  return (
    <>
      {isShipping ? (
        allowUpdateNameOnCheckoutPage ? (
          <>
            <Body1 className={styles.inputLabel}>{address.firstName.label}</Body1>

            <input
              className={classNames(
                textInputClassName,
                address.firstName.error ? styles.error : null
              )}
              // https://stackoverflow.com/questions/64548202/react-input-change-not-firing-when-select-all-to-delete
              // https://petlife.atlassian.net/browse/PUNKS-888
              // defaultValue={address.firstName.value}
              value={address.firstName.value}
              onChange={handleFieldChangeFactory(address.firstName.id)}
              aria-label={address.firstName.label}
              id={'firstName'}
              name={'firstName'}
              autoComplete={'given-name'}
            />

            <Body1 className={styles.inputLabel}>{address.lastName.label}</Body1>

            <input
              className={classNames(
                textInputClassName,
                address.lastName.error ? styles.error : null
              )}
              // https://stackoverflow.com/questions/64548202/react-input-change-not-firing-when-select-all-to-delete
              // https://petlife.atlassian.net/browse/PUNKS-888
              // defaultValue={address.lastName.value}
              value={address.lastName.value}
              onChange={handleFieldChangeFactory(address.lastName.id)}
              aria-label={address.lastName.label}
              id={'lastName'}
              name={'lastName'}
              autoComplete={'family-name'}
            />
          </>
        ) : (
          <div>
            <Body1 className={styles.inputLabel}>Name on Plan</Body1>

            <input
              className={isShipping ? textInputLockedClassName : textInputClassName}
              data-testid={'name-input'}
              defaultValue={address.firstName.value + ' ' + address.lastName.value}
              disabled
              placeholder={'Name'}
              aria-label={'Name'}
              id={'name'}
              name={'name'}
              autoComplete={'name'}
            />

            <img
              alt=''
              src={'/assets/images/iconImages/padlock.png'}
              style={{
                position: 'absolute',
                top: '10px',
                left: '95%',
                right: '10px',
              }}
            />
          </div>
        )
      ) : null}

      <Body1 className={styles.inputLabel}>Address</Body1>

      <input
        className={classNames(
          textInputClassName,
          address.address1.error ? styles.error : null
        )}
        data-testid={'address1-input'}
        defaultValue={address.address1.value}
        onChange={handleFieldChangeFactory(address.address1.id)}
        aria-label={'Address'}
        id={'address1'}
        name={'address1'}
        autoComplete={'address-line1'}
      />

      <Body1 className={styles.inputLabel}>Address Line 2 (Optional)</Body1>

      <input
        className={textInputClassName}
        data-testid={'address2-input'}
        defaultValue={address.address2.value}
        onChange={handleFieldChangeFactory(address.address2.id)}
        aria-label={'Address line 2'}
        id={'address2'}
        name={'address2'}
        autoComplete={'address-line2'}
      />

      <Body1 className={styles.inputLabel}>City</Body1>

      <input
        className={classNames(
          textInputClassName,
          address.city.error ? styles.error : null
        )}
        data-testid={'city-input'}
        defaultValue={address.city.value}
        onChange={handleFieldChangeFactory(address.city.id)}
        aria-label={'City'}
        id={'city'}
        name={'city'}
        autoComplete={'address-level2'}
      />

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <div className={styles.divColumnLeft}>
          <Body1 className={styles.inputLabel}>State</Body1>

          <input
            className={
              isShipping
                ? textInputLockedClassName
                : classNames(
                    textInputClassName,
                    address.state.error ? styles.error : null
                  )
            }
            data-testid={'state-input'}
            defaultValue={isShipping ? address.state.value : null}
            disabled={isShipping}
            onChange={handleFieldChangeFactory(address.state.id)}
            aria-label={'State'}
            id={'state'}
            name={'state'}
            autoComplete={'address-level1'}
          />

          {isShipping ? (
            <img
              alt=''
              src={'/assets/images/iconImages/padlock.png'}
              style={{
                position: 'absolute',
                top: '10px',
                left: '90%',
                right: '10px',
              }}
            />
          ) : null}
        </div>

        <div className={styles.divColumnRight}>
          <Body1 className={styles.inputLabel}>Zip Code</Body1>

          <input
            className={
              isShipping
                ? textInputLockedClassName
                : classNames(
                    textInputClassName,
                    address.zipcode.error ? styles.error : null
                  )
            }
            data-testid={'zipCode-input'}
            defaultValue={isShipping ? address.zipcode.value : null}
            disabled={isShipping}
            onChange={handleFieldChangeFactory(address.zipcode.id)}
            aria-label={'Zip'}
            id={'zipcode'}
            name={'zipcode'}
            autoComplete={'postal-code'}
          />

          {isShipping ? (
            <img
              alt=''
              src={'/assets/images/iconImages/padlock.png'}
              style={{
                position: 'absolute',
                top: '10px',
                left: '90%',
                right: '10px',
              }}
            />
          ) : null}
        </div>
      </div>

      {isShipping ? (
        <>
          <Body1 className={styles.inputLabel}>Email Address</Body1>

          <div style={{ position: 'relative' }}>
            <input
              className={textInputLockedClassName}
              data-testid={'email-input'}
              defaultValue={email}
              disabled={true}
              aria-label={'Email'}
            />

            <img
              alt=''
              src={'/assets/images/iconImages/padlock.png'}
              style={{
                position: 'absolute',
                top: '10px',
                left: '95%',
                right: '10px',
              }}
            />
          </div>
        </>
      ) : null}
    </>
  );
}

export default AddressInput;
