import { LegalBody } from '@pumpkincare/shared/ui';

import styles from './coverage-item.module.css';

function CoverageItem({ imgSrc, children, className, role = 'text' }) {
  return (
    <div className={`${styles.coverageItem} ${className || ''}`}>
      <div className={styles.itemImageContainer}>
        <img className={styles.itemImage} src={imgSrc} aria-hidden='true' alt='' />
      </div>

      <LegalBody>
        <span role={role}>{children}</span>
      </LegalBody>
    </div>
  );
}

export default CoverageItem;
