import { getIsLoggedIn } from '@pumpkincare/shared';

import { getIdentityId } from '../../identity/selectors';
import { getQuotesTrackingId } from '../selectors/quotes-selectors';
import { setQuoteOnInitialFetch } from '../state/quotes-ducks';

export default function fetchQuoteAndGetId() {
  return (dispatch, getState, { quotesService }) => {
    const state = getState();
    const trackingId = getQuotesTrackingId(state);
    const identityId = getIdentityId(state);
    const isLoggedIn = getIsLoggedIn();

    return quotesService
      .fetchQuote(trackingId, identityId, isLoggedIn)
      .then(quote => {
        const payload = isLoggedIn
          ? {
              id: quote.id,
              email: quote.email,
              lastName: quote.last_name,
              firstName: quote.first_name,
              policy_state: quote.policy_state,
              policy_zipcode: quote.policy_zipcode,
            }
          : { id: quote.id };

        dispatch(setQuoteOnInitialFetch(payload));

        return quote.id;
      });
  };
}
