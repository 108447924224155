/* eslint-disable react/jsx-no-target-blank */

import { GAPlanSelectionClickSeeSamplePolicy } from '@pumpkincare/analytics';
import { WWW_URL } from '@pumpkincare/config';
import { getPlanVersion } from '@pumpkincare/plans';
import { formatCustomerAgreementParams } from '@pumpkincare/shared';
import { Body2 } from '@pumpkincare/shared/ui';

import styles from './not-covered.module.css';

function NotCovered({ plan }) {
  const urlSearchString = formatCustomerAgreementParams(getPlanVersion(plan));

  return (
    <div className={styles.outerContainer}>
      <div className={styles.contentContainer}>
        <div className={styles.textContainer}>
          <h3 className={styles.title}>
            Understanding what’s not covered is super important, too.
          </h3>

          <Body2>
            Pumpkin insurance plans can’t cover conditions that were treated or
            showed symptoms before coverage starts or during the waiting period – at
            least at first. If one of these “pre-existing conditions” is curable,
            cured, and has been free of symptoms and treatment for 180 days, we’ll
            cover it if it occurs again in the future, with the exception of knee &
            hind leg ligament conditions.
          </Body2>

          <Body2 className={styles.notCovered}>
            <b>Other stuff Pumpkin insurance plans don’t cover:</b>
            <br />
            <ul className={styles.notCoveredUl}>
              <li className={styles.notCoveredLi}>Breeding costs</li>
              <li className={styles.notCoveredLi}>Spaying / Neutering</li>
              <li className={styles.notCoveredLi}>Cosmetic & Elective Procedures</li>
              <li className={styles.notCoveredLi}>
                Non-illness Related Dental Cleanings
              </li>
            </ul>
            <br />
            For all exclusions, call us or check out a{' '}
            <a
              href={`${WWW_URL}/sample-plan${urlSearchString}`}
              target='_blank'
              rel='noopener'
              style={{ fontSize: 'inherit' }}
              onClick={() => GAPlanSelectionClickSeeSamplePolicy()}
            >
              sample policy
            </a>
          </Body2>
        </div>

        <div className={styles.imgContainer}>
          <img
            src={'/assets/images/cartoons/coverage-cat.png'}
            className={styles.img}
            alt=''
          />
        </div>
      </div>
    </div>
  );
}

export default NotCovered;
