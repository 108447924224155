import { useFlags } from 'launchdarkly-react-client-sdk';

import {
  getQuoteCompletePets,
  getQuotePets,
  getQuotePolicyState,
  useQuote,
} from '@pumpkincare/quotes';
import {
  formatISODate,
  getEffectiveDate,
  getIllnessEffectiveDate,
  getIsLoggedIn,
} from '@pumpkincare/shared';
import { Body2, LegalBody, Typography } from '@pumpkincare/shared/ui';

import Summary from './summary';

import styles from './plan-review.module.css';

function PlanReview() {
  const { punks699Maine45Updates } = useFlags();

  const { data: quoteData } = useQuote();
  const pets = getIsLoggedIn()
    ? getQuotePets(quoteData)
    : getQuoteCompletePets(quoteData);
  const policyState = getQuotePolicyState(quoteData);

  const isPrevSelected = pets.some(pet => pet.preventativeSelected);

  return (
    <>
      <Summary />

      <div>
        <h4>Important Dates</h4>

        <div>
          <div>
            <Body2 isBold className={styles.dateSpacing}>
              {formatISODate(new Date(), { format: 'MMMM Do, YYYY' })}
            </Body2>

            <div className={styles.verticalLine}>
              <LegalBody className={styles.importantDatesFont}>
                <span className={Typography.bold}>Enrollment Date</span>
                <br />
                Yay! Today you decided to enroll in a Pumpkin plan and join the
                Pumpkin Pack.
              </LegalBody>
            </div>
          </div>

          <div>
            <Body2 isBold className={styles.dateSpacing}>
              {getEffectiveDate()}
            </Body2>

            <div className={styles.verticalLine}>
              {!punks699Maine45Updates && policyState === 'ME' ? (
                <LegalBody className={styles.importantDatesFont}>
                  <span className={Typography.bold}>
                    Pet Insurance Policy Effective Date
                  </span>
                  <br />
                  This is the day after you enroll your pet in a Pumpkin Pet
                  Insurance plan, and this is the day your pet’s coverage will start
                </LegalBody>
              ) : (
                <LegalBody className={styles.importantDatesFont}>
                  <span className={Typography.bold}>
                    Insurance Policy Effective Date
                  </span>
                  <br />
                  The day after you enroll in pet insurance, and the day your waiting
                  period starts.
                </LegalBody>
              )}
            </div>

            {isPrevSelected ? (
              <div className={styles.verticalLine}>
                <LegalBody className={styles.importantDatesFont}>
                  <br />
                  <span className={Typography.bold}>
                    Preventive Essentials Start Date
                  </span>
                  <br />
                  The day after you enroll in Preventive Essentials and when you can
                  use those benefits.
                </LegalBody>
              </div>
            ) : null}
          </div>

          {!punks699Maine45Updates && policyState === 'ME' ? null : (
            <div>
              <Body2 isBold className={styles.dateSpacing}>
                {getIllnessEffectiveDate()}
              </Body2>

              <div className={styles.verticalLine}>
                <LegalBody className={styles.importantDatesFont}>
                  <span className={Typography.bold}>
                    Insurance Coverage Start Date
                  </span>
                  <br />
                  The day your pet insurance coverage kicks in after the 14-day
                  waiting period.
                </LegalBody>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default PlanReview;
