// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".yes-no_group_fc825140 {\n    width: 100%;\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n    justify-content: center;\n}\n\n.yes-no_group_fc825140 .yes-no_groupItem_c18e1713 {\n    width: 128px;\n    height: 128px;\n    margin: 4px;\n\n    box-sizing: border-box;\n}", "",{"version":3,"sources":["webpack://src/personalization/view/yes-no/yes-no.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,uBAAuB;AAC3B;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,WAAW;;IAEX,sBAAsB;AAC1B","sourcesContent":[".group {\n    width: 100%;\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n    justify-content: center;\n}\n\n.group .groupItem {\n    width: 128px;\n    height: 128px;\n    margin: 4px;\n\n    box-sizing: border-box;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"group": "yes-no_group_fc825140",
	"groupItem": "yes-no_groupItem_c18e1713"
};
export default ___CSS_LOADER_EXPORT___;
