import { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import { Body1, LegalBody } from '@pumpkincare/shared/ui';

import styles from './dialog.module.css';

function Dialog({ header, copy, logoSide }) {
  const [isEntered, enter] = useState(false);

  useEffect(() => {
    enter(true);
    return function cleanup() {
      enter(false);
    };
  }, []);

  return (
    <CSSTransition
      classNames={{
        enter: styles['transition-enter'],
        enterActive: styles['transition-enter-active'],
        enterDone: styles['transition-enter-done'],
        exit: styles['transition-exit'],
        exitActive: styles['transition-exit-active'],
      }}
      timeout={1000}
      in={isEntered}
    >
      <div className={styles.root}>
        <div
          className={styles.mainBubble}
          style={{
            borderRadius: '0px',
          }}
        >
          <LegalBody className={styles.header}>
            {header}
            <br />
            {copy}
          </LegalBody>
          <img
            alt='Favicon_Red.png'
            style={{ [logoSide]: '-40px' }}
            src='/assets/images/Favicon_Red.png'
          />
        </div>
      </div>
    </CSSTransition>
  );
}

export default Dialog;
