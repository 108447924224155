import classNames from 'classnames';

import {
  ARF_MEOW_PHONE_NUMBER,
  CONTACT_EMAIL,
  CONTACT_EMAIL_LINK,
  PHONE_NUMBER_LINK,
} from '@pumpkincare/shared';
import { LegalBody, Typography } from '@pumpkincare/shared/ui';

import styles from './need-some-help.module.css';

function NeedSomeHelp() {
  return (
    <div className={styles.container}>
      <div className={styles.imageContainer}></div>
      <img
        className={styles.topIcon}
        src='/assets/images/multipet_pawcierge.svg'
        alt=''
      />
      <div className={styles.content}>
        <div className={styles.bodyText}>
          <h3 className={classNames(styles.title, Typography.h3)}>
            Need some help?
          </h3>
          <h4 className={Typography.h4}>
            Our pet-loving care team is just a paw away.
          </h4>
        </div>

        <div className={styles.contactArea}>
          <div className={styles.iconsWrapper}>
            <div className={styles.contactItem}>
              <div className={styles.contactIconWrapper}>
                <img
                  className={styles.contactIcon}
                  src='/assets/images/contact_phone.svg'
                  alt=''
                />
              </div>

              <div>
                <LegalBody className={styles.contactInfo}>
                  <a href={PHONE_NUMBER_LINK} className={styles.linkText}>
                    Call {`${ARF_MEOW_PHONE_NUMBER}`}
                  </a>
                  <span> Mon-Fri 8am - 8pm EST</span>
                  <span> Sat 9am - 5pm EST</span>
                </LegalBody>
              </div>
            </div>

            <div className={styles.contactItem}>
              <div className={styles.contactIconWrapper}>
                <img
                  className={styles.contactIcon}
                  src='/assets/images/contact_mail.svg'
                  alt=''
                />
              </div>
              <div>
                <LegalBody className={styles.contactInfo}>
                  <a href={CONTACT_EMAIL_LINK} className={styles.linkText}>
                    Email {CONTACT_EMAIL}
                  </a>
                  We’ll respond in a day or less
                </LegalBody>
              </div>
            </div>

            <div className={styles.contactItem}>
              <div className={styles.contactIconWrapper}>
                <img
                  className={styles.contactIcon}
                  src='/assets/images/contact_hello.svg'
                  alt=''
                />
              </div>
              <div>
                <LegalBody className={styles.contactInfo}>
                  <a
                    className={styles.linkText}
                    role='button'
                    onClick={() => {
                      window['GladlyHelpApp'].show();
                    }}
                  >
                    Chat with us
                    <img src='/assets/images/iconImages/chevrons-right.svg' />
                  </a>
                  Mon-Sat 9am - 5pm EST
                </LegalBody>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NeedSomeHelp;
