// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cart_cartIcon_bc1363af {\n  height: 40px;\n  margin-top: 5px;\n  cursor: pointer;\n}\n\n.cart_popoverNum_70bfaa94 {\n  position: absolute;\n  background-color: var(--cheshirePink);\n  border-radius: 50%;\n  padding: 1px;\n  min-height: 16px;\n  min-width: 16px;\n  text-align: center;\n}\n", "",{"version":3,"sources":["webpack://src/quotes/view/cart/cart-icon.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,qCAAqC;EACrC,kBAAkB;EAClB,YAAY;EACZ,gBAAgB;EAChB,eAAe;EACf,kBAAkB;AACpB","sourcesContent":[".cartIcon {\n  height: 40px;\n  margin-top: 5px;\n  cursor: pointer;\n}\n\n.popoverNum {\n  position: absolute;\n  background-color: var(--cheshirePink);\n  border-radius: 50%;\n  padding: 1px;\n  min-height: 16px;\n  min-width: 16px;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cartIcon": "cart_cartIcon_bc1363af",
	"popoverNum": "cart_popoverNum_70bfaa94"
};
export default ___CSS_LOADER_EXPORT___;
