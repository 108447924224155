import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

import { useQuote } from '@pumpkincare/quotes';
import { Body1 } from '@pumpkincare/shared/ui';

import { getQuoteFlowNextStep, getQuotesActiveId } from '../../selectors';
import QuoteFlowHeader from '../quote-flow-header';
import { NextButton, QuoteFlowComponentContent } from '../shared';

export default function EmbarkHelloLegacy() {
  const dispatch = useDispatch();
  const activeId = useSelector(getQuotesActiveId);
  const { data: quoteData, isLoading } = useQuote();
  const { firstName, pets } = quoteData;
  const activePetName = pets.find(pet => pet.id === activeId)?.petName;
  const nextRoute = useSelector(getQuoteFlowNextStep);

  function handleNext() {
    dispatch(push(nextRoute));
  }

  const greeting = firstName ? `Hi, ${firstName}!` : `Hi!`;
  const displayName = activePetName ? `${activePetName}’s` : `your pet’s`;

  return (
    <>
      <QuoteFlowHeader>{greeting}</QuoteFlowHeader>

      <QuoteFlowComponentContent>
        <img
          src={'/assets/images/cartoons/01_Illustrations_Dog_Embark.png'}
          role={'presentation'}
        />
        <Body1 style={{ textAlign: 'center', marginTop: '32px' }}>
          Now that you know all about {displayName} genetic background, it’s time to
          build a pet insurance plan that can help you provide the best care pawsible
          for your pup in the future!
        </Body1>

        <NextButton onClick={handleNext} disabled={isLoading} />
      </QuoteFlowComponentContent>
    </>
  );
}
