import { push } from 'connected-react-router';

// import { MemberRoutePaths } from '../../../../app-shell';
import submitVetForm from './submit-vet-form';

export default function submitCheckoutVetForm(data) {
  return dispatch => {
    /*
    data can be in one of four states:
    (1) vet created by user via custom form, with no id but fields like address
    (2) existing vet returned by BE, with an id
    (3) custom form is hidden, { vet_name: NO_RESULTS_TITLE_HIDE_FORM }

    if custom form is hidden, skip submit and just redirect
     */

    if (data.answers[0].vet_id || data.customVets[0].vet.address) {
      return dispatch(submitVetForm(data));
    } else {
      // return dispatch(push(MemberRoutePaths.Profile));
    }
  };
}
