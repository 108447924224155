import Store from 'store';

import { analyticsTrack, questionSetExperiment } from '@pumpkincare/analytics';
import { IDENTITY_LOCAL_STORAGE_KEY } from '@pumpkincare/shared';

import { getQuotesTrackingId } from '../../quotes/selectors';
import { setUpdatedIdentity } from '../state/identity-ducks';

export default function createIdentity() {
  return (dispatch, getState, { identityService }) => {
    const { quotes } = getState();
    const trackingId = getQuotesTrackingId(getState());
    if (quotes.identityId && trackingId) {
      identityService.patchIdentity({
        tracking_id: trackingId,
        id: quotes.identityId,
      });

      dispatch(
        setUpdatedIdentity({ id: quotes.identityId, trackingId: trackingId })
      );

      Store.set(IDENTITY_LOCAL_STORAGE_KEY, quotes.identityId);
    } else {
      return (
        trackingId
          ? identityService.putIdentity(trackingId)
          : identityService.postIdentity()
      )
        .then(identity => {
          dispatch(setUpdatedIdentity(identity));
          identityService.getIdentityQuestionSet(identity.id).then(qs => {
            if (qs && Object.keys(qs).length > 0) {
              analyticsTrack(questionSetExperiment(qs.id, qs.description));
            }
          });
          Store.set(IDENTITY_LOCAL_STORAGE_KEY, identity.id);
        })
        .catch(err => {
          throw err;
        });
    }
  };
}
