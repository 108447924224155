import { useRef } from 'react';
import classNames from 'classnames';

import {
  Body1,
  Screenreader,
  Superscript1,
  Typography,
} from '@pumpkincare/shared/ui';

import styles from './compare-table.module.css';

const PUMPKIN = 'pumpkin';

const pumpkinCheck = '/assets/images/iconImages/pumpkin-check.svg';
const competitorCheck = '/assets/images/iconImages/check-mark.svg';
const competitorUncheck = '/assets/images/iconImages/x-mark.svg';

function CompareTable({ onClose }) {
  const competitorsTable = useRef();
  const bodyCell = useRef();

  function scroll(forward = true) {
    if (forward) {
      competitorsTable.current.scrollLeft += bodyCell.current.clientWidth;
    } else if (!forward) {
      competitorsTable.current.scrollLeft -= bodyCell.current.clientWidth;
    }
  }

  const COMPETITORS_LIST = [
    {
      name: 'healthypaws',
      logo: '/assets/images/competitorLogos/healthypaws/grey.png',
    },
    {
      name: 'lemonade',
      logo: '/assets/images/competitorLogos/lemonade/grey.png',
    },
    {
      name: 'embrace',
      logo: '/assets/images/competitorLogos/embrace/grey.png',
    },
    {
      name: 'fetch',
      logo: '/assets/images/competitorLogos/fetch/grey.png',
    },
    {
      name: 'nationwide',
      logo: '/assets/images/competitorLogos/nationwide/grey.png',
    },
    {
      name: 'trupanion',
      logo: '/assets/images/competitorLogos/trupanion/grey.png',
    },
  ];

  const COMPETITORS = {
    healthypaws: {
      exam: false,
      dental: false,
      behavioral: false,
      rxFood: false,
      therapies: true,
      microchipping: false,
      reimbursement: false,
      noPreventiveRe: false,
      multiPetDiscount: false,
      eligibleEnroll: false,
      waitingPeriod: false,
      optionalWellnessPackage: false,
    },
    lemonade: {
      exam: 'Additional monthly fee required',
      dental: false,
      behavioral: false,
      rxFood: false,
      therapies: 'Additional monthly fee required',
      microchipping: true,
      reimbursement: false,
      noPreventiveRe: false,
      multiPetDiscount: false,
      eligibleEnroll: false,
      waitingPeriod: false,
      optionalWellnessPackage: true,
    },
    embrace: {
      exam: true,
      dental: 'Only up to $1k/yr',
      behavioral: true,
      rxFood: false,
      therapies: 'Only for 12 wks after treatment starts for a covered condition',
      microchipping: false,
      reimbursement: true,
      noPreventiveRe: false,
      multiPetDiscount: true,
      eligibleEnroll: false,
      waitingPeriod: false,
      optionalWellnessPackage: true,
    },
    fetch: {
      exam: true,
      dental: true,
      behavioral: 'Only up to  $1k/yr',
      rxFood: false,
      therapies: true,
      microchipping: false,
      reimbursement: true,
      noPreventiveRe: false,
      multiPetDiscount: false,
      eligibleEnroll: true,
      waitingPeriod: false,
      optionalWellnessPackage: false,
    },
    nationwide: {
      exam: false,
      dental: true,
      behavioral: 'Excluded with some plans',
      rxFood: 'Excluded with some plans',
      therapies: true,
      microchipping: false,
      reimbursement: false,
      noPreventiveRe: 'Depends on plan',
      multiPetDiscount: true,
      eligibleEnroll: 'Depends on plan',
      waitingPeriod: false,
      optionalWellnessPackage: true,
    },
    trupanion: {
      exam: false,
      dental: (
        <>
          Annual dental exam required in some states
          <Superscript1
            role='button'
            className={styles.supScript}
            onClick={handleSupscriptClick}
          >
            *
          </Superscript1>
        </>
      ),
      behavioral: (
        <>
          Additional monthly fee required in some states
          <Superscript1
            role='button'
            className={styles.supScript}
            onClick={handleSupscriptClick}
          >
            *
          </Superscript1>
        </>
      ),
      rxFood: (
        <>
          Coverage may have reimbursement and/or time limits depending on your state
          <Superscript1
            role='button'
            className={styles.supScript}
            onClick={handleSupscriptClick}
          >
            *
          </Superscript1>
        </>
      ),
      therapies: (
        <>
          Additional monthly fee required in some states
          <Superscript1
            role='button'
            className={styles.supScript}
            onClick={handleSupscriptClick}
          >
            *
          </Superscript1>
        </>
      ),
      microchipping: false,
      reimbursement: true,
      noPreventiveRe: false,
      multiPetDiscount: false,
      eligibleEnroll: false,
      waitingPeriod: false,
      optionalWellnessPackage: false,
    },
  };

  const TABLE_ROWS_LABEL = [
    {
      name: 'exam',
      label: 'Exam Fees for Accidents & Illnesses',
      icon: '/assets/images/iconImages/pumpkin-check.svg',
    },
    {
      name: 'dental',
      label: 'Dental Illnesses',
      icon: '/assets/images/iconImages/pumpkin-check.svg',
    },
    {
      name: 'behavioral',
      label: 'Behavioral Issues',
      icon: '/assets/images/iconImages/pumpkin-check.svg',
    },
    {
      name: 'rxFood',
      label: (
        <>
          Rx Food to Treat Covered Conditions
          <Superscript1
            role='button'
            className={styles.supScript}
            onClick={handleSupscriptClick}
          >
            *
          </Superscript1>
        </>
      ),
      icon: '/assets/images/iconImages/pumpkin-check.svg',
    },
    {
      name: 'therapies',
      label: 'Alternative Therapies',
      icon: '/assets/images/iconImages/pumpkin-check.svg',
    },
    {
      name: 'microchipping',
      label: 'Microchipping',
      icon: '/assets/images/iconImages/pumpkin-check.svg',
    },
    {
      name: 'reimbursement',
      label: '90% Reimbursement Rate for All Breeds Ages 8 Wks & Up',
      icon: '/assets/images/iconImages/pumpkin-check.svg',
    },
    {
      name: 'noPreventiveRe',
      label: 'No Preventive Care Requirements to Cover Certain Illnesses',
      icon: '/assets/images/iconImages/pumpkin-check.svg',
    },
    {
      name: 'multiPetDiscount',
      label: 'Discount for Multiple Pets',
      icon: '/assets/images/iconImages/pumpkin-check.svg',
    },
    {
      name: 'eligibleEnroll',
      label: 'All Pets Ages 8 Wks & Up Are Eligible to Enroll',
      icon: '/assets/images/iconImages/pumpkin-check.svg',
    },
    {
      name: 'optionalWellnessPackage',
      label:
        'Optional Wellness Package (Pumpkin’s package is not insurance, but an easy add-on)',
      icon: '/assets/images/iconImages/pumpkin-check.svg',
    },
  ];

  function setCheckIcon(isChecked, competitor) {
    if (competitor === PUMPKIN) {
      return pumpkinCheck;
    }
    return isChecked ? competitorCheck : competitorUncheck;
  }

  function handleSupscriptClick() {
    setTimeout(() => {
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
    }, 100);
    onClose();
  }

  return (
    <div className={styles.container}>
      <div className={styles.headerWrapper}>
        <span className={classNames(Typography.h3, styles.header)}>
          See how Pumpkin stands out from the pack.
        </span>

        <div className={styles.subHeaderWrapper}>
          <Body1 className={styles.subHeader}>
            Pumpkin insurance plans offer a bowlful of coverage benefits some other
            insurances exclude, limit, or charge sneaky add-on fees for. Let's dig
            in...
          </Body1>

          <Body1 className={styles.scrollHint}>
            <button type={'button'}>
              <img
                alt={'Scroll Left'}
                onClick={() => scroll(false)}
                src={'/assets/images/iconImages/blues-clues-arrow.svg'}
                style={{ transform: 'scaleX(-1)' }}
              />
            </button>
            <span className={styles.scrollHintText}>Scroll to compare</span>

            <button type={'button'}>
              <img
                alt={'Scroll Right'}
                onClick={() => scroll()}
                src={'/assets/images/iconImages/blues-clues-arrow.svg'}
              />
            </button>
          </Body1>
        </div>
      </div>
      <div className={styles.content}>
        <div className={classNames(styles.respTablePump, styles.respTable)}>
          <div className={styles.respTableHeader}>
            <div className={styles.tableHeaderCell}>
              <Body1 isBold>
                Comparison Chart
                <Superscript1
                  role='button'
                  className={styles.supScript}
                  onClick={handleSupscriptClick}
                >
                  *
                </Superscript1>
              </Body1>
            </div>

            <div className={styles.tableHeaderCellPump}>
              <img
                src={'/assets/images/Pumpkin_Logo_White.svg'}
                alt={PUMPKIN}
                className={styles.pumpkinLogo}
                aria-label='pumpkin'
              />
            </div>
          </div>

          <div className={styles.respTableBody}>
            {TABLE_ROWS_LABEL.map((item, row, { length }) => {
              const cellPumClass = classNames(styles.tableBodyCellPump, {
                [styles.tableBodyCellPumpRounded]: row + 1 === length,
              });
              return (
                <div className={styles.respTableRow} key={row}>
                  <div className={styles.tableBodyCell}>
                    <span
                      className={classNames(styles.cellText, styles.cellTextItem)}
                    >
                      {' '}
                      {item.label}
                    </span>
                  </div>

                  <div className={cellPumClass}>
                    <img src={item.icon} alt='' />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className={styles.containerCompetitors} ref={competitorsTable}>
          <div className={styles.boardChild}>
            <div className={styles.respTable}>
              <div className={styles.respTableHeader}>
                {COMPETITORS_LIST.map((item, cell) => {
                  const headerCellClass = classNames({
                    [styles.tableHeaderCellPump]: item.name === PUMPKIN,
                    [styles.tableHeaderCellComp]: item.name !== PUMPKIN,
                  });
                  return (
                    <div className={headerCellClass} key={cell} ref={bodyCell}>
                      <img
                        src={item.logo}
                        className={styles.competitorLogo}
                        aria-label={item.name}
                      />
                    </div>
                  );
                })}
              </div>

              <div className={styles.respTableBody}>
                {TABLE_ROWS_LABEL.map((item, row) => (
                  <div className={styles.respTableRow} key={row}>
                    {COMPETITORS_LIST.map((cells, cell) => {
                      const tableBodyCellClass = classNames({
                        [styles.tableBodyCellPump]: cells.name === PUMPKIN,
                        [styles.tableBodyCellIcon]: cells.name !== PUMPKIN,
                        [styles.tableBodyCellText]:
                          typeof COMPETITORS[cells.name][item.name] !== 'boolean',
                      });

                      const isChecked = COMPETITORS[cells.name][item.name];
                      const ariaLabelText = isChecked
                        ? `${cells.name} accepts`
                        : `${cells.name} does not accept`;

                      return (
                        <div
                          key={cell}
                          className={classNames(
                            tableBodyCellClass,
                            styles.checkIcon
                          )}
                        >
                          {typeof COMPETITORS[cells.name][item.name] ===
                          'boolean' ? (
                            <img
                              src={setCheckIcon(isChecked, cells.name)}
                              alt=''
                              aria-label={ariaLabelText}
                              className={styles.statusLogo}
                            />
                          ) : (
                            <span
                              className={classNames(
                                styles.cellDescText,
                                styles.cellText
                              )}
                            >
                              {}
                              <Screenreader>{item.label}</Screenreader>
                              <>{COMPETITORS[cells.name][item.name]}</>
                            </span>
                          )}
                        </div>
                      );
                    })}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompareTable;
