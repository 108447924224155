import { push } from 'connected-react-router';

import { QUOTE_ID_COOKIE_NAME } from '@pumpkincare/quotes';
import { ADD, setCookie } from '@pumpkincare/shared';

import getQuoteFlowNextStep from '../selectors/get-quote-flow-next-step';
import {
  getQuotesId,
  getQuotesMode,
  getQuotesPets,
} from '../selectors/quotes-selectors';
import { setActiveId } from '../state';
import addPetToQuote from './add-pet-to-quote';
import deletePetFromQuote from './delete-pet-from-quote';
import fetchQuoteAndGetId from './fetch-quote-and-get-id';
import setFormattedPets from './set-formatted-pets';
import updatePetInQuote from './update-pet-in-quote';

export default function submitIntroduction(pets) {
  return (dispatch, getState) => {
    const state = getState();
    const existingPets = getQuotesPets(state);
    const mode = getQuotesMode(state);
    const petsToDelete = existingPets.filter(
      existingPet => !pets.some(pet => existingPet.id === pet.id)
    );

    // Promise that gets resolved with either
    // quoteId value from Redux or newly fetched from API
    return Promise.resolve(
      getQuotesId(state) || dispatch(fetchQuoteAndGetId())
    ).then(quoteId => {
      petsToDelete.forEach(pet => dispatch(deletePetFromQuote(quoteId, pet.id)));

      return Promise.all(
        pets.map(pet =>
          typeof pet.id === 'number' // interim pet id, before pet is added to DB
            ? dispatch(addPetToQuote(quoteId, pet))
            : dispatch(updatePetInQuote(quoteId, pet))
        )
      ).then(responsePets => {
        const activePet = mode === ADD ? pets[pets.length - 1] : pets[0];
        dispatch(
          setActiveId(
            responsePets.find(
              responsePet => activePet.petName === responsePet.petName
            ).id
          )
        );

        setCookie(QUOTE_ID_COOKIE_NAME, quoteId);

        dispatch(setFormattedPets(responsePets));
        dispatch(push(getQuoteFlowNextStep(getState())));
      });
    });
  };
}
