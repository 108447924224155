import PropTypes from 'prop-types';

import { WWW_URL } from '@pumpkincare/config';

function TermsPrevent2(props) {
  const { urlSearchString } = props;

  return (
    <>
      <h3>Pumpkin Preventive Essentials Summary Terms</h3>
      <p>
        <b>
          {`Please see the `}
          <a
            href={`${WWW_URL}/customeragreement${urlSearchString}`}
            style={{ fontSize: 'inherit' }}
          >
            customer agreement
          </a>
          {` for full terms and conditions`}
        </b>
      </p>
      <p>
        For your convenience, we’ll automatically renew your Preventive Essentials
        enrollment every 12 months unless you contact us to cancel. We’ll use your
        current payment method and notify you in advance of any changes to the
        Preventive Essentials offering or fee. Pet parents must be 18 or older to
        purchase the Pumpkin Preventive Essentials offering.
      </p>
      <b>Product Delivery</b>
      <p>
        Once your payment is made and a veterinarian prescription in sent to our
        pharmacy fulfillment partner, and upon confirmation of a valid prescription
        from your veterinarian, you will receive six monthly doses of Simparica Trio®
        (sarolaner, moxidectin, and pyrantel chewable tablets) for dogs or six
        monthly doses of Revolution® Plus (selamectin and sarolaner topical solution)
        for cats (the “Product”) in the appropriate dosage for your Pet based on the
        prescription written by your veterinarian . The second shipment of six doses
        shall be sent 6 months after the first shipment provided a prescription was
        provided to our pharmacy partner for such refill. To be eligible to receive
        the Product, you must obtain or possess a prescription from your veterinarian
        and provide the required information to Pumpkin within 30 days of joining
        (and after each renewal). During your first year, you may instead choose to
        purchase your own Product in lieu of receiving the first shipment. See our{' '}
        <a
          href={`${WWW_URL}/customeragreement${urlSearchString}`}
          style={{ fontSize: 'inherit' }}
        >
          Customer Agreement
        </a>
        {` for full Product and fulfillment terms`}
      </p>
      <p>
        <b>Vaccine and Wellness Office Visit Refund</b>
      </p>
      <p>
        Procedures To obtain a refund, you must upload an image of your veterinarian
        receipt through Pumpkin’s provided online portal for verification. Upon
        receiving an uploaded image of your veterinarian receipt, Pumpkin shall
        refund you for the full amount of such purchase by either check or ACH
        payment, at Pumpkin’s discretion. See our Customer Agreement for full Vaccine
        and Wellness Office Visit terms.
      </p>

      <h3>Cancellation Policy</h3>
      <p>
        <b>
          <u>Preventive Essentials Cancellation & Refunds for Dogs and Cats</u>
        </b>
      </p>
      <p>
        {`If `}
        <i>
          you <u>have not</u> been shipped 6 mo. of parasite meds or reimbursed for 6
          mo. bought at the vet.
        </i>
        Cancel within the first 30 days and get a full refund. Cancel after 30 days
        and get a prorated refund for the remaining days in the month. <br />
        {`If `}
        <i>
          you <u>have</u> been shipped 6 mo. of parasite meds or reimbursed for 6 mo.
          bought at the vet.
        </i>
        <br />
        Cancel any time in the year term and Pumpkin will charge your card for an
        outstanding balance equal to your remaining Preventive Essentials monthly
        fees in the current 6-month period.
      </p>
      <p>
        <b>
          <u>Preventive Essentials Cancellation &amp; Refunds for Puppies</u>
        </b>
      </p>
      <p>
        {`If `}
        <i>
          you have <u>not</u> been reimbursed for any vaccine(s), shipped 6 mo. of
          parasite meds, or reimbursed for 6 mo. bought at the vet.
        </i>
        <br />
        {`Cancel within 30 days and get a full refund. Cancel after 30 days and get a
        prorated refund the remaining days in the month. `}
      </p>
      <p>
        {`If `}
        <i>
          you <u>have</u> been reimbursed for vaccine(s), but <u>have not</u> been
          shipped or reimbursed for 6 mo. of parasite meds.
        </i>
        <br />
        {`Cancel anytime in your initial year term and Pumpkin will charge you for an
        outstanding balance equal to the difference between the amount reimbursed to
        you for the vaccine(s) and the Preventive Essentials monthly fees paid by you
        thus far. `}
      </p>
      <p>
        {`If `}
        <i>
          you <u>have</u> been shipped 6 mo. of parasite meds or reimbursed for 6 mo.
          bought at the vet.
        </i>
        <br />
        Cancel any time in your initial year term and Pumpkin will charge you for an
        outstanding balance equal to your remaining Preventive Essentials monthly
        fees in the current 6-month period.
      </p>
      <p>
        {`See our `}
        <a
          href={`${WWW_URL}/customeragreement${urlSearchString}`}
          style={{ fontSize: 'inherit' }}
        >
          Customer Agreement
        </a>
        {` for full cancellation & refund terms `}
      </p>
      <p>
        By clicking complete enrollment, YOU authorize Pumpkin to deduct the amount
        above at the selected frequency from the payment method YOU provided for full
        or partial payment of services when due. In addition, and in case you have
        purchased Pumpkin Preventive Essentials and subsequently wish to cancel your
        Preventive Essentials plan, by clicking complete enrollment, YOU authorize
        Pumpkin to charge YOU the Outstanding Balance upon such cancellation from the
        payment method on file IF an Outstanding Amount is so due.
      </p>
    </>
  );
}

export default TermsPrevent2;

TermsPrevent2.propTypes = {
  urlSearchString: PropTypes.string.isRequired,
};
