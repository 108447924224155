import { createSelector } from 'reselect';

import { getIsLoggedIn } from '@pumpkincare/shared';

import {
  getAppFeatureFlags,
  getRouterLocationPathName,
  Paths,
} from '../../app-shell';
import { getQuotes } from './quotes-selectors';

const getQuoteFlowNextStep = createSelector(
  [getRouterLocationPathName, getQuotes, getAppFeatureFlags],

  (pathname, quote, featureFlags) => {
    let nextPathname;

    switch (pathname) {
      case Paths.Intro:
        nextPathname = Paths.PetInfo;
        break;

      case Paths.PetInfo:
        nextPathname =
          featureFlags['multi-quote-flow'] && quote.pets.length > 1
            ? Paths.PlanSelection
            : Paths.PetQuestions;
        break;

      case Paths.PetQuestions:
        if (quote.isRegistered || getIsLoggedIn()) {
          nextPathname = Paths.PlanSelection;
        } else {
          nextPathname = Paths.Register;
        }
        break;

      case Paths.Register:
        nextPathname = Paths.PlanSelection;
        break;

      case Paths.EmbarkHello:
        nextPathname = Paths.Intro;
        break;

      default:
        nextPathname = Paths.Intro;
        break;
    }

    return nextPathname;
  }
);

export default getQuoteFlowNextStep;
