import classNames from 'classnames';

import { ToggleButton } from '@pumpkincare/shared/ui';

import styles from './single-select.module.css';

function SingleSelect({
  data,
  onChange = () => {},
  currentAnswer,
  autoFocus = false,
}) {
  const icon = '/assets/images/mui-icons/radio.png';
  const selectedIcon = '/assets/images/mui-icons/radio-checked.png';

  const is4x4 = data.length === 4;
  const groupClassName = classNames(styles.group, {
    [styles.group4x4]: is4x4,
  });

  const currentId = currentAnswer[0]?.id;

  return (
    <div role={'radiogroup'} className={groupClassName}>
      {is4x4
        ? data.map((answer, i) => {
            const { answer_text = '', img_src = '', id } = answer;
            const isSelected = id === currentId;

            return (
              <ToggleButton
                classes={{ root: styles.groupItem4x4 }}
                label={answer_text}
                selected={isSelected}
                icon={img_src}
                clickHandler={() => onChange(answer)}
                role={'radio'}
                tabIndex={i + 1}
                key={id}
                direction={'v'}
                data-testid='radio'
                autoFocus={autoFocus && i === 0}
              />
            );
          })
        : data.map((answer, i) => {
            const { answer_text = '', id } = answer;
            const isSelected = id === currentId;

            return (
              <ToggleButton
                classes={{ root: styles.groupItem }}
                label={answer_text}
                selected={isSelected}
                icon={isSelected ? selectedIcon : icon}
                clickHandler={() => onChange(answer)}
                role={'radio'}
                tabIndex={i + 1}
                key={id}
                data-testid='radio'
                autoFocus={autoFocus && i === 0}
              />
            );
          })}
    </div>
  );
}

export default SingleSelect;
