import { runQueuedExperiments } from '@pumpkincare/analytics';
import { storeObserver } from '@pumpkincare/shared';

import getLaunchDarklyUser from './selectors/get-launch-darkly-user';
import { setFeatureFlags } from './app-ducks';

export default function appStoreObservers(store, ldClient) {
  const { dispatch } = store;

  storeObserver(store, getLaunchDarklyUser, user => {
    ldClient.identify(user).then(flags => {
      dispatch(setFeatureFlags(flags));

      runQueuedExperiments({ featureFlags: flags });
    });
  });
}
