import { useDispatch, useSelector } from 'react-redux';

import { Body1, SwitchInput } from '@pumpkincare/shared/ui';

import {
  setUpdatedQuoteBillingField,
  setUpdatedQuoteField,
} from '../../../../quotes';
import {
  getQuotesBillingAddress,
  getShippingSameAsBilling,
} from '../../../../quotes/selectors';
import AddressInput from './address-input';

function BillingForm() {
  const dispatch = useDispatch();
  const quotesBillingAddress = useSelector(getQuotesBillingAddress);
  const isShippingSameAsBilling = useSelector(getShippingSameAsBilling);

  function handleSwitchChange(isChecked) {
    dispatch(
      setUpdatedQuoteField({
        field: 'isShippingSameAsBilling',
        value: isChecked,
      })
    );
  }

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          margin: '16px 0 0',
        }}
      >
        <div data-testid='billing-switch-button'>
          <SwitchInput
            checked={isShippingSameAsBilling}
            onChange={handleSwitchChange}
            id='toggleAddress'
          />
        </div>

        <Body1>Switch on to use your account address as your billing address.</Body1>
      </div>

      {isShippingSameAsBilling ? null : (
        <div data-testid='billing-form'>
          <AddressInput
            address={quotesBillingAddress}
            onChange={payload => dispatch(setUpdatedQuoteBillingField(payload))}
            style={{ marginTop: '16px' }}
          />
        </div>
      )}
    </div>
  );
}

export default BillingForm;
