import { Body1 } from '@pumpkincare/shared/ui';

import styles from './pumpkin-difference.module.css';

function PumpkinDifference() {
  return (
    <div className={styles.root}>
      <h4 className={styles.header}>The Pumpkin Difference</h4>

      <div className={styles.row}>
        <div className={styles.item}>
          <div className={styles.image}>
            <img
              src='/assets/images/cartoons/pumpkin-difference/pets-save-10.png'
              aria-hidden='true'
            />
          </div>

          <div className={styles.caption}>
            <Body1 role='text'>
              Big Fur Fams Get <br />
              Big Savings
            </Body1>
          </div>
        </div>

        <div className={styles.item}>
          <div className={styles.image}>
            <img
              src='/assets/images/cartoons/pumpkin-difference/cat-in-plant.png'
              aria-hidden='true'
            />
          </div>

          <div className={styles.caption}>
            <Body1 role='text'>
              Easy, Speedy <br />
              Online Claims
            </Body1>
          </div>
        </div>

        <div className={styles.item}>
          <div className={styles.image}>
            <img
              src='/assets/images/cartoons/pumpkin-difference/dog-love.png'
              aria-hidden='true'
            />
          </div>

          <div className={styles.caption}>
            <Body1 role='text'>
              A Care Team <br />
              of Experts
            </Body1>
          </div>
        </div>

        <div className={styles.item}>
          <div className={styles.image}>
            <img
              src='/assets/images/cartoons/pumpkin-difference/cat-with-box.png'
              aria-hidden='true'
            />
          </div>

          <div className={styles.caption}>
            <Body1 role='text'>
              See Any Vet in <br />
              the US or Canada
            </Body1>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PumpkinDifference;
