import { generateIdentifyMeta, INITIAL } from '@pumpkincare/shared';

import { setPlanPageLoadStatus } from '../../plans';
import {
  getQuotesActiveId,
  getQuotesEmail,
  getQuotesPetsOrder,
  getQuotesTrackingId,
} from '../selectors/quotes-selectors';
import { setUpdatedPet } from '../state/quotes-ducks';

export default function setFormattedUpdatedPet(pet) {
  return (dispatch, getState) => {
    const state = getState();
    const activeId = getQuotesActiveId(state);
    const petsOrder = getQuotesPetsOrder(state)[activeId] || 1;
    const trackingId = getQuotesTrackingId(state);
    const email = getQuotesEmail(state);
    const { petBreedName, petBreedSpecies, petAge, petGender } = pet;
    const traits = {
      [`PET${petsOrder}BREED`]: petBreedName,
      [`PET${petsOrder}SPECIES`]: petBreedSpecies,
      [`PET${petsOrder}AGE`]: petAge,
      [`PET${petsOrder}GENDER`]: petGender,
      email,
    };

    dispatch(setUpdatedPet(pet, generateIdentifyMeta(traits, trackingId)));
    dispatch(setPlanPageLoadStatus(INITIAL));
  };
}
