import { CjPixel, TunePixel } from '@pumpkincare/marketing/ui';
import {
  calculateCheckoutTotals,
  getQuoteId,
  getQuotePets,
  useQuote,
  useQuotePricing,
} from '@pumpkincare/quotes';

// The main value of this component is to call useCost in a component that is conditionally rendered
export default function PlanSelectionPixels() {
  const { data: quoteData } = useQuote();
  const quoteId = getQuoteId(quoteData);

  const { data: pricingData } = useQuotePricing();

  const { total_insurance_price: totalInsurancePrice } = pricingData;

  const petsData = getQuotePets(quoteData);

  const { grandTotal } = calculateCheckoutTotals(petsData, totalInsurancePrice);

  return (
    <>
      <CjPixel page='Lead' quoteId={quoteId} checkoutTotal={grandTotal} />
      <TunePixel page='Lead' quoteId={quoteId} checkoutTotal={grandTotal} />
    </>
  );
}
