import PropTypes from 'prop-types';

import { WWW_URL } from '@pumpkincare/config';

function TermsPrevent3(props) {
  const { urlSearchString } = props;

  return (
    <>
      <h3>Pumpkin Preventive Essentials Summary Terms</h3>
      <p>
        {`Please see the `}

        <a
          href={`${WWW_URL}/customeragreement${urlSearchString}`}
          style={{ fontSize: 'inherit' }}
        >
          customer agreement
        </a>
        {` for full terms and conditions`}
      </p>
      <p>
        For your convenience, we’ll automatically renew your Preventive Essentials
        enrollment every 12 months unless you contact us to cancel. We’ll use your
        current payment method and notify you in advance of any changes to the
        Preventive Essentials offering or fee. Pet parents must be 18 or older to
        purchase the Pumpkin Preventive Essentials offering.
      </p>

      <p>
        <b>
          Vaccine, Wellness Office Visit, Wellness Fecal Test and Wellness Vector
          Borne Test Refund
        </b>{' '}
        Procedures To obtain a refund, you must upload an image of your veterinarian
        receipt through Pumpkin’s provided online portal for verification. Upon
        receiving an uploaded image of your veterinarian receipt, Pumpkin shall
        refund you for the full amount of such purchase by either electronic check,
        check or ACH payment, at Pumpkin’s discretion. See our{' '}
        <a
          href={`${WWW_URL}/customeragreement${urlSearchString}`}
          style={{ fontSize: 'inherit' }}
        >
          Customer Agreement
        </a>
        {` for full terms and exclusions.`}
      </p>

      <h3>Cancellation Policy</h3>
      <p>
        <b>Pumpkin Pet Insurance Cancellation & Refunds</b>
      </p>

      <p>
        <b>
          <i>I live outside New York State</i>
        </b>
      </p>
      <p>
        Cancel in the first 30 days and get a full refund as long as you have not yet
        filed any covered claims. If you cancel after 30 days and/or have already
        filed a covered claim, you’ll get a prorated refund for the remaining days in
        the month.
      </p>

      <p>
        <b>
          <i>I live in New York State</i>
        </b>
      </p>
      <p>
        Cancel any time during your pet’s year-long policy period and get a prorated
        refund for the remaining days in the month.
      </p>

      <p>
        For full cancellation terms, see our{' '}
        <a
          href={`${WWW_URL}/customeragreement${urlSearchString}`}
          style={{ fontSize: 'inherit' }}
        >
          Customer Agreement
        </a>
        .
      </p>

      <p>
        <b>Preventive Essentials Cancellation & Refunds</b>
      </p>

      <p>
        <b>
          <i>I have not been reimbursed for any Preventive Essentials Claims.</i>
        </b>
      </p>
      <p>
        Cancel in the first 30 days and get a full refund. Cancel after 30 days and
        get a prorated refund for the remaining days in the month.
      </p>

      <p>
        <b>
          <i>I have been reimbursed for one or more Preventive Essentials Claims.</i>
        </b>
      </p>
      <p>
        Cancel any time during the year term of your Preventive Essentials Customer
        Agreement, and Pumpkin will charge your card for an outstanding balance equal
        to the difference of the amount reimbursed to you for your claim(s), and the
        amount paid by you in Preventive Essentials monthly fees this term.
      </p>

      <p>
        See our{' '}
        <a
          href={`${WWW_URL}/customeragreement${urlSearchString}`}
          style={{ fontSize: 'inherit' }}
        >
          Customer Agreement
        </a>
        {` for full cancellation & refund terms.`}
      </p>

      <p>
        By clicking complete enrollment, YOU authorize Pumpkin to deduct the amount
        above at the selected frequency from the payment method YOU provided for full
        or partial payment of services when due. In addition, and in case you have
        purchased Pumpkin Preventive Essentials and subsequently wish to cancel your
        Preventive Essentials plan, by clicking complete enrollment, YOU authorize
        Pumpkin to charge YOU the Outstanding Balance upon such cancelation from the
        payment method on file IF an Outstanding Amount is so due.
      </p>
    </>
  );
}

export default TermsPrevent3;

TermsPrevent3.propTypes = {
  urlSearchString: PropTypes.string.isRequired,
};
