import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { push } from 'connected-react-router';
import queryString from 'query-string';
import Store from 'store';

import { MC_URL } from '@pumpkincare/config';
import { postIdentityHdyhau } from '@pumpkincare/identity';
import { SOURCE_HDYHAU } from '@pumpkincare/quotes';
import { COMPLETE_QUOTE_ID_LS_KEY, setIsLoggedIn } from '@pumpkincare/shared';
import { customSignInToCognito } from '@pumpkincare/user';
import { postCustomVets } from '@pumpkincare/vets';

import { Paths } from '../../../app-shell';
import { getIdentityId } from '../../../identity/selectors';

export default function useSubmitHdyhau() {
  const dispatch = useDispatch();
  const identityId = useSelector(getIdentityId);
  const [isLoading, setIsLoading] = useState(false);

  const { search } = useLocation();
  const { token, email } = queryString.parse(search);

  function goToRegistration() {
    window.location.replace(
      `${MC_URL}` +
        queryString.stringifyUrl({
          url: '/register',

          query: {
            token,
            tokenType: 'vet_survey',
            source: SOURCE_HDYHAU,
          },
        })
    );
  }

  function submitCustomVet(vet) {
    return postCustomVets([vet]);
  }

  // function submitUserVet(vet) {
  //   return postUserVet(
  //     userId,
  //     policies.map(policy => {
  //       return { vet_id: vet.id, pet_id: policy.pet.id };
  //     })
  //   );
  // }

  function submitHdyhauSelection(payload) {
    const { vet, hdyhauOptionSelected, hdyhauOptionValue, order } = payload;
    return postIdentityHdyhau(identityId, {
      quote_id: Store.get(COMPLETE_QUOTE_ID_LS_KEY),
      order: order,
      option_identifier: hdyhauOptionSelected,
      option_value: hdyhauOptionValue,
      vet_id: vet?.id,
    });
  }

  async function submitHdyhau({
    vet: inputVet,
    hdyhauOptionSelected,
    hdyhauOptionValue,
    order,
  }) {
    /*
    vet can be in one of four states:
    (1) vet created by user via custom form, with no id but fields like address
    (2) existing vet returned by BE, with an id
    (3) custom form is hidden, { vet_name: NO_RESULTS_TITLE_HIDE_FORM }
    (4) null bc user has selected a non-vet option

    if (1), block progress and create a vet and get the response with id
    if (1) or (2), add the vet to the policies
     */
    setIsLoading(true);
    const vet =
      !inputVet?.id && inputVet?.address
        ? (await submitCustomVet(inputVet))[0]
        : inputVet;

    Promise.all([
      // vet?.id ? submitUserVet(vet) : Promise.resolve(),
      submitHdyhauSelection({
        vet,
        hdyhauOptionSelected,
        hdyhauOptionValue,
        order,
      }),
    ]).then(
      customSignInToCognito(email, token)
        .then(() => {
          setIsLoggedIn(true);
          setIsLoading(false);
          goToRegistration();
        })
        .catch(() => {
          dispatch(push(Paths.CheckYourEmail));
        })
    );
  }

  return { submitHdyhau, isLoading };
}
