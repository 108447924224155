// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../../../../libs/shared/src/ui/breakpoints.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".one-step-closer_oneStepCloserContainer_d245043c {\n    position: relative;\n    background-color: var(--peteyBlue);\n    border-radius: 10px;\n    padding: 16px 168px 16px 24px;\n    margin-top: 60px;\n    margin-bottom: 40px;\n}\n\n.one-step-closer_image_95f6c028 {\n    position: absolute;\n    right: 24px;\n    bottom: 10px;\n    z-index: 100;\n}\n\n@media " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["tablet"] + " {\n    .one-step-closer_oneStepCloserContainer_d245043c {\n        margin-top: 80px;\n    }\n}\n\n@media " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["desktop"] + " {\n    .one-step-closer_oneStepCloserContainer_d245043c {\n        margin-top: 100px;\n    }\n}", "",{"version":3,"sources":["webpack://src/checkout/view/checkout/one-step-closer/one-step-closer.module.css"],"names":[],"mappings":"AAEA;IACI,kBAAkB;IAClB,kCAAkC;IAClC,mBAAmB;IACnB,6BAA6B;IAC7B,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI;QACI,gBAAgB;IACpB;AACJ;;AAEA;IACI;QACI,iBAAiB;IACrB;AACJ","sourcesContent":["@value desktop, tablet from '~@pumpkincare/shared/ui/breakpoints';\n\n.oneStepCloserContainer {\n    position: relative;\n    background-color: var(--peteyBlue);\n    border-radius: 10px;\n    padding: 16px 168px 16px 24px;\n    margin-top: 60px;\n    margin-bottom: 40px;\n}\n\n.image {\n    position: absolute;\n    right: 24px;\n    bottom: 10px;\n    z-index: 100;\n}\n\n@media tablet {\n    .oneStepCloserContainer {\n        margin-top: 80px;\n    }\n}\n\n@media desktop {\n    .oneStepCloserContainer {\n        margin-top: 100px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"desktop": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["desktop"] + "",
	"tablet": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["tablet"] + "",
	"oneStepCloserContainer": "one-step-closer_oneStepCloserContainer_d245043c",
	"image": "one-step-closer_image_95f6c028"
};
export default ___CSS_LOADER_EXPORT___;
