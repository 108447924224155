import { useState } from 'react';
import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { analyticsTrack, INITIATE_EXPERIMENT } from '@pumpkincare/analytics';
import {
  getPricingTransactionFee,
  getQuoteCompletePets,
  getQuotePets,
  useQuote,
  useQuotePricing,
} from '@pumpkincare/quotes';
import { getIsLoggedIn } from '@pumpkincare/shared';
import { Body2, Spinner, ToggleButton } from '@pumpkincare/shared/ui';
import { getUserIsChargedAnnually, getUserPets, useUssr } from '@pumpkincare/user';

import {
  useIsChargedAnnuallySetter,
  useIsChargedAnnuallyValue,
} from '../../checkout-provider';
import PetSummaryPanel from './pet-summary-panel';
import TotalFee from './total-fee';

import styles from './summary.module.css';

function Summary() {
  const { data: userData } = useUssr();
  const isLoggedUserAnnuallyCharged = getUserIsChargedAnnually(userData);
  const userPets = getUserPets(userData);

  const isLocalAnnuallyCharged = useIsChargedAnnuallyValue();
  const setIsChargedAnnually = useIsChargedAnnuallySetter();

  const isLoggedIn = getIsLoggedIn();

  const isChargedAnnually = isLoggedIn
    ? isLoggedUserAnnuallyCharged
    : isLocalAnnuallyCharged;

  const [isAnnual, setIsAnnual] = useState(isChargedAnnually);

  const { data: pricingData, isFetching: isPricingFetching } =
    useQuotePricing(isAnnual);

  const { annualPayCheckout } = useFlags();
  const { data: quoteData } = useQuote();

  const pets = isLoggedIn
    ? getQuotePets(quoteData)
    : getQuoteCompletePets(quoteData);

  const transactionFee = getPricingTransactionFee(pricingData);

  const isMultiPet = pets.length > 1;

  const buttonsClassName = classNames(styles.periodChoiceItem, {
    [styles.disabled]: isLoggedIn,
  });

  function handlePeriodSelection(value, event) {
    event.preventDefault();

    setIsAnnual(value);
    setIsChargedAnnually(value);

    analyticsTrack({
      event: INITIATE_EXPERIMENT,
      category: 'Annual Pay Checkout',
      label: value ? 'annually' : 'monthly',
    });
  }

  return (
    <div className={styles.petsSummary}>
      <>
        <h4 className={styles.planReview}>Plan Review</h4>

        {annualPayCheckout ? (
          <>
            <div>
              <Body2>
                {isLoggedIn
                  ? `Your payment schedule is already set based on your other plan${
                      userPets.length > 1 ? 's' : ''
                    }. Please contact us if you’d like to make a change.`
                  : 'Choose your payment schedule:'}
              </Body2>
            </div>

            <div className={styles.periodChoice}>
              <ToggleButton
                classes={{
                  root: buttonsClassName,
                  selected: styles.periodChoiceItemSelected,
                }}
                data-testid='monthly-pay'
                role='radio'
                label={'Monthly'}
                selected={!isAnnual}
                clickHandler={e => handlePeriodSelection(false, e)}
              />

              <ToggleButton
                classes={{
                  root: buttonsClassName,
                  selected: styles.periodChoiceItemSelected,
                }}
                data-testid='annual-pay'
                role='radio'
                label={'Annually'}
                selected={isAnnual}
                clickHandler={e => handlePeriodSelection(true, e)}
              />
            </div>
          </>
        ) : null}

        {isPricingFetching ? (
          <div className={styles.spinnerContainer}>
            <Spinner />
          </div>
        ) : (
          <>
            {pets.map(pet => {
              const { pet_totals: petTotals } = pricingData;
              const petCostData = petTotals.find(prices => prices.id === pet.id);

              return petCostData ? (
                <PetSummaryPanel
                  key={pet.id}
                  pet={pet}
                  isMultiPet={isMultiPet}
                  petCost={petCostData}
                  isUserChargedAnnually={isChargedAnnually}
                />
              ) : null;
            })}

            <TotalFee
              pets={pets}
              transactionFee={transactionFee}
              isAnnualCharged={isChargedAnnually}
            />
          </>
        )}
      </>
    </div>
  );
}

export default Summary;
