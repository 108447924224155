import classNames from 'classnames';

import { CAT, getIsPuppy } from '@pumpkincare/shared';
import { Body1, LegalBody, Typography } from '@pumpkincare/shared/ui';

import ConfirmPepButtons from '../confirm-pep-buttons';

import styles from './pep3-picker-details.module.css';

function Pep3PickerDetails({ pet, onAdd, onRemove }) {
  const isPuppy = getIsPuppy(pet);
  const bottomImageClassName = classNames({
    [styles.bottomImage]: !isPuppy,
    [styles.bottomImagePuppy]: isPuppy,
  });

  const FOR_DOGS = [
    {
      src: '/assets/images/stethoscope.png',
      alt: 'stethoscope',
      title: 'Annual Wellness Exam',
      text: 'Full refund for the exam fee when you take your dog to the vet for a yearly check-up, vaccines & tests – key for checking head-to-tail health and catching issues early',
    },
    {
      src: 'assets/images/syringe.png',
      alt: 'vaccine',
      title: 'Vaccines at the Vet',
      text: 'Full refund for any 2 vaccines you and your vet choose each year – key for protecting your dog against common preventable illnesses and diseases',
    },
    {
      src: '/assets/images/poop-sample.png',
      alt: 'fecal sample',
      title: 'Parasite Screening Tests',
      text: 'Full refund for 2 key yearly tests: 1 blood test for heartworm & tick diseases, including lyme, ehrlichiosis & anaplasmosis + 1 fecal “poop” test for roundworms, hookworms & whipworms',
    },
  ];

  const FOR_PUPPIES = [
    {
      src: '/assets/images/stethoscope.png',
      alt: 'stethoscope',
      title: 'Annual Wellness Exam',
      text: 'Full refund for the exam fee when you take your puppy to the vet for a yearly check-up, vaccines & tests – key for checking head-to-tail health and catching issues early.',
    },
    {
      src: 'assets/images/syringe.png',
      alt: 'vaccine',
      title: 'Puppy Vaccines at the Vet',
      text: 'Full refund for any 4 vaccines you and your vet choose each year – key for protecting your pup against common preventable illnesses & diseases',
    },
    {
      src: '/assets/images/poop-sample.png',
      alt: 'fecal sample',
      title: 'Parasite Screening Tests',
      text: 'Full refund for 1 yearly fecal test to screen for intestinal worms, like roundworms, hookworms & whipworms – key for detecting these dangerous parasites early',
    },
  ];

  const FOR_KITTENS = [
    {
      src: '/assets/images/stethoscope.png',
      alt: 'stethoscope',
      title: 'Annual Wellness Exam',
      text: 'Full refund for the exam fee when you take your cat to the vet for a yearly check-up, vaccines & tests – key for checking head-to-tail health and catching issues early.',
    },
    {
      src: 'assets/images/syringe.png',
      alt: 'vaccine',
      title: 'Vaccine at the Vet',
      text: 'Full refund for any 1 vaccine you and your vet choose each year – key for protecting your cat against common preventable illnesses and diseases.',
    },
    {
      src: '/assets/images/poop-sample.png',
      alt: 'fecal sample',
      title: 'Parasite Screening Tests',
      text: 'Full refund for 1 yearly fecal test to screen for intestinal worms, like roundworms, hookworms & whipworms – key for detecting these dangerous parasites early.',
    },
  ];

  const benefits =
    pet.petBreedSpecies === CAT ? FOR_KITTENS : isPuppy ? FOR_PUPPIES : FOR_DOGS;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h4>{pet.petName}’s Preventive Essentials Pack</h4>
      </div>
      <div className={styles.gridContents}>
        {benefits.map((item, i) => (
          <div key={i} className={styles.gridColumn}>
            <img src={item.src} alt='' className={styles.gridColumnImage} />
            <Body1 isBold className={styles.Body2}>
              {item.title}
            </Body1>
            <LegalBody className={classNames(styles.text)}>{item.text}</LegalBody>
          </div>
        ))}
      </div>

      <div className={styles.imageContainer}>
        <div className={bottomImageClassName} />
      </div>
      <div className={styles.buttonContainer}>
        <ConfirmPepButtons
          onAdd={onAdd}
          onRemove={onRemove}
          classes={{ container: styles.flexContentCenter }}
        />
      </div>
    </div>
  );
}

export default Pep3PickerDetails;
