import classNames from 'classnames';
import PropTypes from 'prop-types';

import { ButtonStyles } from '@pumpkincare/shared/ui';

import styles from './confirm-pep-buttons.module.css';

function ConfirmPepButtons(props) {
  const { onAdd, onRemove, classes } = props;
  const containerClassName = classNames(styles.container, classes.container);
  return (
    <div className={containerClassName} data-testid={'confirm-pep-buttons'}>
      <button
        className={classNames(ButtonStyles.secondary, styles.declineButton)}
        onClick={onRemove}
        data-testid='no-pep'
      >
        No Thanks
      </button>

      <button
        className={classNames(ButtonStyles.primary, styles.addButton)}
        onClick={onAdd}
        data-testid='select-pep'
      >
        Add to plan
      </button>
    </div>
  );
}

ConfirmPepButtons.defaultProps = {
  classes: {},
  onAdd: () => {},
  onRemove: () => {},
};

ConfirmPepButtons.propTypes = {
  classes: PropTypes.shape({ container: PropTypes.string }),
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
};

export default ConfirmPepButtons;
