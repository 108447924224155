// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../../../../../libs/shared/src/ui/breakpoints.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pet-summary-panel_insurancePreventTitleDiv_7eb28beb {\n  display: flex;\n  width: 100%;\n  justify-content: space-between;\n}\n\n.pet-summary-panel_insurancePreventTitlePrice_86547036 {\n  text-align: right;\n}\n\n.pet-summary-panel_verticalLine_a6682769 {\n  border-left: 2px solid;\n  color: var(--pusheenGray);\n  padding-left: 16px;\n  margin-left: 16px;\n}\n\n.pet-summary-panel_titleClassname_f9e53b1a {\n  padding-left: 0px;\n}\n\n.pet-summary-panel_detailsColor_1dcd284a {\n  color: var(--gromitGray);\n}\n\n.pet-summary-panel_LegalBodyMargin_be7bf161 {\n  margin-bottom: 3px;\n  color: var(--gromitGray);\n}", "",{"version":3,"sources":["webpack://src/checkout/view/checkout/summary/pet-summary-panel/prevent.module.css"],"names":[],"mappings":"AAEA;EACE,aAAa;EACb,WAAW;EACX,8BAA8B;AAChC;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,sBAAsB;EACtB,yBAAyB;EACzB,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,kBAAkB;EAClB,wBAAwB;AAC1B","sourcesContent":["@value mobile from '~@pumpkincare/shared/ui/breakpoints';\n\n.insurancePreventTitleDiv {\n  display: flex;\n  width: 100%;\n  justify-content: space-between;\n}\n\n.insurancePreventTitlePrice {\n  text-align: right;\n}\n\n.verticalLine {\n  border-left: 2px solid;\n  color: var(--pusheenGray);\n  padding-left: 16px;\n  margin-left: 16px;\n}\n\n.titleClassname {\n  padding-left: 0px;\n}\n\n.detailsColor {\n  color: var(--gromitGray);\n}\n\n.LegalBodyMargin {\n  margin-bottom: 3px;\n  color: var(--gromitGray);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mobile": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["mobile"] + "",
	"insurancePreventTitleDiv": "pet-summary-panel_insurancePreventTitleDiv_7eb28beb",
	"insurancePreventTitlePrice": "pet-summary-panel_insurancePreventTitlePrice_86547036",
	"verticalLine": "pet-summary-panel_verticalLine_a6682769",
	"titleClassname": "pet-summary-panel_titleClassname_f9e53b1a",
	"detailsColor": "pet-summary-panel_detailsColor_1dcd284a",
	"LegalBodyMargin": "pet-summary-panel_LegalBodyMargin_be7bf161"
};
export default ___CSS_LOADER_EXPORT___;
