import { ERROR, FETCHING, SUCCESS } from '@pumpkincare/shared';
import { createPetVetIds } from '@pumpkincare/vets';

import { setVetFormCustomVets } from '../state';

export default function submitCustomVets(data) {
  return (dispatch, getState, { vetService }) => {
    dispatch(setVetFormCustomVets({ status: FETCHING, data: [] }));

    const petIds = data.map(customVet => customVet.pet_id);
    const vets = data.map(customVet => customVet.vet);

    return vetService
      .submitCustomVets(vets)
      .then(result => {
        const petVets = createPetVetIds(petIds, result);

        dispatch(setVetFormCustomVets({ status: SUCCESS, data: petVets }));
      })
      .catch(err => {
        dispatch(setVetFormCustomVets({ status: ERROR, data: [] }));
        throw err;
      });
  };
}
