import { batch } from 'react-redux';

import { setTrackedCustomProps } from '@pumpkincare/analytics';
import { generateIdentifyMeta } from '@pumpkincare/shared';

import { getQuotesTrackingId } from '../selectors/quotes-selectors';
import {
  setQuotesEmail,
  setQuotesFirstName,
  setQuotesLastName,
  setQuotesPolicyState,
  setQuotesPolicyZipCode,
} from '../state/quotes-ducks';

export default function submitRegister(payload) {
  return (dispatch, getState) => {
    const { email, firstName, lastName, zipCode, policyState } = payload;
    const trackingId = getQuotesTrackingId(getState());

    setTrackedCustomProps({ email });

    batch(() => {
      dispatch(setQuotesEmail(email, generateIdentifyMeta({ email }, trackingId)));
      dispatch(
        setQuotesFirstName(
          firstName,
          generateIdentifyMeta({ firstName }, trackingId)
        )
      );
      dispatch(
        setQuotesLastName(lastName, generateIdentifyMeta({ lastName }, trackingId))
      );
      dispatch(
        setQuotesPolicyZipCode(
          zipCode,
          generateIdentifyMeta({ zipCode }, trackingId)
        )
      );
      dispatch(
        setQuotesPolicyState(
          policyState,
          generateIdentifyMeta({ state: policyState }, trackingId)
        )
      );
    });
  };
}
