import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import {
  analyticsTrack,
  GAQuoteFlowSubmitPetCount,
  quoteFlowStartedQuote,
} from '@pumpkincare/analytics';
import { useGoToCheckout, validatePet } from '@pumpkincare/quotes';
import {
  ADD,
  captureException,
  generateIdentifyMetaUserId,
  GENERIC_ERROR,
  getIsLoggedIn,
  useBanners,
  useBooleanInput,
} from '@pumpkincare/shared';
import { Body1, LegalBody, LoaderButton } from '@pumpkincare/shared/ui';
import { getUserEmail, getUserId, useUssr } from '@pumpkincare/user';

import { Paths } from '../../../app-shell';
import { getQuotesActiveId, getQuotesMode, getQuotesPets } from '../../selectors';
import { setAddANewPetLaterDay } from '../../state';
import submitIntroduction from '../../thunks/submit-introduction';
import EditPet from './edit-pet';

import styles from './introduction.module.css';

export default function Introduction() {
  const dispatch = useDispatch();
  const activeId = useSelector(getQuotesActiveId);
  const mode = useSelector(getQuotesMode);
  const quotePets = useSelector(getQuotesPets);

  const goToCheckout = useGoToCheckout(Paths.Checkout);
  const { addBanner, removeAllBanners } = useBanners();

  const {
    data: { userId, userEmail },
  } = useUssr(data => ({ userId: getUserId(data), userEmail: getUserEmail(data) }));

  const [componentPets, setComponentPets] = useState(quotePets);
  const [isSubmitting, toggleIsSubmitting] = useBooleanInput(false);

  const currentPet = componentPets.find(
    pet => pet.id === (activeId || componentPets[componentPets.length - 1].id)
  );
  const isLoggedIn = getIsLoggedIn();
  const isSinglePetExperience = mode === ADD && !isLoggedIn;

  const headerClassName = classNames({
    [styles.quoteFlowHeader]: isSinglePetExperience,
    [styles.quoteFlowHeaderAdd]: !isSinglePetExperience,
  });

  useEffect(() => {
    setComponentPets(quotePets);
  }, [quotePets]);

  useEffect(() => {
    analyticsTrack(quoteFlowStartedQuote());
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      const traits = {
        MULTIPET: 'True',
        ADDPETLATERDAY: 'True',
        email: userEmail,
      };

      dispatch(
        setAddANewPetLaterDay(
          { id: new Date().valueOf() },
          generateIdentifyMetaUserId({ traits, userId })
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleNext() {
    removeAllBanners();
    toggleIsSubmitting();

    dispatch(submitIntroduction(componentPets)).catch(err => {
      captureException(err);

      toggleIsSubmitting();
      addBanner(GENERIC_ERROR);
    });

    GAQuoteFlowSubmitPetCount({ totalCount: componentPets.length });
  }

  function handlePetChange(pet) {
    setComponentPets(componentPets.map(curr => (curr.id === pet.id ? pet : curr)));
  }

  function handleGoCheckoutClick() {
    goToCheckout();
  }

  return (
    <>
      <h3 className={headerClassName}>
        {isSinglePetExperience
          ? 'The more, the healthier - let’s add another pet!'
          : 'Let’s create your pet’s plan!'}
      </h3>

      <div className={styles.content}>
        {isSinglePetExperience ? (
          <Body1 className={styles.multiPetTitle}>
            Don’t worry if you don’t see it on the next page - the 10% multi-pet
            discount for all additional pets is applied at checkout!
          </Body1>
        ) : null}

        {currentPet ? <EditPet pet={currentPet} onChange={handlePetChange} /> : null}

        {isSinglePetExperience ? null : (
          <div className={styles.badgeSection}>
            <img
              alt=''
              src='/assets/images/star-badge.png'
              className={styles.badgeIcon}
            />

            <LegalBody className={styles.badgeText}>
              If you have multiple fur kids, fill in just one of their names for now.
              After you’ve created your first pet’s plan, you can add more pets and
              save 10% on each additional plan!
            </LegalBody>
          </div>
        )}

        <LoaderButton
          disabled={componentPets.every(validatePet) === false}
          color='primary'
          classes={{ root: styles.nextButton }}
          data-testid={'next-button'}
          onClick={handleNext}
          isLoading={isSubmitting}
        >
          Next
        </LoaderButton>

        {isSinglePetExperience ? (
          <>
            <LegalBody
              isBold
              className={styles.skipButton}
              onClick={handleGoCheckoutClick}
              data-testid='go-back-button'
            >
              Skip and proceed to checkout
            </LegalBody>
          </>
        ) : null}
      </div>
    </>
  );
}
