import { postAnswers } from '@pumpkincare/identity';
import { captureException } from '@pumpkincare/shared';

import { getIdentityId } from '../../identity/selectors';
import { getPersonalizationQuestionVariantId } from '../selectors/personalization-selectors';
import fetchQuestionVariant from './fetch-question-variant';

export default function postAnswersThunk(answers) {
  return (dispatch, getState) => {
    const state = getState();
    const questionVariantId = getPersonalizationQuestionVariantId(state);
    const identityId = getIdentityId(state);

    return postAnswers(identityId, questionVariantId, answers)
      .then(() => dispatch(fetchQuestionVariant()))
      .catch(captureException);
  };
}
