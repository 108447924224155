import Store from 'store';

import { IDENTITY_LOCAL_STORAGE_KEY } from '@pumpkincare/shared';

const UPDATE_IDENTITY = 'identity/UPDATE_IDENTITY';

const initialState = {
  city: '',
  email: '',
  first_name: '',
  id: Store.get(IDENTITY_LOCAL_STORAGE_KEY) || '',
  last_name: '',
  state_province: '',
  tracking_id: '',
  zipcode: '',
};

export default function identityReducer(state = initialState, { payload, type }) {
  switch (type) {
    case UPDATE_IDENTITY:
      return { ...state, ...payload };

    default:
      return state;
  }
}

export function setUpdatedIdentity(payload) {
  return { type: UPDATE_IDENTITY, payload };
}
