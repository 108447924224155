// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".has-been-to-the-vet_required_5d55e8a7::after {\n  content: ' *';\n  color: var(--cliffordRed);\n}\n\n.has-been-to-the-vet_radio_124ddf39 {\n  margin: 8px 0;\n}\n\n.has-been-to-the-vet_radioGroup_dfffb544 {\n  gap: 32px;\n}\n", "",{"version":3,"sources":["webpack://src/vet/view/has-been-to-the-vet/has-been-to-the-vet.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,SAAS;AACX","sourcesContent":[".required::after {\n  content: ' *';\n  color: var(--cliffordRed);\n}\n\n.radio {\n  margin: 8px 0;\n}\n\n.radioGroup {\n  gap: 32px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"required": "has-been-to-the-vet_required_5d55e8a7",
	"radio": "has-been-to-the-vet_radio_124ddf39",
	"radioGroup": "has-been-to-the-vet_radioGroup_dfffb544"
};
export default ___CSS_LOADER_EXPORT___;
