import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './slide-out-panel.module.css';

function SlideOutPanel({ children, onClose, show, classes }) {
  return (
    <>
      <div
        className={classNames(
          styles.backdrop,
          !show ? styles.hideBackDrop : styles.showBackDrop
        )}
        onClick={onClose}
        data-testid={'backdrop'}
      >
        &nbsp;
      </div>
      <div
        className={classNames(
          styles.container,
          classes.container,
          !show ? styles.hideContainer : styles.showContainer
        )}
        data-testid='slide-out-panel'
      >
        {children}
      </div>
    </>
  );
}

SlideOutPanel.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.shape({ container: PropTypes.string }),
  onClose: PropTypes.func,
  show: PropTypes.bool,
};

SlideOutPanel.defaultProps = {
  classes: {},
  show: false,
};
export default SlideOutPanel;
