import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

import { useDiscount } from '@pumpkincare/discounts';
import { Body1, Body2, LoaderButton, TextField } from '@pumpkincare/shared/ui';

import { Paths } from '../app-shell';

import styles from './teams.module.css';

function Teams() {
  const dispatch = useDispatch();
  const [passcode, setPasscode] = useState('');
  const [hasError, setHasError] = useState(false);

  const discountQuery = useDiscount(passcode, {
    onSuccess: () => dispatch(push(Paths.Intro)),
    onError: () => {
      setHasError(true);
    },
  });

  function handleButtonClick() {
    if (hasError) {
      dispatch(push(Paths.Intro));
    } else {
      discountQuery.refetch();
    }
  }

  function cleanErrorStatus() {
    setPasscode('');
    setHasError(false);
  }

  return (
    <div className={styles.mainContainer}>
      <div className={styles.contentBlock}>
        <h1 className={styles.headerH1}>Welcome to Pumpkin!</h1>

        <Body2 className={styles.headerBody1}>
          We are proud to work alongside you and your company help give pets the best
          care humanly possible.
        </Body2>

        <Body2 isBold className={styles.headerMessage}>
          Enter your code below and enjoy your organization’s discounts.
        </Body2>

        <TextField
          autoFocus
          id='passcode'
          value={passcode}
          classes={{ container: styles.textField }}
          label='Enter Code'
          error={{
            errorMessage: hasError
              ? 'You have entered an invalid code. Discount may not be applied when you create your plan.'
              : '',
          }}
          type='password'
          onFocus={cleanErrorStatus}
          onChange={e => setPasscode(e.target.value)}
        />

        <LoaderButton
          color='primary'
          onClick={handleButtonClick}
          classes={{ root: styles.headerButton }}
          isLoading={discountQuery.isFetching}
        >
          {hasError ? 'Continue' : 'Create My Plan'}
        </LoaderButton>

        <Body1>*Note: Discounts will be applied at checkout.</Body1>
      </div>
    </div>
  );
}

export default Teams;
