import _ from 'lodash';

import { generateIdentifyMeta, getIsLoggedIn, INITIAL } from '@pumpkincare/shared';

import { setPlanPageLoadStatus } from '../../plans';
import { getQuotesEmail, getQuotesTrackingId } from '../selectors/quotes-selectors';
import { setPets } from '../state/quotes-ducks';
import clearPetTraits from './clear-pet-traits';

export default function setFormattedPets(pets) {
  return (dispatch, getState) => {
    const state = getState();
    const trackingId = getQuotesTrackingId(state);
    const isLoggedIn = getIsLoggedIn();
    const email = getQuotesEmail(state);
    const nameFieldMap = {
      NAME: 'petName',
      GENDER: 'petGender',
      SPECIES: 'petBreedSpecies',
      AGE: 'petAge',
      BREED: 'petBreedName',
      CHECKOUT: '',
    };

    // TODO: likely we don't even use these traits anymore
    let traits = {
      email,
      MULTIPET: isLoggedIn || pets.length > 1 ? 'True' : 'False',
    };

    pets.forEach((pet, index) => {
      // for add a pet at later day
      // only set the traits for newly added pet
      // because the data structure for preEnrolled and quote pets is different
      for (const name in nameFieldMap) {
        const field = nameFieldMap[name];

        if (pet[field]) {
          traits[`PET${index + 1}${name}`] = _.startCase(pet[field]);
        }
      }
    });

    dispatch(clearPetTraits(nameFieldMap, trackingId, email));
    dispatch(setPets({ pets }, generateIdentifyMeta(traits, trackingId)));
    dispatch(setPlanPageLoadStatus(INITIAL));
  };
}
