const SET_QUESTION_VARIANT = 'personalization/SET_QUESTION_VARIANT';

const initialState = {
  questionVariant: null,
};

export default function reducer(state = initialState, { payload, type }) {
  switch (type) {
    case SET_QUESTION_VARIANT:
      return { ...state, questionVariant: payload };

    default: {
      return state;
    }
  }
}

export function setQuestionVariant(payload) {
  return { type: SET_QUESTION_VARIANT, payload };
}
