import classNames from 'classnames';
import PropTypes from 'prop-types';

import { WWW_URL } from '@pumpkincare/config';
import { isPrevent4 } from '@pumpkincare/plans';
import { parsePlanDetails } from '@pumpkincare/plans';
import { LegalBody, Typography } from '@pumpkincare/shared/ui';

import ConfirmPepButtons from '../confirm-pep-buttons';

import styles from './pep-picker.module.css';

function PepPicker({ plan, quoteData, activePet, onAdd, onRemove }) {
  const planDetails = parsePlanDetails(plan, activePet?.petBreedSpecies);
  const pepListClassName = classNames({
    [styles.pep4List]: isPrevent4(plan),
    [styles.pep3List]: !isPrevent4(plan),
  });
  const { vet } = quoteData;

  const savingsLegalTag = {
    dog: '#simparica-savings',
    cat: '#revolution-savings',
  };

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <h4 className={classNames(Typography.h4, styles.title)}>
          Want to help prevent diseases {'&'} catch illnesses earlier?
        </h4>
      </div>
      <div className={styles.body}>
        <div className={styles.container}>
          <span className={classNames(Typography.h3, styles.containerTitle)}>
            Add Preventive Essentials
          </span>
          {isPrevent4(plan) ? (
            plan.savings ? (
              <LegalBody className={styles.bodyText}>
                If your fur kid comes first, one of the first things you should do is
                add Preventive Essentials, our optional wellness package created with{' '}
                {vet?.vet_name}, to your insurance plan. Preventive Essentials isn't
                insurance, but helps you get ahead of health issues by offering
                refunds for crucial wellness care your vet recommends each year. Best
                part? Clients of your vet may{' '}
                <span className={styles.savings}>save over ${plan.savings}</span> per
                year on this package:
              </LegalBody>
            ) : (
              <LegalBody className={styles.bodyText}>
                If your fur kid comes first, one of the first things you should do is
                add Preventive Essentials, our optional wellness package created with{' '}
                {vet?.vet_name}, to your insurance plan. Preventive Essentials isn't
                insurance, but helps you get ahead of health issues by offering
                refunds for crucial wellness care your vet recommends each year:
              </LegalBody>
            )
          ) : (
            <LegalBody className={styles.bodyText}>
              If your fur kid comes first, one of the first things you should do is
              add Preventive Essentials, our optional wellness package, to your
              insurance plan. Preventive Essentials isn't insurance, but helps you
              get ahead of health issues by offering refunds for crucial wellness
              care vets strongly recommend each year:
            </LegalBody>
          )}

          <div className={pepListClassName}>
            {planDetails.map(({ item }, index) => (
              <div key={index} className={styles.pepListItem}>
                <div className={styles.iconContainer}>
                  <img
                    className={classNames(styles.iconContainer, styles.icon)}
                    src={'/assets/images/iconImages/check-white.svg'}
                  />
                </div>
                <LegalBody>{item}</LegalBody>
              </div>
            ))}
          </div>
          {isPrevent4(plan) && vet?.vet_name ? (
            <div className={styles.recommendedBadge}>
              <div className={styles.recommendedBadgeImgContainer}>
                <img
                  className={styles.recommendedBadgeImg}
                  src='/assets/images/stethoscope.png'
                  alt='stethoscope'
                  aria-hidden='true'
                />
              </div>
              <div className={styles.recommendedBadgeBody}>
                <LegalBody>Recommended Wellness Package by</LegalBody>
                <LegalBody>
                  <b>{vet.vet_name}</b>
                </LegalBody>
              </div>
            </div>
          ) : null}

          <div className={styles.divider} />
          <div className={styles.containerFooter}>
            <div className={styles.priceSection}>
              <div className={styles.priceText}>
                <span className={classNames(Typography.h3, styles.priceValue)}>
                  ${plan?.cost.toFixed(2)}{' '}
                </span>
                / month
              </div>
              <LegalBody className={styles.priceSubtext}>
                (for 12 months minimum)
              </LegalBody>
              {plan.savings ? (
                <LegalBody isBold className={styles.salesMessage}>
                  <span className={styles.salesMessageHighlight}>
                    You may save over ${plan.savings}/yr on these items
                    <a href={savingsLegalTag[plan.target]}>*</a>
                  </span>
                </LegalBody>
              ) : null}
            </div>
            <ConfirmPepButtons onAdd={onAdd} onRemove={onRemove} />
          </div>
          <img
            className={styles.imageContainer}
            src='/assets/images/hdyhau/welcome-box.png'
            alt=''
          />
        </div>
      </div>
      <div className={styles.footer}>
        <p>
          {`Fur all the details, `}
          <a
            href={`${WWW_URL}/sample-plan/`}
            target='_blank'
            rel='noreferrer noopener'
            className={styles.url}
          >
            see our sample customer agreement
          </a>
        </p>
      </div>
    </div>
  );
}

PepPicker.defaultProps = {
  version_number: '',
  quoteData: {},
  plan: {},
};

PepPicker.propTypes = {
  version_number: PropTypes.string,
  quoteData: PropTypes.object,
  plan: PropTypes.object,
};

export default PepPicker;
