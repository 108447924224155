import { transformPet } from '@pumpkincare/quotes';

import { getAppNoopPayload } from '../../app-shell';

export default function addPetToQuote(quoteId, pet) {
  return (dispatch, getState, { quotesService }) => {
    return quotesService
      .addPetToQuote({ quoteId, pet, noopPayload: getAppNoopPayload(getState()) })
      .then(response => transformPet(response));
  };
}
