import { createSelector } from 'reselect';

import { getRouterAction } from '../../app-shell';
import { getQuotesTrackingId } from '../../quotes/selectors/quotes-selectors';
import { getIdentityId } from './identity-selectors';

const getShouldCreateIdentity = createSelector(
  [getIdentityId, getQuotesTrackingId, getRouterAction],
  (identityId, trackingId, routerAction) => {
    /*
     in the cases where segment fails to load and there's no trackingId, create an
     identity when the user changes their location (all init actions are POP)
     */
    const shouldCreateIdentity =
      !identityId && (!!trackingId || routerAction === 'PUSH');

    return {
      shouldCreateIdentity,
      shouldUnsubObserver: !!identityId || shouldCreateIdentity,
    };
  }
);

export default getShouldCreateIdentity;
