import { generateIdentifyMeta } from '@pumpkincare/shared';
import { INITIAL } from '@pumpkincare/shared';

import { setAddANewPet } from '../../quotes';
import { getQuotesEmail, getQuotesTrackingId } from '../../quotes/selectors';
import { setPlanPageLoadStatus } from '../state/plans-ducks';

export default function goToAddNewPet() {
  return function (dispatch, getState) {
    const state = getState();
    const trackingId = getQuotesTrackingId(state);
    const email = getQuotesEmail(state);

    dispatch(setPlanPageLoadStatus(INITIAL));
    dispatch(
      setAddANewPet(
        { id: new Date().valueOf() },
        generateIdentifyMeta({ MULTIPET: 'True', email }, trackingId)
      )
    );
  };
}
