import { ERROR, FETCHING, SUCCESS } from '@pumpkincare/shared';

import { setVetFormAnswers } from '../state';

export default function submitAnswers(answers, userId) {
  return (dispatch, getState, { vetService }) => {
    dispatch(setVetFormAnswers({ status: FETCHING }));

    return vetService
      .submitAnswers(userId, answers)
      .then(() => {
        dispatch(setVetFormAnswers({ status: SUCCESS }));
      })
      .catch(err => {
        dispatch(setVetFormAnswers({ status: ERROR }));
        throw err;
      });
  };
}
