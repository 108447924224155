import { INITIAL } from '@pumpkincare/shared';

export const emptyPet = {
  annual_limit: null,
  copay: null,
  deductible: null,
  embarkDnaKitSelected: false,
  id: new Date().valueOf(),
  partner: null,
  petAge: null,
  petBreedCode: null,
  petBreedName: null,
  petBreedSpecies: null,
  petBreedType: null,
  petDOB: null,
  petGender: '',
  petName: '',
  plan: null,
  preventativeSelected: false,
};

export default {
  email: null,

  id: null,

  isRegistered: false,

  partner: null,

  policy_state: null,

  policy_zipcode: null,

  firstName: null,

  lastName: null,

  mode: null,

  stage: null,

  userId: null,

  plan_id: null,

  pets: [{ ...emptyPet }],

  petsOrder: {},

  activeID: null,

  trackingId: null,

  transactionFee: null,

  temporary_effective_date: null,

  status: null,

  submitQuoteRequest: {
    fetching: false,
    success: false,
  },

  registerQuoteStatus: INITIAL,
  isShippingSameAsBilling: false,

  billingAddress: {
    firstName: {
      id: 'firstName',
      label: 'First Name',
      required: true,
      value: '',
      error: false,
      valid: false,
    },
    lastName: {
      id: 'lastName',
      label: 'Last Name',
      required: true,
      value: '',
      error: false,
      valid: false,
    },
    address1: {
      id: 'address1',
      label: 'Address',
      required: true,
      value: '',
      error: false,
      valid: false,
    },
    address2: {
      id: 'address2',
      label: 'Address Continued',
      value: '',
      required: null,
      error: false,
      valid: false,
    },
    zipcode: {
      id: 'zipcode',
      label: 'Zip Code',
      required: true,
      value: '',
      error: false,
      valid: false,
    },
    state: {
      id: 'state',
      label: 'State',
      required: true,
      value: '',
      error: false,
      valid: false,
    },
    city: {
      id: 'city',
      label: 'City',
      required: true,
      value: '',
      error: false,
      valid: false,
    },
    phone: {
      id: 'phone',
      label: 'Phone Number',
      required: true,
      value: '',
      error: false,
      valid: false,
    },
    nameShownOnCard: {
      id: 'nameShownOnCard',
      label: 'Name Shown on Card',
      required: true,
      value: '',
      error: false,
      valid: false,
    },
  },

  shippingAddress: {
    firstName: {
      id: 'firstName',
      label: 'First Name',
      required: true,
      value: '',
      error: false,
      valid: false,
    },
    lastName: {
      id: 'lastName',
      label: 'Last Name',
      required: true,
      value: '',
      error: false,
      valid: false,
    },
    address1: {
      id: 'address1',
      label: 'Address',
      required: true,
      value: '',
      error: false,
      valid: false,
    },
    address2: {
      id: 'address2',
      label: 'Address Continued',
      required: false,
      value: '',
      error: false,
      valid: false,
    },
    zipcode: {
      id: 'zipcode',
      label: 'Zipcode',
      required: true,
      value: '',
      error: false,
      valid: false,
    },
    state: {
      id: 'state',
      label: 'State',
      required: true,
      value: '',
      error: false,
      valid: false,
    },
    city: {
      id: 'city',
      label: 'City',
      required: true,
      value: '',
      error: false,
      valid: false,
    },
  },

  paymentInfo: {
    cardNumber: {
      id: 'cardNumber',
      error: false,
      empty: true,
      complete: false,
      label: 'Debit or Credit Card Number *',
      required: true,
    },
    expirationDate: {
      id: 'expirationDate',
      error: false,
      empty: true,
      complete: false,
      label: 'Expiration Date *',
      required: true,
    },
    cvc: {
      id: 'cvc',
      label: 'CVV *',
      error: false,
      empty: true,
      complete: false,
      required: true,
    },
  },

  vetAttribution: {
    status: INITIAL,
    data: {},
  },
};
