import { transformPet } from '@pumpkincare/quotes';
import { captureException, getIsLoggedIn } from '@pumpkincare/shared';

export default function fetchExistingDuplicatePets(quoteId) {
  return (dispatch, getState, { quotesService }) => {
    const isLoggedIn = getIsLoggedIn();
    const params = { quoteId, isLoggedIn };

    return quotesService
      .checkQuoteForExistingDuplicates(params)
      .then(dupCheckResponse => {
        const pets = dupCheckResponse.map(value => ({
          existingPet: transformPet(value.existing_pet),
          duplicatePet: transformPet(value.duplicate_pet),
        }));
        return pets;
      })
      .catch(captureException);
  };
}
