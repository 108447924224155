import { SUCCESS } from '@pumpkincare/shared';

const SET_VET_FORM = 'vet/SET_VET_FORM';
const SET_VET_FORM_CUSTOM_VETS = 'vet/SET_VET_FORM_CUSTOM_VETS';
const SET_VET_FORM_ANSWERS = 'vet/SET_VET_FORM_ANSWERS';
const SET_CURRENT_ANSWERS = 'vet/SET_CURRENT_ANSWERS';

const initialState = {
  form: {
    status: null,

    customVets: {
      status: null,
      data: [],
    },

    answers: {
      status: null,
    },
  },

  currentAnswers: {
    status: null,
    data: [],
  },
};

export default function vetReducer(state = initialState, { payload, type }) {
  switch (type) {
    case SET_VET_FORM: {
      return {
        ...state,
        form: {
          customVets: { ...state.form.customVets },
          answers: { ...state.form.answers },
          ...payload,
        },
      };
    }

    case SET_VET_FORM_CUSTOM_VETS: {
      return {
        ...state,
        form: {
          ...state.form,
          customVets: { ...payload },
        },
      };
    }

    case SET_VET_FORM_ANSWERS: {
      return {
        ...state,
        form: {
          ...state.form,
          answers: { ...payload },
        },
      };
    }

    case SET_CURRENT_ANSWERS: {
      return {
        ...state,
        currentAnswers: { ...payload },
      };
    }

    default: {
      return state;
    }
  }
}

export function setVetForm(payload) {
  return {
    type: SET_VET_FORM,
    payload,
  };
}

export function setVetFormAnswers(payload) {
  return {
    type: SET_VET_FORM_ANSWERS,
    payload,
  };
}

export function setCurrentAnswers(payload) {
  return {
    type: SET_CURRENT_ANSWERS,
    payload,
  };
}

export function setVetFormCustomVets(payload) {
  return {
    type: SET_VET_FORM_CUSTOM_VETS,
    payload,
  };
}
