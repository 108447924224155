import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { canGoToPlanPage } from '@pumpkincare/plans';
import { getQuotePets, QUOTE_ID_COOKIE_NAME, useQuote } from '@pumpkincare/quotes';
import { EMBARK, getCookie } from '@pumpkincare/shared';
import { ProgressBar } from '@pumpkincare/shared/ui';

import { Paths } from '../../../app-shell';
import { PetQuestions } from '../../../personalization';
import { getQuotesPartner } from '../../selectors';
import getQuotesActivePet from '../../selectors/get-quotes-active-pet';
import {
  calculateEmbarkQuoteFlowProgress,
  calculateQuoteFlowProgress,
} from '../../utils/quote-flow-progress-bar-utils';
import { EmbarkHello, EmbarkHelloLegacy } from '../embark-hello';
import Introduction from '../introduction';
import PetInfo from '../pet-info';
import Register from '../register';
import { QuoteFlowTransition } from '../shared';

import styles from './quote-flow.module.css';

export function QuoteFlow() {
  const location = useLocation();
  const { pathname } = location;

  const quoteId = getCookie(QUOTE_ID_COOKIE_NAME);

  const activePet = useSelector(getQuotesActivePet);
  const quotePartner = useSelector(getQuotesPartner);

  const { data: quoteData, isFetched } = useQuote();
  const petsData = getQuotePets(quoteData);
  const canRouteToPlan = canGoToPlanPage(quoteData);
  const canRouteToRegister = petsData.some(pet => pet.petGender);
  const canRouteToPetInfo = petsData.some(pet => pet.petName);

  const { embarkStrategicDiscount, showScheduledMaintenanceFrame } = useFlags();
  const isWideComponent =
    embarkStrategicDiscount && [Paths.EmbarkHello].includes(pathname);

  return showScheduledMaintenanceFrame &&
    Object.keys(showScheduledMaintenanceFrame).length > 0 ? (
    <Redirect
      to={{
        pathname: Paths.MaintenanceFrame,
        state: {
          timeStart: showScheduledMaintenanceFrame.start,
          timeEnd: showScheduledMaintenanceFrame.end,
        },
      }}
    />
  ) : quoteId && isFetched === false ? null : (
    <div>
      <ProgressBar
        value={
          quotePartner === EMBARK
            ? calculateEmbarkQuoteFlowProgress(pathname)
            : calculateQuoteFlowProgress(pathname)
        }
      />

      <div
        className={classNames(styles.quoteFlow, {
          [styles.wide]: isWideComponent,
        })}
      >
        <QuoteFlowTransition location={location}>
          <Switch location={location}>
            <Route exact path={Paths.QuoteFlow}>
              {canRouteToPlan ? (
                <Redirect to={Paths.PlanSelection} />
              ) : canRouteToRegister ? (
                <Redirect to={Paths.Register} />
              ) : canRouteToPetInfo ? (
                <Redirect to={Paths.PetInfo} />
              ) : (
                <Redirect to={Paths.Intro} />
              )}
            </Route>

            <Route path={Paths.EmbarkHello}>
              {embarkStrategicDiscount ? <EmbarkHello /> : <EmbarkHelloLegacy />}
            </Route>

            <Route path={Paths.Intro}>
              <Introduction />
            </Route>

            <Route path={Paths.PetInfo}>
              <PetInfo />
            </Route>

            <Route path={Paths.PetQuestions}>
              <PetQuestions />
            </Route>

            <Route path={Paths.Register}>
              <Register activePet={activePet} />
            </Route>

            <Redirect to={Paths.Intro} />
          </Switch>
        </QuoteFlowTransition>
      </div>
    </div>
  );
}

export default QuoteFlow;
