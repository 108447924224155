import { HOME_URL } from '@pumpkincare/config';
import { getMarketingAttribution } from '@pumpkincare/marketing';
import { transformPet } from '@pumpkincare/quotes';
import { FETCHING, generateIdentifyMeta, SUCCESS } from '@pumpkincare/shared';

import { getAppNoopPayload } from '../../app-shell';
import {
  getQuotesActiveId,
  getQuotesEmail,
  getQuotesFirstName,
  getQuotesId,
  getQuotesLastName,
  getQuotesPets,
  getQuotesPolicyZipCode,
  getQuotesTrackingId,
} from '../selectors/quotes-selectors';
import {
  setQuoteResponseV2,
  setRegisterQuoteStatus,
  setSubmittingQuoteFetching,
} from '../state/quotes-ducks';

export default function registerQuote(discountId) {
  return (dispatch, getState, { quotesService }) => {
    const state = getState();
    const noopPayload = getAppNoopPayload(state);
    const quoteId = getQuotesId(state);
    const reduxPets = getQuotesPets(state);
    const activeId = getQuotesActiveId(state);
    const trackingId = getQuotesTrackingId(state);
    const firstName = getQuotesFirstName(state);
    const lastName = getQuotesLastName(state);
    const email = getQuotesEmail(state);
    const policyZipcode = getQuotesPolicyZipCode(state);

    const params = {
      quoteId,
      activeId,
      trackingId,
      firstName,
      lastName,
      email,
      policyZipcode,
      discountId,
      noopPayload,
    };

    dispatch(setSubmittingQuoteFetching());
    dispatch(setRegisterQuoteStatus(FETCHING));

    return quotesService.registerQuote(params).then(responseQuote => {
      const {
        email,
        first_name,
        is_registered,
        last_name,
        policy_state,
        policy_zipcode,
        quote_pets,
        id,
        vet,
      } = responseQuote;
      let newActiveId = '';
      const responsePet =
        quote_pets.find(pet => pet.age && pet.gender) || quote_pets[0];

      const pets = reduxPets.map(pet => {
        if (pet.id === responsePet.id) {
          const newPet = transformPet(responsePet);

          newActiveId = newPet.id;

          return newPet;
        }
        return pet;
      });

      const quote = {
        email,
        firstName: first_name,
        isRegistered: is_registered,
        lastName: last_name,
        policy_zipcode,
        policy_state,
        pets,
        id,
        activeID: newActiveId,
        vetAttribution: vet || {},
      };

      const marketing = getMarketingAttribution();
      const traits = {
        QUOTEURL: `${HOME_URL}/plan-selection/${id}`,
        QUOTEID: id,
        email,
        marketing,
      };

      dispatch(setRegisterQuoteStatus(SUCCESS));
      dispatch(setQuoteResponseV2(quote, generateIdentifyMeta(traits, trackingId)));

      return newActiveId;
    });
  };
}
