// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../../../libs/shared/src/ui/breakpoints.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".section-container_sectionContainer_1e13cef9 {\n  padding: 0 16px;\n  margin: auto;\n\n  width: 100%;\n  display: flex;\n  flex-wrap: wrap;\n\n  margin: 0;\n  box-sizing: border-box;\n  justify-content: center;\n}\n\n@media " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["tablet"] + " {\n  .section-container_sectionContainer_1e13cef9 {\n    padding: 0 32px;\n  }\n}\n\n@media " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["desktop"] + " {\n  .section-container_sectionContainer_1e13cef9 {\n    padding: 0 168px;\n  }\n}\n", "",{"version":3,"sources":["webpack://src/plans/view/section-container/section-container.module.css"],"names":[],"mappings":"AAEA;EACE,eAAe;EACf,YAAY;;EAEZ,WAAW;EACX,aAAa;EACb,eAAe;;EAEf,SAAS;EACT,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE;IACE,eAAe;EACjB;AACF;;AAEA;EACE;IACE,gBAAgB;EAClB;AACF","sourcesContent":["@value tablet, desktop from '~@pumpkincare/shared/ui/breakpoints';\n\n.sectionContainer {\n  padding: 0 16px;\n  margin: auto;\n\n  width: 100%;\n  display: flex;\n  flex-wrap: wrap;\n\n  margin: 0;\n  box-sizing: border-box;\n  justify-content: center;\n}\n\n@media tablet {\n  .sectionContainer {\n    padding: 0 32px;\n  }\n}\n\n@media desktop {\n  .sectionContainer {\n    padding: 0 168px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tablet": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["tablet"] + "",
	"desktop": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["desktop"] + "",
	"sectionContainer": "section-container_sectionContainer_1e13cef9"
};
export default ___CSS_LOADER_EXPORT___;
