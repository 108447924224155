import { push } from 'connected-react-router';

import { ERROR, FETCHING, SUCCESS } from '@pumpkincare/shared';
import { addVetIdsToAnswers } from '@pumpkincare/vets';

// import { MemberRoutePaths } from '../../../../app-shell';
import { getVetFormCustomVetsData } from '../selectors/vet-selectors';
import { setVetForm } from '../state';
import submitAnswers from './submit-answers';
import submitCustomVets from './submit-custom-vets';

export default function submitVetForm(data) {
  return (dispatch, getState) => {
    dispatch(setVetForm({ status: FETCHING }));

    const { customVets, answers, userId } = data;

    const onSuccess = () => {
      dispatch(setVetForm({ status: SUCCESS }));
      // dispatch(push(MemberRoutePaths.Profile));
    };

    const onError = err => {
      dispatch(setVetForm({ status: ERROR }));

      throw err;
    };

    // Do we have custom vets?
    if (customVets.length > 0) {
      // Create vets, add vet ids and submit
      return dispatch(submitCustomVets(customVets))
        .then(() => {
          const petVetIds = getVetFormCustomVetsData(getState());
          return addVetIdsToAnswers(answers, petVetIds);
        })
        .then(answersWithVetIds => {
          return dispatch(submitAnswers(answersWithVetIds, userId));
        })
        .then(onSuccess)
        .catch(onError);
    }
    // No custom vets, just submit
    else {
      return dispatch(submitAnswers(answers, userId)).then(onSuccess).catch(onError);
    }
  };
}
