import { useSelector } from 'react-redux';

import { ARF_MEOW_PHONE_NUMBER, FORMATTED_PHONE_NUMBER } from '@pumpkincare/shared';

export const CHECKOUT_HEADLINE = 'Yay, you’re enrolled!';
export const formatVetFormHeader = petNames => {
  const names =
    petNames.length > 2 ? "your fur family's" : `${petNames.join(' & ')}’s`;
  return `Let’s get some info for ${names} profile so we can make things quick & easy for you later.`;
};

export const PETS_IMAGE = '/assets/images/vet-survey.png';
export const PETS_IMAGE_ALT = 'A dog waving and a cat behind a box';

export const HELP_TITLE = "Have questions? We're a paw away.";
export const HelpText = () => {
  return (
    <>
      ${ARF_MEOW_PHONE_NUMBER} (${FORMATTED_PHONE_NUMBER}) | help@pumpkin.care
    </>
  );
};

export const CHECKOUT_CTA = 'Submit';

export const CHECKOUT_NOTE =
  'By submitting, you give Pumpkin permission to (a) contact your vet in order to gather claim-related information; (b) share your name & the name of your pet(s) with your vet in order to make them aware of your Pumpkin affiliation; and (c) share your Pumpkin claims and their status with your vet.';

export const PREVENTIVE_TITLE = 'Have Preventive Essentials? ';
export const PREVENTIVE_TEXT =
  "File a claim online within 14 days of the visit to get any benefits received fully refunded! Don't have our Preventive Essentials Pack? You can add it to your insurance plan anytime.";
