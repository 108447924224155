import classNames from 'classnames';

import { Body1, Body2, Typography } from '@pumpkincare/shared/ui';

import styles from './plan-royalty.module.css';
function PlanRoyalty({ description = '', header = '', imageUrl = '', items = [] }) {
  return (
    <>
      <div className={styles.gridContent}>
        <h3 className={classNames(Typography.h3, styles.titleSection)}>{header}</h3>

        <div className={styles.copySection}>
          <h6 className={classNames(Typography.h6, styles.royalDesc)}>
            {description}
          </h6>

          {items.map((item, index) => (
            <div className={styles.royalItem} key={index}>
              <img src='/assets/images/iconImages/shape-star.svg' alt='' />

              <div>
                <Body1 isBold>{item.title}</Body1>

                <Body2>{item.description}</Body2>
              </div>
            </div>
          ))}
        </div>

        <div className={styles.imageSection}>
          <img src={imageUrl} alt='' />
        </div>
      </div>
    </>
  );
}

export default PlanRoyalty;
