const SET_FEATURE_FLAGS = 'app/SET_FEATURE_FLAGS';
const SET_MEDIA_MATCHES = 'app/SET_MEDIA_MATCHES';
const SET_NOOP_PAYLOAD = 'app/SET_NOOP_PAYLOAD';
const SET_AGENT = 'app/SET_AGENT';
const LEAVE_AGENT_MODE = 'app/LEAVE_AGENT_MODE';

const initialState = {
  featureFlags: {},

  media: {
    isSmDown: false,
    isMdUp: false,
  },

  noopPayload: {},
};

export default function appReducer(state = initialState, { payload, type }) {
  switch (type) {
    case SET_FEATURE_FLAGS:
      return { ...state, featureFlags: payload };

    case SET_MEDIA_MATCHES: {
      return { ...state, media: payload };
    }

    case SET_NOOP_PAYLOAD: {
      return { ...state, noopPayload: payload };
    }

    case SET_AGENT: {
      return {
        ...state,
        agentId: payload,
      };
    }

    case LEAVE_AGENT_MODE: {
      return {
        ...state,
        agentId: '',
      };
    }

    default:
      return state;
  }
}

export function setFeatureFlags(flags) {
  return { type: SET_FEATURE_FLAGS, payload: flags };
}

export function setMediaMatches(payload) {
  return { type: SET_MEDIA_MATCHES, payload };
}

export function setNoopPayload(payload) {
  return { type: SET_NOOP_PAYLOAD, payload };
}

export function setAppAgent(agentId) {
  return { type: SET_AGENT, payload: agentId };
}

export function leaveAgentMode() {
  return { type: LEAVE_AGENT_MODE };
}
