import { Body2 } from '@pumpkincare/shared/ui';

function AddressDisplay({ address, className, style }) {
  const {
    firstName = '',
    lastName = '',
    street1 = '',
    street2 = '',
    city = '',
    stateProvince = '',
    zipcode = '',
  } = address;

  return (
    <div className={className} style={style}>
      <Body2>
        {firstName} {lastName}
      </Body2>
      <Body2>
        {street1} {street2} {city}, {stateProvince} {zipcode}
      </Body2>
    </div>
  );
}

export default AddressDisplay;
