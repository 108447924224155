import { Paths } from '../../app-shell';

const embarkPath = [
  Paths.EmbarkHello,
  Paths.Intro,
  Paths.PetInfo,
  Paths.PetQuestions,
  Paths.Register,
];

const basePath = [Paths.Intro, Paths.PetInfo, Paths.PetQuestions, Paths.Register];

function calculateProgress(sequence, step) {
  const length = sequence.length + 1;
  const currentIndex = sequence.indexOf(step) + 1;

  return (currentIndex / length) * 100;
}

export function calculateEmbarkQuoteFlowProgress(step) {
  return calculateProgress(embarkPath, step);
}

export function calculateQuoteFlowProgress(step) {
  return calculateProgress(basePath, step);
}
