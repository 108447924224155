// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".checkout_verticalLine_950de416 {\n  border-left: 2px solid;\n  color: var(--benjiBlue);\n  padding-left: 16px;\n  margin-left: 8px;\n  display: flex;\n  flex-direction: row;\n  flex: 1;\n  justify-content: space-between;\n}\n\n.checkout_importantDatesFont_378df03c {\n  color: var(--gromitGray);\n} \n\n.checkout_dateSpacing_eeaa8634 {\n  margin-top: 16px;\n  margin-bottom: 16px;\n}", "",{"version":3,"sources":["webpack://src/checkout/view/checkout/plan-review.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,uBAAuB;EACvB,kBAAkB;EAClB,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,OAAO;EACP,8BAA8B;AAChC;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".verticalLine {\n  border-left: 2px solid;\n  color: var(--benjiBlue);\n  padding-left: 16px;\n  margin-left: 8px;\n  display: flex;\n  flex-direction: row;\n  flex: 1;\n  justify-content: space-between;\n}\n\n.importantDatesFont {\n  color: var(--gromitGray);\n} \n\n.dateSpacing {\n  margin-top: 16px;\n  margin-bottom: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"verticalLine": "checkout_verticalLine_950de416",
	"importantDatesFont": "checkout_importantDatesFont_378df03c",
	"dateSpacing": "checkout_dateSpacing_eeaa8634"
};
export default ___CSS_LOADER_EXPORT___;
