import { push } from 'connected-react-router';

import getQuoteFlowNextStep from '../selectors/get-quote-flow-next-step';
import {
  getQuotesActiveId,
  getQuotesId,
  getQuotesPets,
} from '../selectors/quotes-selectors';
import setFormattedUpdatedPet from './set-formatted-updated-pet';
import updatePetInQuote from './update-pet-in-quote';

export default function submitPetInfo(petInfo) {
  return (dispatch, getState) => {
    const state = getState();
    const pets = getQuotesPets(state);
    const quoteId = getQuotesId(state);
    const activeId = getQuotesActiveId(state);
    const activePet = pets.find(pet => pet.id === activeId);

    return dispatch(updatePetInQuote(quoteId, { ...activePet, ...petInfo })).then(
      response => {
        dispatch(setFormattedUpdatedPet(response));
        dispatch(push(getQuoteFlowNextStep(state)));
      }
    );
  };
}
