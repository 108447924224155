import SingleSelect from '../view/single-select';
import YesNo from '../view/yes-no';

export const YES_NO = 'Boolean';
export const INTERSTITIAL = 'Interstitial';
export const SINGLE_SELECT = 'Multiple Choice - Single Answer';
export const MULTI_SELECT = 'Multiple Choice - Multiple Answers';

export const answerOptionsComponentMap = {
  [YES_NO]: YesNo,
  [SINGLE_SELECT]: SingleSelect,
};
