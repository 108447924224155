import PropTypes from 'prop-types';

import { Body2, Radio, RadioGroup } from '@pumpkincare/shared/ui';

import styles from './has-been-to-the-vet.module.css';

function HasBeenToTheVet({ pet, value, className, onOptionChange }) {
  return (
    <div className={className}>
      <Body2 className={styles.required}>
        Has {pet.name} had an annual wellness exam in the last 2 weeks?
      </Body2>

      <RadioGroup
        name={`hasBeenToTheVet-${pet.id}`}
        value={value}
        onChange={onOptionChange}
        classes={{ root: styles.radioGroup }}
        row
      >
        <Radio value={true} label='Yes' classes={{ root: styles.radio }} />
        <Radio value={false} label='No' classes={{ root: styles.radio }} />
      </RadioGroup>
    </div>
  );
}

HasBeenToTheVet.defaultProps = {
  className: '',
  value: '',
  onOptionChange: () => {},
};

HasBeenToTheVet.propTypes = {
  pet: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  className: PropTypes.string,
  value: PropTypes.bool,
  onOptionChange: PropTypes.func,
};

export default HasBeenToTheVet;
